import {Services} from '../api/services';

export const setProjects = (projects) => {
    return (dispatch, getState) => {
    Services.Projects.setProjects().then((data)=>{
        if(data.val() !== null) {
            projects = [];
            const response = data.val();
            for (var key in response) {
                projects.push(response[key]);
            }
            dispatch({ type: 'SET_PROJECTS', projects: projects.sort((a,b) => (String(a.clientId).toLowerCase() > String(b.clientId).toLowerCase()) ? 1 : ((String(b.clientId).toLowerCase() > String(a.clientId).toLowerCase()) ? -1 : 0)) });
        } else {
            projects = [];
            dispatch({ type: '', projects });
        } 
        })
        .catch((error)=>{
            alert("Error while getting the projects!") 
        });
    }
}

export const updateProjectsInStore = (projects) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_PROJECTS', projects });
    }
}

export const createNewProject = (name, clientId, statusId, projects) => {
    return (dispatch, getState) => {
        Services.Projects.createNewProject(name, clientId, statusId, projects)
            .then((data) => {
                var project = {
                    id: data.id,
                    name: data.name,
                    statusId: data.statusId,
                    clientId: data.clientId
                };
                if(data.projectAdded)
                {
                    projects.push(project);
                    project["projectAdded"] = data.projectAdded;
                    project["projectAddedMessage"] = data.projectAddedMessage;
                    dispatch({ type: 'CREATE_NEW_PROJECT', payload: { projects, project }});
                }
                else {
                    project["projectAdded"] = false;
                    project["projectAddedMessage"] = data.projectAddedMessage;
                    dispatch({ type: 'CREATE_NEW_PROJECT', payload: { projects, project }});
                }
            }).catch((error) => {
                var project = { };
                project["projectAdded"] = false;
                project["projectAddedMessage"] = "Error when creating the new project";
                dispatch({ type: '', project });
            });
    }
};    


export const updateProject = (projectId, name, clientId, statusId, projects, capacities, statusName) => {
    return (dispatch, getState) => {
        Services.Projects.updateProject(projectId, name, clientId, statusId, projects, capacities, statusName)
        .then((data) => {
            var project = {
                id: data.id,
                name: data.name,
                clientId: data.clientId
            };
            if(data.projectUpdated) {
                projects.push(project);
                project["projectUpdated"] = data.projectUpdated;
                project["projectUpdatedMessage"] = data.projectUpdatedMessage;
                dispatch({ type: 'UPDATE_PROJECT', payload: { projects, project }});
            }
            else {
                project["projectUpdated"] = false;
                project["projectUpdatedMessage"] = data.projectUpdatedMessage;
                dispatch({ type: 'UPDATE_PROJECT', payload: { projects, project } });
            } 
           }).catch((error) => {
                var project = { };
                project["projectUpdated"] = false;
                project["projectUpdatedMessage"] = "Error when updating the project";
                dispatch({ type: 'UPDATE_PROJECT', payload: { projects, project } });
           });
   };    
};

export const setSelectedProject = (selectedProjectId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_PROJECT_ID', selectedProjectId });      
   };
}