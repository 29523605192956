import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner'
class Overlay extends Component {
    state = { }
    render() { 
        return ( 
            <React.Fragment>
                <div style={{width: '100%', height: '100%', position: 'fixed', zIndex: '9999', backgroundColor: '#282c34', left: '0', right: '0', top: '0', bottom: '0' }}>
                    <div style={{textAlign: 'center', marginTop: '5rem'}}>
                        <Spinner style={{color: 'white'}} animation="border" />
                    </div>
                    <h4 className="text-center mt-3" style={{color: 'white'}}>Loading...</h4>
                </div>
            </React.Fragment>
         );
    }
}
export default Overlay;