import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Textbox from '../shared/textbox';
import { connect } from 'react-redux';
import {
  createNewUser,
  setUsers,
  updateUser,
  sendResetPasswordLink,
  setSelectedUser,
  updateUsersInStore,
} from '../../actions/user-actions';
import {
  setGeneralEnums,
  setUserProjects,
} from '../../actions/general-actions';
import { setInventoryItems } from '../../actions/inventory-actions';
import { setProjects } from '../../actions/projects-actions';
import Spinner from 'react-bootstrap/Spinner';
import Cookies from 'universal-cookie';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import {
  isTextValid,
  generateString,
  isEmailValid,
} from '../../utilities/string.utilities';
import { Services } from '../../api/services';
import { LocalModal } from '../common';

class UsersPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '', //generateString(8),
        userStatus: '',
      },
      emailError: '',
      firstnameError: '',
      lastnameError: '',
      passwordError: '',
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showCreateUserMessage: false,
      userAddMessage: '',
      userUpdatedMessage: '',
      selectedUserId: '',
      processing: false,
      passwordReset: false,
      userRoleButtonText:
        this.props.userRoles[0] && this.props.userRoles.length > 0
          ? this.props.userRoles[this.getUserRole()].name
          : 'Select',
      userTypeButtonText: 'Select',
      userLocationButtonText:
        this.props.locations && this.props.locations.length > 0
          ? this.props.locations[0].name
          : 'Select',
      userStatusButtonText: 'Select',
      selectedRoleId: 0,
      roleError: '',
      selectedTypeId: 0,
      typeError: '',
      selectedLocationId: 0,
      locationError: '',
      selectedStatusId: 0,
      statusError: '',
      processingResetPassword: false,
      showResetPasswordButton: true,
      userRoles: [],
    };
  }

  componentDidMount() {
    const { locations, userRoles, userStatusTypes, userTypes } = this.state;
    this.props.setGeneralEnums(
      locations,
      userRoles,
      userStatusTypes,
      userTypes
    );
  }

  handleTextboxChange = async (e) => {
    await this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        [e.target.name]: e.currentTarget.value,
      },
      alertClass: '',
      showCreateUserMessage: false,
    });
  };

  handleUserSelected = (event) => {
    let userId = parseInt(event.target.value);
    if (userId > 0) {
      const selectedUser = this.props.users.find(function(user) {
        return user.id === userId;
      });
      this.setState({
        ...this.state,
        user: selectedUser,
      });
    } else {
      this.setState({
        ...this.state,
        user: {
          firstName: '',
          lastName: '',
          email: '',
          password: '', //generateString(8)
        },
      });
    }
  };

  componentDidUpdate = async (prevProps) => {
    const {
      selectedUserId,
      locations,
      userStatusTypes,
      userTypes,
      userRoles,
      passwordReset,
      inventoryItems,
      projects,
    } = this.props;
    if (prevProps.user !== this.props.user && this.props.user !== undefined && this.props.user !== null) {
      this.props.setUsers(this.state.users);
      this.setState({
        ...this.state,
        user: {
          firstName: this.props.user.userAdded ? '' : this.state.firstName,
          lastName: this.props.user.userAdded ? '' : this.state.lastName,
          email: this.props.user.userAdded ? '' : this.state.email,
          password: this.props.user.userAdded ? '' : this.state.password,
        },
        users: this.props.users,
        userAddMessage: this.props.user.userAdded
          ? this.state.firstName +
            ' ' +
            this.state.lastName +
            ' has been added.'
          : this.props.user.userAddedMessage,
        userUpdatedMessage: this.props.user.userUpdated
          ? 'The user has been updated.'
          : this.props.user.userUpdatedMessage,
        alertClass:
          this.props.user.userAdded || this.props.user.userUpdated
            ? this.state.baseAlertClass + 'success'
            : this.state.baseAlertClass + 'danger',
        showCreateUserMessage: true,
        processing: false,
        selectedRoleId: this.props.user.userRoleId,
        selectedTypeId: this.props.user.userTypeId,
      });
    } else if (
      prevProps.selectedUserId !== this.props.selectedUserId ||
      (typeof selectedUserId !== 'undefined' &&
        selectedUserId !== null &&
        selectedUserId !== '' &&
        (this.state.user.firstName === '' ||
          typeof this.state.user.firstName === 'undefined') &&
        (this.state.lastName === '' ||
          typeof this.state.user.lastName === 'undefined'))
      /*&& (this.state.email === "" || (typeof this.state.user.email) === 'undefined')*/
    ) {
      this.props.setInventoryItems(inventoryItems, this.props.selectedUserId);
      this.props.setUserProjects();
      this.props.setProjects(projects);
      const selectedUser = this.props.users.find(function(user) {
        return user.id === selectedUserId;
      });
      const userId = new Cookies().get('1950SystemUserId');
      let showResetPasswordButton = true;
      if (userId === selectedUserId) {
        showResetPasswordButton = false;
      }
      let activeUserRoles = [];

      let userRoleButtonText =
        this.props.userRoles && this.props.userRoles.length > 0
          ? this.props.userRoles[this.getUserRole()].name
          : 'Selects';
      let selectedUserRoleId =
        this.props.userRoles && this.props.userRoles.length > 0
          ? this.props.userRoles[this.getUserRole()].id
          : 0;
      userRoles.forEach((userRole) => {
        if (userRole.status === 'Active') {
          activeUserRoles.push(userRole);
          if (
            selectedUser &&
            userRole.id.toString() === selectedUser.userRoleId.toString()
          ) {
            userRoleButtonText = userRole.name;
            selectedUserRoleId = userRole.id;
          }
        }
      });
      let selectedLocation = '',
        selectedUserType = '',
        selectedUserStatusType = '';
      if (typeof selectedUser !== 'undefined' && selectedUser) {
        locations.forEach((location) => {
          if (location.id.toString() === selectedUser.locationId.toString()) {
            selectedLocation = location.name;
          }
        });
        userTypes.forEach((userType) => {
          if (userType.id.toString() === selectedUser.userTypeId.toString()) {
            selectedUserType = userType.name;
          }
        });
        userStatusTypes.forEach((userStatusType) => {
          if (
            userStatusType.id.toString() ===
            selectedUser.userStatusTypeId.toString()
          ) {
            selectedUserStatusType = userStatusType.name;
          }
        });
      }

      selectedLocation =
        selectedLocation !== ''
          ? selectedLocation
          : this.props.locations[0]
          ? this.props.locations[0].name
          : 'SELECT';
      selectedUserType = selectedUserType !== '' ? selectedUserType : 'Select';
      selectedUserStatusType =
        selectedUserStatusType !== '' ? selectedUserStatusType : 'Active';
      this.setState({
        ...this.state,
        user: {
          firstName: selectedUser ? selectedUser.firstName : '',
          lastName: selectedUser ? selectedUser.lastName : '',
          email: selectedUser ? selectedUser.email : '',
          //password: generateString(8) //selectedUser ? selectedUser.password : ""
        },
        userRoleButtonText: userRoleButtonText, //selectedUser ? userRoles.find(role => { if(role.id.toString() === selectedUser.userRoleId.toString()) { return role } return userRoles[0] }).name : "User",
        userTypeButtonText: selectedUserType,
        userLocationButtonText: selectedLocation,
        userStatusButtonText: selectedUserStatusType,
        selectedRoleId: selectedUserRoleId, //selectedUser ? selectedUser.userRoleId : 1,
        selectedTypeId: selectedUser ? selectedUser.userTypeId : 0,
        selectedLocationId: selectedUser
          ? selectedUser.locationId
          : this.props.locations[0]
          ? this.props.locations[0].id
          : 0,
        selectedStatusId: selectedUser
          ? selectedUser.userStatusTypeId
          : this.props.userStatusTypes && this.props.userStatusTypes.length > 0
          ? this.props.userStatusTypes[this.getActiveStatus()].id
          : 0,
        showResetPasswordButton: showResetPasswordButton,
        userRoles: activeUserRoles,
      });
    } else {
      if (prevProps.passwordReset !== passwordReset) {
        this.setState({
          alertClass: passwordReset.linkSent
            ? this.state.baseAlertClass + 'success'
            : this.state.baseAlertClass + 'danger',
          showCreateUserMessage: true,
          processing: false,
          userUpdatedMessage: passwordReset.message,
          processingResetPassword: false,
        });
      } else {
        if (prevProps.userRoles !== userRoles && userRoles.length > 0) {
          let activeUserRoles = [];
          userRoles.forEach((userRole) => {
            if (userRole.status === 'Active') {
              activeUserRoles.push(userRole);
            }
          });
          this.setState({
            userRoles: activeUserRoles,
          });
        }
      }
    }
    if(this.state.userTypeButtonText === 'Select' && this.props.userTypes !== undefined && this.props.userTypes !== null && this.props.userTypes.length > 0) {
      let userTypeButtonText = 'Select', selectedTypeId = 0;
      this.props.userTypes.forEach((userType, index) => {
        if (index === 0) {
          userTypeButtonText = userType.name;
          selectedTypeId = userType.id;
        }
      });
      this.setState({
        ...this.state,
        userTypeButtonText,
        selectedTypeId
      });
    }
  };

  handleCreateUser = async () => {
    console.log('create user');
    const { firstName, lastName, email, password } = this.state.user;
    const emailError = isTextValid(email) ? isEmailValid(email) ? '' : 'Email is not valid' : 'Email is required';
    let selectedLocationId = 0,
      selectedUserRoleId = 0,
      selectedUserStatusTypeId = 0,
      userRoleButtonText = 'User',
      userTypeButtonText = '',
      userLocationButtonText = '',
      userStatusButtonText = 'Active';
    this.props.locations.forEach((location, index) => {
      if (index === 0) {
        selectedLocationId = location.id;
        userLocationButtonText = location.name;
      }
    });
    this.props.userRoles.forEach((userRole) => {
      if (userRole.status === 'Active' && userRole.name === 'User') {
        selectedUserRoleId = userRole.id;
      }
    });
    this.props.userStatusTypes.forEach((userStatusType) => {
      if (userStatusType.name === 'Active') {
        selectedUserStatusTypeId = userStatusType.id;
        userTypeButtonText = userStatusType.name;
      }
    });
    await this.setState({
      ...this.state,
      ...this.state.user,
      alertClass: '',
      showCreateUserMessage: false,
      emailError,
    });
    const formValid = true; //!this.props.needsToFillRegister ? await this.validateUserForm(firstName, lastName) : true;
    const randomPassword = '1950Labs'; //generateString(8);
    if (formValid && emailError === '') {
      this.setState({ processing: true });

      //this.props.createNewUser(this.state.user.email, this.state.user.firstName, this.state.user.lastName, randomPassword, this.state.selectedRoleId, this.state.selectedTypeId, this.state.selectedLocationId, this.state.selectedStatusId, this.props.users);
      this.existsUser(this.state.user.email).then(response => {
        //console.log(response);
        if(!response.success && !response.message) {
          Services.Users.sendSignInLinkToEmail(this.state.user.email,this.state.selectedTypeId, selectedLocationId, selectedUserRoleId, selectedUserStatusTypeId).then(data => {
            if (data && data.success === true)
                this.setState({
                  ...this.state,
                  user: {
                    ...this.state.user,
                    firstName: '',
                    lastName: '',
                    email: '',
                  },
                  processing: false,
                  userAddMessage: 'An email was sent to the user to complete the registration.',
                  alertClass: this.state.baseAlertClass + 'success',
                  showCreateUserMessage: true,
                });
            else {
              this.setState({
                ...this.state,
                userAddMessage: data.message,
                alertClass: this.state.baseAlertClass + 'danger',
                showCreateUserMessage: true,
                processing: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              ...this.state,
              userAddMessage: error.message,
              alertClass: this.state.baseAlertClass + 'danger',
              showCreateUserMessage: true,
              processing: false,
            });
          });
          // Services.Users.createNewUser(
          //   this.state.user.email,
          //   this.state.user.firstName,
          //   this.state.user.lastName,
          //   randomPassword,
          //   selectedUserRoleId,
          //   this.state.selectedTypeId,
          //   selectedLocationId,
          //   selectedUserStatusTypeId,
          //   !this.props.needsToFillRegister ? false : true,
          //   this.props.users
          // )
          // .then((data) => {
          //     //this.props.setUsers(this.state.users);
          //     if (data && data.id)
          //       this.setState({
          //         ...this.state,
          //         user: {
          //           ...this.state.user,
          //           firstName: '',
          //           lastName: '',
          //           email: '',
          //         },
          //         processing: false,
          //         userAddMessage: this.state.firstName === '' && this.state.lastName === '' ? 'User has been added.' :
          //           this.state.firstName +
          //           ' ' +
          //           this.state.lastName +
          //           ' has been added.',
          //         alertClass: this.state.baseAlertClass + 'success',
          //         showCreateUserMessage: true,
          //       });
          //   })
          //   .catch((error) => {
          //     this.setState({
          //       ...this.state,
          //       userAddMessage: error.userAddedMessage,
          //       alertClass: this.state.baseAlertClass + 'danger',
          //       showCreateUserMessage: true,
          //       processing: false,
          //     });
          //   });
        } else {
          this.setState({
            ...this.state,
            userAddMessage: response.message,
            alertClass: this.state.baseAlertClass + 'danger',
            showCreateUserMessage: true,
            processing: false,
          });
        }
      })
      
    }
  };

  existsUser = (email) => {
    return Services.Users.getUsers().then(data => {
        if(data.val() !== null) {
          const response = data.val();
          if(response) {
              for (var key in response) {
                if(response[key].email === email) {
                  return { success: true, message: 'Already exists an user with this email' };
                }
              }
          }
        }
        return { success: false };
    })
    .catch(_ => {
      return { success: false, message: 'Error getting users' };
    })
  }

  handleCloseAlert = () => {
    this.setState({
      ...this.state,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        userStatus: '',
      },
      emailError: '',
      firstnameError: '',
      lastnameError: '',
      passwordError: '',
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showCreateUserMessage: false,
      userAddMessage: '',
      userUpdatedMessage: '',
      selectedUserId: '',
      processing: false,
      passwordReset: false,
      userRoleButtonText:
        this.props.userRoles && this.props.userRoles.length > 0
          ? this.props.userRoles[this.getUserRole()].name
          : 'Select',
      userTypeButtonText: 'Select',
      userLocationButtonText:
        this.props.locations && this.props.locations.length > 0
          ? this.props.locations[0].name
          : 'Select',
      userStatusButtonText:
        this.props.userStatusTypes[0] && this.props.userStatusTypes.length > 0
          ? this.props.userStatusTypes[this.getActiveStatus()].name
          : 'Select',
      selectedRoleId:
        this.props.userRoles && this.props.userRoles.length > 0
          ? this.props.userRoles[this.getUserRole()].id
          : 0,
      roleError: '',
      selectedTypeId: 0,
      typeError: '',
      selectedLocationId:
        this.props.locations && this.props.locations.length > 0
          ? this.props.locations[0].id
          : 0,
      locationError: '',
      selectedStatusId:
        this.props.userStatusTypes[0] && this.props.userStatusTypes.length > 0
          ? this.props.userStatusTypes[this.getActiveStatus()].id
          : 0,
      statusError: '',
      processingResetPassword: false,
      showResetPasswordButton: true,
    });
    this.props.setSelectedUser('');
  };

  handleCloseNotification = () => {
    this.setState({
      ...this.state,
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showCreateUserMessage: false,
      userAddMessage: '',
      userUpdatedMessage: '',
      processing: false,
    });
  };

  getUserRole = () => {
    const usrRole = this.props.userRoles.find((r) => {
      if (r.name === 'User') {
        return this.props.userRoles.indexOf(r);
      }
    });

    return usrRole ? this.props.userRoles.indexOf(usrRole) : 0;
  };

  getActiveStatus = () => {
    const usrStatus = this.props.userStatusTypes.find((r) => {
      if (r.name === 'Active') {
        return this.props.userStatusTypes.indexOf(r);
      }
    });

    return usrStatus ? this.props.userStatusTypes.indexOf(usrStatus) : 0;
  };

  validateUserForm = async (firstName, lastname) => {
    let formValid = false;
    await this.setState({
      ...this.state,
      firstnameError:
        firstName && isTextValid(firstName) ? '' : 'First name is required',
      lastnameError:
        lastname && isTextValid(lastname) ? '' : 'Last name is required',
      roleError: this.state.selectedRoleId === 0 ? 'Role is required' : '',
      typeError: this.state.selectedTypeId === 0 ? 'Type is required' : '',
      statusError:
        this.state.selectedStatusId === 0 ? 'Status is required' : '',
      locationError:
        this.state.selectedLocationId === 0 ? 'Location is required' : '',
    });
    formValid =
      this.state.firstnameError === '' &&
      this.state.lastnameError === '' &&
      this.state.roleError === '' &&
      this.state.typeError === '' &&
      this.state.statusError === '' &&
      this.state.locationError === '';
    return formValid;
  };

  resetPassword = () => {
    this.setState({ processingResetPassword: true });
    this.props.sendResetPasswordLink(this.state.user.email);
  };

  handleClickOutside = () => {
    this.handleCloseAlert();
  };

  render() {
    const {
      modalId,
      modalTitleId,
      selectedUserId,
      locations,
      userStatusTypes,
      userTypes,
    } = this.props;
    const { userRoles } = this.state;
    const isNewUser = selectedUserId === '';
    const currentUserId = new Cookies().get('1950SystemUserId');
    return (
      <LocalModal
        modalName={modalId}
        title={modalTitleId}
        processing={this.state.processing}
        icon="fas fa-user"
        onConfirm={this.handleCreateUser}
        onClose={this.handleCloseAlert}
        onConfirmText={this.props.needsToFillRegister ? 'Send Invitation' : 'Create User'}
      >
        <div className={ this.state.showCreateUserMessage ? "" : "d-none" }>
          <div className={ this.state.alertClass } role="alert">
              { isNewUser ? this.state.userAddMessage : this.state.userUpdatedMessage }
              <button type="button" className="close" onClick={ this.handleCloseNotification }>
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
        </div>
        <div>
          {/* { !this.props.needsToFillRegister ? <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <Textbox
                name="firstName"
                value={this.state.user.firstName || ''}
                errorText={this.state.firstnameError}
                inputLabel="First Name:"
                inputType="text"
                inputPlaceholder="First name"
                inputClass="form-control"
                handleTextboxChange={(e) => this.handleTextboxChange(e)}
                errorMessage={this.state.firstnameError}
                errorLabelClass="text-danger"
                required
              />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <Textbox
                name="lastName"
                value={this.state.user.lastName || ''}
                errorText={this.state.lastnameError}
                inputLabel="Last Name:"
                inputType="text"
                inputPlaceholder="Last name"
                inputClass="form-control"
                handleTextboxChange={(e) => this.handleTextboxChange(e)}
                errorMessage={this.state.lastnameError}
                errorLabelClass="text-danger"
                required
              />
            </div>
          </div>
          : null} */}
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <Textbox
                readOnly={!isNewUser && selectedUserId !== currentUserId}
                name="email"
                value={this.state.user.email || ''}
                errorText={this.state.emailError}
                inputLabel="Email:"
                inputType="email"
                inputPlaceholder="Email"
                inputClass="form-control"
                handleTextboxChange={(e) => this.handleTextboxChange(e)}
                errorMessage={this.state.emailError}
                errorLabelClass="text-danger"
                required
              />
            </div>
            <div className="form-group col-md-6 col-sm-12 col-xs-12" style={{marginBottom: '0px'}}>
              <label className="mb-0" style={{marginBottom: '0px!important', margin: '0rem!important'}}>
                Type:
                <span className="ml-1" style={{ color: 'red' }}>
                  *
                </span>
              </label>
              <div className="dropdown">
                <button
                  style={{ width: '100%' }}
                  className="btn btn-sm btn-info dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.state.userTypeButtonText}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenu2"
                  style={{ width: '100%' }}
                >
                  {userTypes &&
                    userTypes.map((type) => {
                      return (
                        <button
                          key={type.id}
                          onClick={(event) =>
                            this.setState({
                              selectedTypeId: event.target.value,
                              userTypeButtonText: type.name,
                            })
                          }
                          className="dropdown-item"
                          type="button"
                          value={type.id}
                        >
                          {type.name}
                        </button>
                      );
                    })}
                </div>
              </div>
              <p style={{ color: 'red' }}>{this.state.typeError}</p>
            </div>
          </div>
        </div>
      </LocalModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.usersStore.users,
    user: state.usersStore.user,
    selectedUserId: state.usersStore.selectedUserId,
    passwordReset: state.usersStore.passwordReset,
    locations: state.generalStore.locations,
    userRoles: state.generalStore.userRoles,
    userStatusTypes: state.generalStore.userStatusTypes,
    userTypes: state.generalStore.userTypes,
    inventoryItems: state.inventoryStore.inventoryItems,
    userProjects: state.generalStore.userProjects,
    projects: state.projectsStore.projects,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUsers: (users) => dispatch(setUsers(users)),
  createNewUser: (
    email,
    firstName,
    lastName,
    password,
    userRoleId,
    userTypeId,
    userLocationId,
    userSttusTypeId,
    needsToFillRegister,
    users
  ) =>
    dispatch(
      createNewUser(
        email,
        firstName,
        lastName,
        password,
        userRoleId,
        userTypeId,
        userLocationId,
        userSttusTypeId,
        needsToFillRegister,
        users
      )
    ),
  updateUser: (
    userId,
    email,
    firstName,
    lastName,
    userRoleId,
    userTypeId,
    userLocationId,
    userSttusTypeId,
    users
  ) =>
    dispatch(
      updateUser(
        userId,
        email,
        firstName,
        lastName,
        userRoleId,
        userTypeId,
        userLocationId,
        userSttusTypeId,
        users
      )
    ),
  setGeneralEnums: (locations, userRoles, userStatusTypes, userTypes) =>
    dispatch(setGeneralEnums(locations, userRoles, userStatusTypes, userTypes)),
  sendResetPasswordLink: (email) => dispatch(sendResetPasswordLink(email)),
  setSelectedUser: (user) => dispatch(setSelectedUser(user)),
  setInventoryItems: (inventoryItems, userId) =>
    dispatch(setInventoryItems(inventoryItems, userId)),
  setUserProjects: () => dispatch(setUserProjects()),
  setProjects: (projects) => dispatch(setProjects(projects)),
  updateUsersInStore: (users) => dispatch(updateUsersInStore(users)),
});

export const InviteUserPopUp = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersPopUp);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(UsersPopUp));
