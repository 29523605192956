import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Textbox from '../shared/textbox';
import ImageUpload from '../shared/file/image-upload';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import {
  setSelectedButton,
  setUserRoles,
  getUserRolesButtonNavigation,
  createButtonNavigation,
  updateButtonNavigation,
  setButtonNavigationItems,
} from '../../actions/general-actions';
import { SUPER_ADMIN_ROLE_ID } from '../shared/constants/constants';
import { isTextValid } from '../../utilities/string.utilities';
import { LocalModal } from '../common';

class NavigationPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: [],
      userRoles: [],
      button: {
        id: '',
        title: '',
        description: '',
        external: '',
        icon: '',
        image: '',
        link: '',
        originalIconUrl: '',
        originalImageUrl: '',
        originalIconName: '',
        originalImageName: '',
        //order: ""
      },
      titleError: '',
      descriptionError: '',
      userRolesError: '',
      linkError: '',
      iconError: '',
      imageError: '',
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showCreateButtonMessage: false,
      buttonAddMessage: '',
      buttonUpdatedMessage: '',
      selectedButtonId: '',
      processing: false,
      uploadButtonImage: false,
      uploadIcon: false,
      rolesLoaded: false,
      imageThumb: '',
      iconThumb: '',
    };
  }

  handleTextboxChange = async (e) => {
    await this.setState({
      ...this.state,
      button: {
        ...this.state.client,
        [e.target.name]: e.currentTarget.value,
      },
      alertClass: '',
      showCreateButtonMessage: false,
    });
  };

  componentDidMount() {
    this.props.setUserRoles(this.state.userRoles);
  }

  componentDidUpdate = async (prevProps) => {
    //const { selectedButtonId, buttonStatusTypes } = this.props;
    let userRoles = [];
    //let auxUserRoleId = "";
    if (
      typeof this.props.button !== 'undefined' &&
      this.props.button &&
      prevProps.button !== this.props.button &&
      typeof this.props.button.buttonDeleted === 'undefined'
    ) {
      userRoles = this.state.userRoles;
      if (
        typeof this.props.button['rolesIds'] !== 'undefined' &&
        this.props.button['rolesIds']
      ) {
        userRoles.forEach((userRole) => {
          userRole.checked = false;
          this.props.button['rolesIds'].forEach((roleId) => {
            if (userRole.id === roleId) {
              userRole.checked = true;
            }
            //userRoles[roleId]['checked'] = true;
          });
        });
        // Object.keys(this.props.userRoles).map((userRoleId, userRoleIndex) => {
        //     auxUserRoleId = this.props.userRoles[userRoleId].id;
        //     userRoles[auxUserRoleId] = this.props.userRoles[userRoleId];
        //     userRoles[auxUserRoleId]['checked'] = false;
        // });
      }
      this.setState({
        ...this.state,
        button: {
          id: this.props.button.buttonAdded ? '' : this.state.button.id,
          title: this.props.button.buttonAdded ? '' : this.state.button.title,
          description: this.props.button.buttonAdded
            ? ''
            : this.state.button.description,
          external: this.props.button.buttonAdded
            ? ''
            : this.state.button.external,
          originalIconUrl: this.props.button.buttonAdded
            ? ''
            : this.props.button.originalIconUrl,
          originalImageUrl: this.props.button.buttonAdded
            ? ''
            : this.props.button.originalImageUrl,
          link: this.props.button.buttonAdded ? '' : this.state.button.link,
          originalIconName: this.props.button.buttonAdded
            ? ''
            : this.state.button.originalIconName,
          originalImageName: this.props.button.buttonAdded
            ? ''
            : this.state.button.originalImageName,
          image:
            this.props.button.buttonAdded || this.props.button.buttonUpdated
              ? ''
              : this.state.button.image,
          icon:
            this.props.button.buttonAdded || this.props.button.buttonUpdated
              ? ''
              : this.state.button.icon,
        },
        buttonAddMessage: this.props.button.buttonAdded
          ? this.state.title + ' has been added.'
          : this.props.button.buttonAddedMessage,
        buttonUpdatedMessage: this.props.button.buttonUpdated
          ? 'The button has been updated.'
          : this.props.button.buttonUpdatedMessage,
        alertClass:
          this.props.button.buttonAdded || this.props.button.buttonUpdated
            ? this.state.baseAlertClass + 'success'
            : this.state.baseAlertClass + 'danger',
        showCreateButtonMessage: true,
        processing: false,
        uploadIcon:
          this.props.button.buttonAdded || this.props.button.buttonUpdated
            ? false
            : this.state.uploadIcon,
        uploadButtonImage:
          this.props.button.buttonAdded || this.props.button.buttonUpdated
            ? false
            : this.state.uploadButtonImage,
        rolesLoaded: false,
        userRoles: userRoles,
      });
    } else {
      if (
        prevProps.selectedButtonId !== this.props.selectedButtonId &&
        this.props.selectedButtonId !== '' &&
        typeof this.props.selectedButtonId !== 'undefined'
      ) {
        const selectedButton = this.props.buttonNavigationItems.find(
          (navBtn) => {
            if (navBtn.id === this.props.selectedButtonId) {
              return navBtn;
            }
            return undefined;
          }
        );
        //this.props.getUserRolesButtonNavigation();
        if (typeof selectedButton !== 'undefined' && selectedButton) {
          this.setState({
            ...this.state,
            button: {
              id: selectedButton.id,
              title: selectedButton.title,
              description: selectedButton.description,
              external: selectedButton.external,
              link: selectedButton.link,
              originalIconUrl: selectedButton.iconUrl,
              originalImageUrl: selectedButton.imageUrl,
              originalIconName: selectedButton.iconName,
              originalImageName: selectedButton.imageName,
              roles: selectedButton.roles,
            },
            uploadIcon: false,
            uploadButtonImage: false,
            rolesLoaded: false,
          });
        }
      } else {
        if (
          prevProps.userRoles !== this.props.userRoles &&
          this.props.userRoles.length > 0
        ) {
          let userRoles = this.props.userRoles;
          userRoles.forEach((userRole) => {
            userRole['checked'] =
              userRole.id.toString() === SUPER_ADMIN_ROLE_ID ? true : false;
          });
          this.setState({
            userRoles: userRoles,
          });
        } else {
          if (
            this.props.userRolesButtonNavigation &&
            /*prevProps.userRolesButtonNavigation !==*/ this.props
              .userRolesButtonNavigation &&
            Object.keys(this.props.userRolesButtonNavigation).length > 0 &&
            !this.state.rolesLoaded
          ) {
            if (
              this.props.selectedButtonId &&
              this.props.selectedButtonId !== '' &&
              (typeof this.props.button === 'undefined' ||
                (typeof this.props.button !== 'undefined' &&
                  this.props.button &&
                  (typeof this.props.button.buttonUpdated === 'undefined' ||
                    !this.props.button.buttonUpdated)))
            ) {
              // && ) {
              let buttonUserRolesIdAllowed = [];
              let auxUserRole = null;
              //let auxRoleIndex = null;
              //const userRolesKeys = Object.keys(this.props.userRolesButtonNavigation);
              //get user roles id that has permission to see the selected button
              Object.keys(this.props.userRolesButtonNavigation).forEach(
                (userRoleId, userRoleButtonNavigationIndex) => {
                  auxUserRole = userRoleId;
                  //auxRoleIndex = userRoleButtonNavigationIndex;
                  if (
                    typeof this.props.userRolesButtonNavigation[userRoleId] !==
                      'undefined' &&
                    this.props.userRolesButtonNavigation[userRoleId]
                  ) {
                    Object.keys(
                      this.props.userRolesButtonNavigation[userRoleId]
                    ).forEach((buttonNavigationId) => {
                      if (
                        typeof buttonNavigationId !== 'undefined' &&
                        buttonNavigationId === this.props.selectedButtonId
                      ) {
                        buttonUserRolesIdAllowed.push(userRoleId);
                      }
                    });
                  }
                }
              );
              if (
                typeof this.props.buttonNavigationItems !== 'undefined' &&
                this.props.buttonNavigationItems
              ) {
                let buttonNavigationItemsToUpdate = [];
                Object.keys(this.props.buttonNavigationItems).forEach(
                  (buttonNavigationIndex) => {
                    buttonNavigationItemsToUpdate.push(
                      this.props.buttonNavigationItems[buttonNavigationIndex]
                    );
                    if (
                      buttonNavigationItemsToUpdate[buttonNavigationIndex]
                        .id === this.props.selectedButtonId
                    ) {
                      buttonNavigationItemsToUpdate[buttonNavigationIndex][
                        'rolesIds'
                      ] = buttonUserRolesIdAllowed;
                    }
                  }
                );
                this.props.setButtonNavigationItems(
                  buttonNavigationItemsToUpdate
                );
              }
              //get the role information of the roles that are allowed to see the selected button
              let userRoles = this.props.userRoles;
              userRoles.forEach((userRole, index) => {
                auxUserRole = userRole;
                //auxRoleIndex = index+1;
                userRole['checked'] =
                  userRole.id.toString() === SUPER_ADMIN_ROLE_ID ? true : false;
                buttonUserRolesIdAllowed.forEach((buttonUserRoleAllowed) => {
                  if (buttonUserRoleAllowed === auxUserRole.id.toString()) {
                    userRole['checked'] = true;
                  }
                });
              });
              this.setState({
                userRoles: userRoles,
                rolesLoaded: true,
              });
            } else {
              if (
                prevProps.userRolesButtonNavigation !==
                this.props.userRolesButtonNavigation
              )
                this.setState({
                  userRolesButtonNavigation: this.props
                    .userRolesButtonNavigation,
                });
            }
          }
        }
      }
    }
  };

  handleCreateButton = async () => {
    await this.setState({
      ...this.state,
      ...this.state.button,
      alertClass: '',
      showCreateButtonMessage: false,
    });
    //const { title, description, icon, image, link } = this.state.button;
    const formValid = await this.validateButtonForm();
    let order = 0;
    this.props.buttonNavigationItems.forEach((button) => {
      if (button.order > order) {
        order = button.order;
      }
    });
    order += 1;
    if (formValid) {
      this.setState({ processing: true });
      this.props.createButtonNavigation(
        this.state.button,
        order,
        this.state.userRoles,
        this.props.buttonNavigationItems
      );
    }
  };

  handleUpdateButton = async () => {
    await this.setState({
      ...this.state,
      ...this.state.button,
      alertClass: '',
      showCreateButtonMessage: false,
    });
    //const { title, description, icon, image, link } = this.state.button;
    const formValid = await this.validateButtonForm();
    if (formValid) {
      this.setState({ processing: true });
      this.props.updateButtonNavigation(
        this.state.button,
        this.state.uploadIcon,
        //this.state.uploadButtonImage,
        this.state.userRoles,
        this.props.userRolesButtonNavigation,
        this.props.buttonNavigationItems
      );
    }
  };

  handleCloseNotification = () => {
    this.setState({
      buttonAddMessage: '',
      buttonUpdatedMessage: '',
      alertClass: '',
      showCreateButtonMessage: false,
    });
  };

  handleCloseAlert = () => {
    this.props.setSelectedButton('');
    let { userRoles } = this.state;
    userRoles.forEach((userRole) => {
      userRole['checked'] =
        userRole.id.toString() === SUPER_ADMIN_ROLE_ID ? true : false;
    });
    this.setState({
      buttons: [],
      userRoles,
      button: {
        id: '',
        title: '',
        description: '',
        external: '',
        icon: '',
        image: '',
        link: '',
        originalIconUrl: '',
        originalImageUrl: '',
        originalIconName: '',
        originalImageName: '',
      },
      titleError: '',
      descriptionError: '',
      userRolesError: '',
      linkError: '',
      iconError: '',
      imageError: '',
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showCreateButtonMessage: false,
      buttonAddMessage: '',
      buttonUpdatedMessage: '',
      selectedButtonId: '',
      processing: false,
      uploadButtonImage: false,
      uploadIcon: false,
      imageThumb: '',
      iconThumb: '',
    });
  };

  validateButtonForm = async () => {
    const {
      title,
      description,
      icon,
      //image,
      link,
      originalIconUrl,
      //originalImageUrl,
      id,
    } = this.state.button;
    const { uploadIcon/*, uploadButtonImage*/ } = this.state;
    const { buttonNavigationItems } = this.props;
    let formValid = false;
    let userRolesChecked = 0;
    let isBtnNavValid = true;
    this.state.userRoles.forEach((userRole) => {
      if (userRole.checked) {
        userRolesChecked++;
      }
    });
    buttonNavigationItems.forEach((buttonNavigationItem) => {
      if (
        buttonNavigationItem.title.toLowerCase().trim() ===
        title.toLowerCase().trim()
      ) {
        if (typeof id === 'undefined' || id === null || id === '') {
          isBtnNavValid = false;
        } else {
          if (buttonNavigationItem.id.toString() !== id.toString()) {
            isBtnNavValid = false;
          }
        }
      }
    });
    await this.setState({
      ...this.state,
      titleError:
        title && isTextValid(title)
          ? !isBtnNavValid
            ? 'Already exists a button with this title'
            : ''
          : 'Title is required',
      descriptionError:
        description && isTextValid(description)
          ? ''
          : 'Description is required',
      iconError:
        uploadIcon && icon && icon !== ''
          ? ''/*!image || icon.name !== image.name
            ? ''
            : 'Icon name can not be the same as image'*/
          : !uploadIcon && originalIconUrl !== ''
          ? ''
          : 'Icon is required',
      // imageError:
      //   uploadButtonImage && image && image !== ''
      //     ? ''
      //     : !uploadButtonImage && originalImageUrl !== ''
      //     ? ''
      //     : 'Image is required',
      linkError: link
        ? this.isLinkValid(link)
          ? ''
          : 'The link has to contain http://  or https://'
        : 'Link is required',
      userRolesError:
        userRolesChecked > 0 ? '' : 'You have to select one or more roles',
    });
    formValid =
      this.state.titleError === '' &&
      this.state.descriptionError === '' &&
      this.state.iconError === '' &&
      // this.state.imageError === '' &&
      this.state.linkError === '' &&
      this.state.userRolesError === '';
    return formValid;
  };

  isLinkValid = (link) => {
    if (
      isTextValid(link) &&
      (link.includes('http://') || link.includes('https://'))
    )
      return true;
    return false;
  };

  handleTextboxChange = async (e) => {
    await this.setState({
      ...this.state,
      button: {
        ...this.state.button,
        [e.target.name]: e.currentTarget.value,
      },
      alertClass: '',
      showCreateRoleMessage: false,
    });
  };

  // onChangeImage = (e) => {
  //   let files = e.target.files;
  //   if (files.length > 0) {
  //     let imageblob = URL.createObjectURL(files[0]);
  //     this.setState({
  //       button: {
  //         ...this.state.button,
  //         image: files[0],
  //       },
  //       imageThumb: imageblob,
  //       imageError: '',
  //       uploadButtonImage: true,
  //     });
  //   }
  // };

  onChangeIcon = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let iconblob = URL.createObjectURL(files[0]);
      this.setState({
        button: {
          ...this.state.button,
          icon: files[0],
        },
        iconThumb: iconblob,
        iconError: '',
        uploadIcon: true,
      });
    }
  };

  handleCheckboxChange = (e) => {};

  handleCheckboxClick = (e) => {
    const checked = e.target.checked;
    const userRoleId = e.target.value;
    let stateChanged = false;
    let userRoles = JSON.parse(JSON.stringify(this.state.userRoles));
    userRoles.forEach((userRole) => {
      if (
        typeof userRole !== 'undefined' &&
        userRole.id.toString() === userRoleId &&
        userRole.checked !== checked
      ) {
        userRole.checked = checked;
        stateChanged = true;
      }
    });
    if (stateChanged) {
      this.setState({
        userRoles: userRoles,
      });
    }
  };

  handleClickOutside = () => {
    this.handleCloseAlert();
  };

  render() {
    const { modalId, modalTitleId } = this.props;
    const { userRoles } = this.state;
    const buttonId = this.state.button.id;
    const isNewButton =
      typeof buttonId === 'undefined' || buttonId === null || buttonId === '';
    // const imageName =
    //   typeof this.state.button.image !== 'undefined' &&
    //   this.state.button.image !== ''
    //     ? this.state.button.image.name
    //     : '';
    // const showImageName = true;
    const showIconName = true;
    const iconName =
      typeof this.state.button.icon !== 'undefined' &&
      this.state.button.icon !== ''
        ? this.state.button.icon.name
        : '';
    return (
      <LocalModal
        modalName={modalId}
        title={modalTitleId}
        processing={this.state.processing}
        icon="fas fa-link"
        onConfirm={ () => isNewButton ? this.handleCreateButton() : this.handleUpdateButton() }
        onClose={this.handleCloseAlert}
      >
        <div className={ this.state.showCreateButtonMessage ? "" : "d-none" }>
          <div className={ this.state.alertClass } role="alert">
              { isNewButton ? this.state.buttonAddMessage : this.state.buttonUpdatedMessage }
              <button type="button" className="close" onClick={ this.handleCloseNotification }>
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <Textbox
                name="title"
                value={this.state.button.title}
                errorText={this.state.titleError}
                inputLabel="Title"
                inputType="text"
                inputPlaceholder="Title"
                inputClass="form-control"
                handleTextboxChange={(e) => this.handleTextboxChange(e)}
                errorMessage={this.state.titleError}
                errorLabelClass="text-danger"
                required
              />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <Textbox
                name="description"
                value={this.state.button.description}
                errorText={this.state.descriptionError}
                inputLabel="Description"
                inputType="text"
                inputPlaceholder="Description"
                inputClass="form-control"
                handleTextboxChange={(e) => this.handleTextboxChange(e)}
                errorMessage={this.state.descriptionError}
                errorLabelClass="text-danger"
                required
              />
            </div>
          </div>
          <div className="row">
            {/* <div className="col">
              <ImageUpload
                label="Button image"
                onChangeImage={this.onChangeImage}
                errorMessage={this.state.imageError}
                errorLabelClass="text-danger"
                name={imageName}
                required
              />
              {showImageName ? (
                <div className="mt-2" style={{ paddingLeft: '168px' }}>
                  <img
                    alt=""
                    src={
                      isNewButton
                        ? this.state.imageThumb !== ''
                          ? this.state.imageThumb
                          : ''
                        : this.state.imageThumb !== ''
                        ? this.state.imageThumb
                        : this.state.button.originalImageUrl
                    }
                    style={{ width: '30px' }}
                  />
                </div>
              ) : (
                ''
              )}
            </div> */}
            <div className="col-md-6 col-sm-12 col-xs-12">
              <ImageUpload
                label="Icon"
                onChangeImage={this.onChangeIcon}
                errorMessage={this.state.iconError}
                errorLabelClass="text-danger"
                name={iconName}
                required
              />
              {showIconName ? (
                <div className="mt-2" style={{ paddingLeft: '168px' }}>
                  <img
                    alt=""
                    src={
                      isNewButton
                        ? this.state.iconThumb !== ''
                          ? this.state.iconThumb
                          : ''
                        : this.state.iconThumb !== ''
                        ? this.state.iconThumb
                        : this.state.button.originalIconUrl
                    }
                    style={{ width: '30px' }}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <Textbox
                name="link"
                value={this.state.button.link}
                errorText={this.state.linkError}
                inputLabel="Link"
                inputType="text"
                inputPlaceholder="Link"
                inputClass="form-control"
                handleTextboxChange={(e) => this.handleTextboxChange(e)}
                errorMessage={this.state.linkError}
                errorLabelClass="text-danger"
                required
              />
            </div>
          </div>
          {/* <div className="row">
            
          </div> */}
          <div className="row">
            <div className="col">
              <label className="label">
                Roles that can see this button:
                <span className="ml-1" style={{ color: 'red' }}>
                  *
                </span>
              </label>
            </div>
          </div>
          {typeof userRoles !== 'undefined' && userRoles
            ? userRoles.map((userRole, index) => {
                if (
                  typeof userRole !== 'undefined' &&
                  userRole &&
                  userRole.id.toString() === SUPER_ADMIN_ROLE_ID
                ) {
                  return (
                    <div key={userRole.id + 'row'} className="row">
                      <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <input
                          key={userRole.id + 'check'}
                          type="checkbox"
                          checked={userRole.checked}
                          value={userRole.id}
                          onClick={this.handleCheckboxClick}
                          onChange={this.handleCheckboxChange}
                          disabled={true}
                        />
                        <label key={userRole.id + 'label'} className="ml-2">
                          {userRole.name}
                        </label>
                      </div>
                    </div>
                  );
                } else {
                  if (userRole.status === 'Inactive') {
                    return (
                      <div key={userRole.id + 'row'} className="row">
                        <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <input
                            key={userRole.id + 'check'}
                            type="checkbox"
                            checked={userRole.checked}
                            value={userRole.id}
                            onClick={this.handleCheckboxClick}
                            onChange={this.handleCheckboxChange}
                          />
                          <label key={userRole.id + 'label'} className="ml-2">
                            {userRole.name}{' '}
                            <span style={{ color: 'red' }}>(inactive)</span>
                          </label>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={userRole.id + 'row'} className="row">
                        <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <input
                            key={userRole.id + 'check'}
                            type="checkbox"
                            checked={userRole.checked}
                            value={userRole.id}
                            onClick={this.handleCheckboxClick}
                            onChange={this.handleCheckboxChange}
                          />
                          <label key={userRole.id + 'label'} className="ml-2">
                            {userRole.name}
                          </label>
                        </div>
                      </div>
                    );
                  }
                }
              })
            : ''}
          <p>
            Note: Super Admin role has permission to all buttons by default.
          </p>
          <p style={{ color: 'red' }}>{this.state.userRolesError}</p>
        </div>
      </LocalModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    button: state.generalStore.button,
    selectedButtonId: state.generalStore.selectedButtonId,
    buttonNavigationItems: state.generalStore.buttonNavigationItems,
    userRoles: state.generalStore.userRoles,
    userRolesButtonNavigation: state.generalStore.userRolesButtonNavigation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedButton: (buttonId) => dispatch(setSelectedButton(buttonId)),
  setUserRoles: (userRoles) => dispatch(setUserRoles(userRoles)),
  getUserRolesButtonNavigation: () => dispatch(getUserRolesButtonNavigation()),
  createButtonNavigation: (button, order, userRoles, buttonNavigationItems) =>
    dispatch(
      createButtonNavigation(button, order, userRoles, buttonNavigationItems)
    ),
  updateButtonNavigation: (
    button,
    uploadIcon,
    //uploadButtonImage,
    userRoles,
    userRolesButtonNavigation,
    buttonNavigationItems
  ) =>
    dispatch(
      updateButtonNavigation(
        button,
        uploadIcon,
        //uploadButtonImage,
        userRoles,
        userRolesButtonNavigation,
        buttonNavigationItems
      )
    ),
  setButtonNavigationItems: (buttonNavigationItems) =>
    dispatch(setButtonNavigationItems(buttonNavigationItems)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(NavigationPopUp));
