import React, { Component } from 'react';
import Textbox from '../shared/textbox';
import './login-form.css';
import logo from '../../assets/images/logo.png';
import {sendResetPasswordLink} from '../../actions/user-actions'
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner'
import { isEmailValid } from '../../utilities/string.utilities';

class ForgotPassword extends Component {
    state = { 
        email: "",
        emailError: "",
        emailSent: true
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.passwordReset !== this.props.passwordReset && this.props.passwordReset) {
            this.setState({
                ...this.state,
                processing: false
            });
            if(!this.props.passwordReset.linkSent) {
                alert(this.props.passwordReset.message);
            }
        }
    }


    handleTextboxChange = (e) => {
        this.setState({[e.target.name]: e.currentTarget.value});
    }

    sendResetPasswordLink = (e) => {
        const error = this.validateForm();
        if(!error)
        {
            this.setState({
                ...this.state,
                processing: true
            })
            this.props.sendResetPasswordLink(this.state.email);
        }
    }

    validateForm = () => {
        this.setState({
            emailError: "",
            passwordError: ""
        });
        if(this.state.email.length <= 0){
            this.setState({emailError: "Email is required."});
            return true;
        }    
        if(!isEmailValid(this.state.email)) {
            this.setState({emailError: "Email not valid."});
            return true;
        }
    }

    backToLogin = () => {
        this.props.history.push('/');
    }

    render() { 
        console.log(this.state.emailError)
        return ( 
            <React.Fragment >
                {/* <header className="App-header">
                    <a href='/'>
                        <img src={logo} className="App-logo" alt="logo" />
                    </a>
                </header> */}
                <div className='navbar-container margin-navbar' style={{backgroundColor: '#f7f7f7'}}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 navbar-content'>
                                <div className='title-forgot-pw' onClick={this.backToLogin}>DASHBOARD</div>
                            </div>
                        </div>
                    </div>
                </div>
                { !this.props.passwordReset || !this.props.passwordReset.linkSent ?
                <div>
                     {/* style={{backgroundColor: 'white', border: 'none', display: 'flex', paddingBottom: '65px'}} */}
                    <form className="forgot-pw-form-main">
                        <div className="forgot-pw-image d-none d-md-flex d-lg-flex" style={{marginBottom: '32px'}}>
                            <img src={require('../../assets/images/password-recovery.png')} style={{width: '180px', height: '180px'}}/>
                        </div>
                        <div style={{marginLeft: '10%', marginRight: '10%', marginBottom: '32px', textAlign: 'center'}}>
                            <label className='title-forgot-pw'>
                            Did you forgot your password?
                            </label>
                            <label className="text-forgot-pw">
                            Enter your email address below and we will send you the password reset link
                            </label>
                        </div>
                        <Textbox
                            name = "email"
                            errorText={this.state.emailError}
                            inputLabel="Email address"
                            labelClass="email-label-forgot-pw"
                            inputType="email"
                            inputPlaceholder="sample@1950labs.com"
                            inputClass="form-control forgot-pw-placeholder div-email"
                            handleTextboxChange={this.handleTextboxChange.bind(this)}
                            errorMessage={this.state.emailError}
                            errorMessageStyle={{color: 'red'}}
                            errorLabelClass="error-message-forgot-pw"
                            style={{width: '130%!important', marginLeft: '7%!important'}}
                            divEmail={{width: '130%!important', marginLeft: '7%!important', maxWidth: '53%!important'}}
                        />
                            { this.state.processing ? 
                                <div className="forgot-pw-buttons d-sm-none" style={{textAlign: 'center'}}>
                                    <Spinner style={{color: 'white'}} animation="border" />
                                </div> 
                                :
                                <div className="d-none d-md-flex">
                                    <a className="back-to-login" style={{marginTop: '16px', marginRight: "15px"}} onClick={this.backToLogin}>
                                        Back to login
                                    </a>
                                    <button type="button" 
                                    onClick={ this.sendResetPasswordLink}
                                    className="add-new-button d-md-block" style={{width: '183px', marginRight: "20px"}}>
                                        Reset password
                                    </button>
                                </div>
                            }
                    </form>
                    <div className="d-flex d-md-none justify-content-center w-100" style={{marginTop: '-24px'}}>
                        { this.state.processing ? 
                            <div style={{textAlign: 'center'}}>
                                <Spinner style={{color: 'white'}} animation="border" />
                            </div> 
                            :
                            <div>
                                <button type="button" 
                                onClick={ this.sendResetPasswordLink}
                                className="add-new-button" style={{width: '183px'}}>
                                    Reset password
                                </button>
                                <br/><br/>
                                <a className="back-to-login" style={{marginTop: '16px', marginRight: '0px!important'}} onClick={this.backToLogin}>
                                    Back to login
                                </a>
                            </div>
                        }
                    </div>
                </div>
                : 
                    <div style={{color: 'white', textAlign: 'center', marginTop: '4rem'}}>
                        <label style={{color: 'black'}}>An email with a password reset link has been sent to {this.props.passwordReset.email}</label>
                    </div>
                } 
            </React.Fragment>
         );
    }
}


const mapStateToProps = ({usersStore}) => {
    return {
        passwordReset: usersStore.passwordReset
    };
  };
  
  const mapDispatchToProps = dispatch => ({
    sendResetPasswordLink: (email) => dispatch(sendResetPasswordLink(email))
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ForgotPassword);
