import React, { Component } from 'react';
import ClientsPopUp from './client-popup';
import { setClients, setSelectedClient } from '../../actions/clients-actions';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { setClientsStatusType } from '../../actions/general-actions';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa/index';
import Navbar from '../main/navbar';
import { getClients, getClientStatusTypes } from '../../utilities/api.calls';
import { ActionButton, AddNew, LocalTable, Sort } from '../common';
import edit from '../../assets/images/icons/edit.svg';
import { TitlePanel } from '../common/LocalPanels';

class IndexClients extends Component {
  constructor(props) {
    super(props);
    this.showActiveClients = this.showActiveClients.bind(this);
    this.showAllClients = this.showAllClients.bind(this);
    this.handleSelectedEdit = this.handleSelectedEdit.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.state = {
      clients: [],
      clientsWereLoaded: false,
      errorMessage: '',
      clientsStatusTypes: [],
      selectedClientId: '',
      activeClientsOnly: true,
      orderByNameAsc: false,
      orderByStatusAsc: false,
      buttons: [
        {
          id: 1,
          className: 'btn btn-sm btn-success mr-2',
          dataToggle: 'modal',
          dataTarget: '#createClient',
          title: 'Add Client',
          onClick: this.handleSelectedEdit.bind(this),
        },
        {
          id: 2,
          className: 'btn btn-sm btn-primary',
          onClick: this.showAllClients.bind(this),
          title: 'Show All',
        },
      ],
      sortField: '',
      sortDirection: 'asc',
      tableColumns: [
        {
          position: 1,
          field: 'name',
          label: 'Name',
          sortable: true,
        },

        {
          stylesHeader: { maxWidth: '40px' },
          stylesCell: { maxWidth: '40px' },
          position: 3,
          field: 'statusType',
          label: 'Status',
          sortable: true,
          customComponent: (row) => {
            return (
              <div className="d-flex justify-content-start">
                <div
                  className="status-dot"
                  style={{
                    position: 'relative',
                    left: '20px',
                    backgroundColor:
                      row.statusType === 'Active' ? '#73FF98' : '#FF6666',
                  }}
                ></div>
              </div>
            );
          },
        },

        {
          stylesHeader: { maxWidth: '40px', textAlign: 'right' },
          stylesCell: { textAlign: 'right' },
          position: 4,
          field: '',
          label: 'Actions',
          sortable: false,
          type: 'button',
          customComponent: (row) => {
            return (
              <div className="button-container">
                <ActionButton
                  row={row}
                  dataToggle="modal"
                  dataTarget="#createClient"
                  handleAction={() => this.handleSelectedEdit(row.id)}
                  icon="fa fa-edit icon edit"
                  iconSrc={edit}
                  color=""
                />
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount = () => {
    // this.props.setClientsStatusType(this.state.clientsStatusTypes);
    // this.props.setClients(this.state.clients);
    getClientStatusTypes().then((responseStatus) => {
      if (responseStatus && responseStatus.success === true) {
        var clientsStatusTypesById = [];
        responseStatus.clientsStatusTypes.forEach((clientStatusType) => {
          clientsStatusTypesById[clientStatusType.id] = clientStatusType.name;
        });
        getClients().then((response) => {
          if (response && response.success === true) {
            let clients = response.clients;
            clients.forEach((client) => {
              client['statusType'] =
                typeof clientsStatusTypesById[client.statusId] !== 'undefined'
                  ? clientsStatusTypesById[client.statusId]
                  : null;
            });
            this.setState({
              ...this.state,
              clients: clients,
              clientsWereLoaded: true,
            });
          }
          if (response && response.success === false) {
            this.setState({
              ...this.state,
              clientsWereLoaded: true,
              errorMessage: response.error,
            });
          }
        });
      }
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.clients !== this.props.clients ||
      prevProps.clientsStatusTypes !== this.props.clientsStatusTypes
    ) {
      if (typeof this.props.clients !== 'undefined' && this.props.clients) {
        var clients = this.props.clients;
        const { clientsStatusTypes } = this.props;
        if (
          typeof clientsStatusTypes !== 'undefined' &&
          clientsStatusTypes &&
          clientsStatusTypes.length > 0
        ) {
          var clientsStatusTypesById = [];
          clientsStatusTypes.forEach((clientStatusType) => {
            clientsStatusTypesById[clientStatusType.id] = clientStatusType.name;
          });
          clients.forEach((client) => {
            client['statusType'] =
              typeof clientsStatusTypesById[client.statusId] !== 'undefined'
                ? clientsStatusTypesById[client.statusId]
                : null;
          });
          this.setState({
            ...this.state,
            clients: clients,
          });
        } else {
          this.setState({
            ...this.state,
            clients: this.props.clients,
          });
        }
      }
    }
  };

  handleSelectedEdit = (id) => {
    this.setState({
      selectedClientId: id,
    });
    this.props.setSelectedClient(id);
  };

  sortClients = (field, sortDirection) => {
    const asc = sortDirection === 'asc';
    let { clients } = this.state;
    clients = clients.sort((a, b) =>
      a[field].toLowerCase() > b[field].toLowerCase()
        ? asc
          ? 1
          : -1
        : b[field].toLowerCase() > a[field].toLowerCase()
        ? asc
          ? -1
          : 1
        : 0
    );

    this.setState({
      clients: clients,
      sortField: field,
      sortDirection: sortDirection,
    });
  };

  showActiveClients = async () => {
    let buttons = this.state.buttons;
    buttons[1]['onClick'] = this.showAllClients.bind(this);
    buttons[1]['title'] = 'Show All';
    await this.setState({
      activeClientsOnly: true,
      buttons,
    });
  };

  showAllClients = async () => {
    let buttons = this.state.buttons;
    buttons[1]['onClick'] = this.showActiveClients.bind(this);
    buttons[1]['title'] = 'Show Active clients only';
    await this.setState({
      activeClientsOnly: false,
      buttons,
    });
  };

  goToHome = () => {
    this.props.history.push('/app');
  };

  render() {
    const { clients, sortField, sortDirection, tableColumns } = this.state;

    if (clients.length <= 0) {
      return (
        <React.Fragment>
          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <Spinner style={{ color: 'white' }} animation="border" />
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <TitlePanel
          title="Clients"
          total={clients.length}
          history={this.props.history}
          icon="far fa-handshake"
        />
        <Filter
          {...this.props}
          handleNewItem={() => this.handleSelectedEdit('')}
          sortField={sortField}
          handleSort={this.sortClients}
          tableColumns={tableColumns}
        ></Filter>

        <Items
          items={clients}
          sortDirection={sortDirection}
          sortField={sortField}
          handleSort={this.sortClients}
          tableColumns={tableColumns}
          handleSelectedEdit={this.handleSelectedEdit}
        />

        <ClientsPopUp
          modalId="createClient"
          modalTitleId={
            this.state.selectedClientId === ''
              ? 'Create Client'
              : 'Update Client'
          }
        />
      </React.Fragment>
    );
  }
}

const Filter = ({
  children,
  handleSort,
  sortField,
  handleNewItem,
  tableColumns,
}) => {
  return (
    <div className="container ">
      <div className="row filter-container">
        <div className="col-10 col-sm-10 col-md-3 col-lg-3 d-none">
          <div className="inner-addon right-addon">
            <i className="fa fa-search icon"></i>
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="col-md-10 col-lg-10 d-none d-md-block">{children}</div>
        <div className="col-12 col-md-2 filter-buttons-container">
          <AddNew handleNew={handleNewItem} dataTarget="#createClient" />
          <Sort
            handleSort={handleSort}
            sortField={sortField}
            tableColumns={tableColumns}
          />
        </div>
      </div>
    </div>
  );
};

const Items = (props) => {
  return (
    <>
      <Cards {...props} />
      <LocalTable
        {...{
          ...props,
          rows: props.items,
          columns: props.tableColumns,
        }}
      />
    </>
  );
};

const Card = ({ id, name, statusType, handleSelectedEdit }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="col-12 col-lg-3 col-md-4 col-sm-6 col-exsm-12">
      <div className="inventory-card">
        <div>{name}</div>
        <div className="card-grey">{statusType}</div>
        {open && (
          <>
            <div className="action-buttons-card-container w-100 mt-1 d-flex justify-content-center">
            <ActionButton
                  dataToggle="modal"
                  dataTarget="#createClient"
                  handleAction={() => handleSelectedEdit(id)}
                  icon="fa fa-edit icon edit"
                  iconSrc={edit}
                  color=""
                />
            </div>
          </>
        )}
        <div className="see-more-container">
          <div className="see-more" onClick={() => setOpen(!open)}>
            <i className={'fas fa-chevron-' + (open ? 'up' : 'down')} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Cards = ({ items, handleSelectedEdit }) => {
  return (
    <div className="container d-md-none cards-container">
      <div className="row">
        {items.map((item) => (
          <Card key={'client_' + item.name} {...item}
          handleSelectedEdit={handleSelectedEdit} />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    clients: state.clientsStore.clients,
    clientsStatusTypes: state.generalStore.clientsStatusTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setClients: (clients) => dispatch(setClients(clients)),
  setSelectedClient: (clientId) => dispatch(setSelectedClient(clientId)),
  setClientsStatusType: (clientsStatusTypes) =>
    dispatch(setClientsStatusType(clientsStatusTypes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexClients);
