import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Textbox from '../shared/textbox';
import { connect } from 'react-redux';
import {
  createNewProject,
  setProjects,
  updateProject,
  setSelectedProject,
} from '../../actions/projects-actions';
import { setClients } from '../../actions/clients-actions';
import Spinner from 'react-bootstrap/Spinner';
import {
  setProjectsStatusType,
  getCapacities,
} from '../../actions/general-actions';
import { setUsers } from '../../actions/user-actions';
import { isTextValid } from '../../utilities/string.utilities';
import { LocalModal } from '../common';

class ProjectsPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      projectsStatusTypes: [],
      project: {
        id: '',
        name: '',
        clientId: '',
        statusId: '',
      },
      nameError: '',
      statusTypeError: '',
      clientIdError: '',
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showCreateProjectMessage: false,
      projectAddMessage: '',
      projectUpdatedMessage: '',
      selectedProjectId: '',
      processing: false,
      clientButtonText: 'Select',
      selectedClientId: 0,
      projectStatusTypeButtonText: 'Select',
      selectedStatusTypeId: '',
      loadUpdateForm: false,
      showWarnMessage: false,
      warnClass: '',
    };
  }

  componentDidMount() {
    this.props.setProjectsStatusType(this.state.projectsStatusTypes);
    this.props.setClients(this.state.clients);
    let users = [];
    this.props.setUsers(users);
  }

  handleTextboxChange = async (e) => {
    await this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        [e.target.name]: e.currentTarget.value,
      },
      alertClass: '',
      showCreateProjectMessage: false,
    });
  };

  handleProjectSelected = (event) => {
    let projectId = parseInt(event.target.value);
    if (projectId > 0) {
      const selectedProject = this.props.projects.find(function(project) {
        return project.id === projectId;
      });
      this.setState({
        ...this.state,
        project: selectedProject,
      });
    } else {
      this.setState({
        ...this.state,
        project: {
          id: '',
          name: '',
          clientId: '',
          statusId: '',
        },
      });
    }
  };

  componentDidUpdate = async (prevProps) => {
    
    const { selectedProjectId, clients, projectsStatusTypes } = this.props;
    if (prevProps.project !== this.props.project) {
      this.props.setProjects(this.state.projects);
      if (
        typeof projectsStatusTypes !== 'undefined' &&
        projectsStatusTypes &&
        projectsStatusTypes.length > 0
      ) {
        this.setState({
          ...this.state,
          project: {
            id: this.props.project.projectAdded ? '' : this.state.id,
            name: this.props.project.projectAdded ? '' : this.state.name,
            clientId: this.props.project.projectAdded
              ? ''
              : this.state.clientId,
          },
          projects: this.props.projects,
          projectAddMessage: this.props.project.projectAdded
            ? this.state.name + ' has been added.'
            : this.props.project.projectAddedMessage,
          projectUpdatedMessage: this.props.project.projectUpdated
            ? 'The project has been updated.'
            : this.props.project.projectUpdatedMessage,
          alertClass:
            this.props.project.projectAdded || this.props.project.projectUpdated
              ? this.state.baseAlertClass + 'success'
              : this.state.baseAlertClass + 'danger',
          showCreateProjectMessage: true,
          processing: false,
        });
      } else {
        this.setState({
          ...this.state,
          ...this.state.project,
          projects: this.props.projects,
          projectAddMessage: this.props.project.projectAdded
            ? this.state.name + ' has been added.'
            : this.props.project.projectAddedMessage,
          projectUpdatedMessage: this.props.project.projectUpdated
            ? 'The project has been updated.'
            : this.props.project.projectUpdatedMessage,
          alertClass:
            this.props.project.projectAdded || this.props.project.projectUpdated
              ? this.state.baseAlertClass + 'success'
              : this.state.baseAlertClass + 'danger',
          showCreateProjectMessage: true,
          processing: false,
        });
      }
    } else {
      if (prevProps.selectedProjectId !== this.props.selectedProjectId) {
        const selectedProject = this.props.projects.find(function(project) {
          return project.id === selectedProjectId;
        });
        if (
          typeof this.props.selectedProjectId !== 'undefined' &&
          this.props.selectedProjectId
        ) {
          this.props.getCapacities(this.props.selectedProjectId);
        }
        if (
          prevProps.projectsStatusTypes !== projectsStatusTypes &&
          typeof projectsStatusTypes !== 'undefined' &&
          projectsStatusTypes &&
          projectsStatusTypes.length > 0
        ) {
          this.setState({
            ...this.state,
            project: {
              id: selectedProject ? selectedProject.id : '',
              name: selectedProject ? selectedProject.name : '',
              clientId: selectedProject ? selectedProject.clientId : '',
              statusId: selectedProject ? selectedProject.statusId : '',
            },
            clientButtonText: selectedProject
              ? clients.find((client) => {
                  if (
                    client.id.toString() === selectedProject.clientId.toString()
                  ) {
                    return client;
                  }
                  return undefined;
                }).name
              : 'Select',
            selectedClientId: selectedProject
              ? selectedProject.selectedClientId
              : 0,
            projectStatusTypeButtonText: selectedProject
              ? projectsStatusTypes.find((statusType) => {
                  if (
                    statusType.id.toString() ===
                    selectedProject.statusId.toString()
                  ) {
                    return statusType;
                  }
                  return undefined;
                }).name
              : 'Active',
            selectedStatusTypeId: selectedProject
              ? selectedProject.statusId
              : '-LVKu1L8RQg7woJlkpC5',
          });
        } else {
          this.setState({
            ...this.state,
            project: {
              id: selectedProject ? selectedProject.id : '',
              name: selectedProject ? selectedProject.name : '',
              clientId: selectedProject ? selectedProject.clientId : '',
              statusId: selectedProject ? selectedProject.statusId : '',
            },
          });
        }
      } else {
        if (
          typeof this.props.selectedProjectId !== 'undefined' &&
          this.props.selectedProjectId !== '' &&
          this.state.project.statusId !== '' &&
          (prevProps.projectsStatusTypes !== projectsStatusTypes ||
            !this.state.loadUpdateForm) &&
          typeof projectsStatusTypes !== 'undefined' &&
          projectsStatusTypes &&
          projectsStatusTypes.length > 0
        ) {
          this.setState({
            ...this.state,
            clientButtonText: clients.find((client) => {
              if (
                client.id.toString() === this.state.project.clientId.toString()
              ) {
                return client;
              }
              return undefined;
            }).name,
            selectedClientId: this.state.project.clientId,
            projectStatusTypeButtonText: projectsStatusTypes.find(
              (statusType) => {
                if (statusType.id.toString() === this.state.project.statusId) {
                  return statusType;
                }
                return undefined;
              }
            ).name,
            selectedStatusTypeId: this.state.project.statusId,
            loadUpdateForm: true,
          });
        }
      }
    }
    if (
      (prevProps.clients !== clients &&
        typeof clients !== 'undefined' &&
        clients &&
        clients.length > 0) ||
      (typeof clients !== 'undefined' &&
        clients &&
        clients.length > 0 &&
        this.state.clients > 0)
    ) {
      let clientsToAdd = clients.filter(
        (client) => client.statusId === '-L9rJozap1lBNrkFHk54'
      );
      this.setState({
        clients: clientsToAdd,
      });
    }
  };

  handleCreateProject = async () => {
    await this.setState({
      ...this.state,
      ...this.state.project,
      alertClass: '',
      showCreateProjectMessage: false,
    });
    const { name, selectedClientId, selectedStatusTypeId } = this.state;
    let formValid = true;
    if (this.isDuplicatedProject(name, selectedClientId)) {
      formValid = false;
      await this.setState({
        ...this.state,
        showCreateProjectMessage: true,
        projectAddMessage:
          'There is actually a project with the provided name and client',
        alertClass: this.state.baseAlertClass + 'danger',
      });
    } else {
      formValid = await this.validateProjectForm(
        name,
        selectedClientId,
        selectedStatusTypeId
      );
    }
    if (formValid) {
      this.setState({ processing: true });
      this.props.createNewProject(
        name,
        selectedClientId,
        selectedStatusTypeId,
        this.props.projects
      );
    }
  };

  handleUpdateProject = async () => {
    await this.setState({
      ...this.state,
      ...this.state.project,
      alertClass: '',
      showCreateProjectMessage: false,
    });
    const {
      name,
      selectedClientId,
      project,
      selectedStatusTypeId,
      projectStatusTypeButtonText,
    } = this.state;
    const clientId =
      selectedClientId && selectedClientId !== ''
        ? selectedClientId
        : project.clientId;
    let formValid = true;
    if (this.isDuplicatedProject(name, clientId)) {
      formValid = false;
      await this.setState({
        ...this.state,
        showCreateProjectMessage: true,
        projectUpdatedMessage:
          'There is actually a project with the provided name and client',
        alertClass: this.state.baseAlertClass + 'danger',
      });
    } else {
      formValid = await this.validateProjectForm(
        name,
        clientId,
        selectedStatusTypeId
      );
    }
    if (formValid) {
      this.setState({ processing: true });
      this.props.updateProject(
        this.props.selectedProjectId,
        name,
        clientId,
        selectedStatusTypeId,
        this.props.projects,
        this.props.capacities,
        projectStatusTypeButtonText
      );
    }
  };

  handleCloseAlert = () => {
    this.props.setSelectedProject('');
    this.setState({
      ...this.state,
      showCreateProjectMessage: false,
      project: {
        name: '',
        clientId: '',
        statusId: '',
      },
      nameError: '',
      clientIdError: '',
      statusTypeError: '',
      clientButtonText: 'Select',
      selectedClientId: 0,
      projectStatusTypeButtonText: 'Select',
      selectedStatusTypeId: '',
      loadUpdateForm: false,
    });
  };

  handleCloseNotification = () => {
    this.setState({
      ...this.state,
      showCreateProjectMessage: false,
      projectAddMessage: '',
      projectUpdatedMessage: '',
      alertClass: '',
    });
  };
  isDuplicatedProject = (name, clientId) => {
    let isDuplicated = false;
    this.props.projects.forEach((project) => {
      if (
        project.name.toLowerCase() === name.toLowerCase() &&
        project.clientId === clientId &&
        ((typeof this.props.selectedProjectId !== 'undefined' &&
          this.props.selectedProjectId &&
          project.id.toString() !== this.props.selectedProjectId) ||
          typeof this.props.selectedProjectId === 'undefined' ||
          this.props.selectedProjectId === null ||
          this.props.selectedProjectId === '')
      )
        isDuplicated = true;
    });
    return isDuplicated;
  };

  validateProjectForm = async (name, clientId, statusId) => {
    let formValid = false;
    await this.setState({
      ...this.state,
      nameError: name && isTextValid(name) ? '' : 'Name is required',
      clientIdError: clientId !== 0 ? '' : 'Client is required',
      statusTypeError: statusId !== '' ? '' : 'Status is required',
    });
    formValid =
      this.state.nameError === '' &&
      this.state.clientIdError === '' &&
      this.state.statusTypeError === '';
    return formValid;
  };

  getProjectStatusType = (projectStatusTypeId) => {
    let project;
    this.props.projectsStatusTypes.forEach((projectStatus) => {
      if (projectStatus.id === projectStatusTypeId) {
        project = projectStatus;
      }
    });
    return project;
  };

  getUserName = (userId) => {
    let user = null;
    this.props.users.forEach((usr) => {
      if (usr.id === userId) {
        user = usr;
      }
    });
    if (typeof user !== 'undefined' && user)
      return user.firstName + ' ' + user.lastName;
    return user;
  };

  handleStatusChange = (e, statusTypeName) => {
    let projectStatus = this.getProjectStatusType(e.target.value);
    if (
      typeof projectStatus !== 'undefined' &&
      projectStatus &&
      projectStatus.name === 'Inactive' &&
      this.props.capacities.length > 0
    ) {
      this.setState({
        warnClass: this.state.baseAlertClass + 'warning',
        showWarnMessage: true,
        selectedStatusTypeId: e.target.value,
        projectStatusTypeButtonText: statusTypeName,
      });
    } else {
      this.setState({
        warnClass: '',
        selectedStatusTypeId: e.target.value,
        projectStatusTypeButtonText: statusTypeName,
        showWarnMessage: false,
      });
    }
  };

  handleClickOutside = () => {
    this.handleCloseAlert();
  };

  render() {
    const {
      modalId,
      modalTitleId,
      selectedProjectId,
      projectsStatusTypes,
    } = this.props;
    const { clients } = this.state;
    const isNewProject = selectedProjectId === '';
    return (
      <LocalModal
        modalName={modalId}
        title={modalTitleId}
        processing={this.state.processing}
        icon="fas fa-project-diagram"
        onConfirm={() =>
          isNewProject ? this.handleCreateProject() : this.handleUpdateProject()
        }
        onClose={this.handleCloseAlert}
      >
        <div className={this.state.showCreateProjectMessage ? '' : 'd-none'}>
          <div className={this.state.alertClass} role="alert">
            {isNewProject
              ? this.state.projectAddMessage
              : this.state.projectUpdatedMessage}
            <button
              type="button"
              className="close"
              onClick={this.handleCloseNotification}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <Textbox
                name="name"
                value={this.state.project.name || ''}
                errorText={this.state.nameError}
                inputLabel="Name:"
                inputType="text"
                inputPlaceholder="Name"
                inputClass="form-control"
                handleTextboxChange={(e) => this.handleTextboxChange(e)}
                errorMessage={this.state.nameError}
                errorLabelClass="text-danger"
                required
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 form-group">
              <label className="label">
                Client:
                <span className="ml-1" style={{ color: 'red' }}>
                  *
                </span>
              </label>
              <div className="dropdown">
                <button
                  style={{ width: '100%' }}
                  className="btn btn-sm btn-info dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.state.clientButtonText}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  {clients &&
                    clients.map((client) => {
                      return (
                        <button
                          key={client.id}
                          onClick={(event) =>
                            this.setState({
                              selectedClientId: event.target.value,
                              clientButtonText: client.name,
                            })
                          }
                          className="dropdown-item"
                          type="button"
                          value={client.id}
                        >
                          {client.name}
                        </button>
                      );
                    })}
                </div>
              </div>
              <div className="text-danger">{this.state.clientIdError}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <label className="label">
                Status:
                <span className="ml-1" style={{ color: 'red' }}>
                  *
                </span>
              </label>
              <div className="dropdown">
                <button
                  style={{ width: '100%' }}
                  className="btn btn-sm btn-info dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.state.projectStatusTypeButtonText}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  {projectsStatusTypes &&
                    projectsStatusTypes.map((statusType) => {
                      return (
                        <button
                          key={statusType.id}
                          onClick={(event) =>
                            this.handleStatusChange(event, statusType.name)
                          }
                          className="dropdown-item"
                          type="button"
                          value={statusType.id}
                        >
                          {statusType.name}
                        </button>
                      );
                    })}
                </div>
              </div>
              <p style={{ color: 'red' }}>{this.state.statusTypeError}</p>
            </div>
          </div>
        </div>
      </LocalModal>
    );
  }
}

const mapStateToProps = (state) => {
    
  return {
    clients: state.clientsStore.clients,
    projects: state.projectsStore.projects,
    project: state.projectsStore.project,
    selectedProjectId: state.projectsStore.selectedProjectId,
    projectsStatusTypes: state.generalStore.projectsStatusTypes,
    capacities: state.generalStore.capacities,
    users: state.usersStore.users,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setClients: (clients) => dispatch(setClients(clients)),
  setProjects: (projects) => dispatch(setProjects(projects)),
  createNewProject: (name, clientId, statusId, projects) =>
    dispatch(createNewProject(name, clientId, statusId, projects)),
  updateProject: (
    projectId,
    name,
    clientId,
    statusId,
    projects,
    capacities,
    status
  ) =>
    dispatch(
      updateProject(
        projectId,
        name,
        clientId,
        statusId,
        projects,
        capacities,
        status
      )
    ),
  setProjectsStatusType: (projectsStatusTypes) =>
    dispatch(setProjectsStatusType(projectsStatusTypes)),
  setSelectedProject: (projectId) => dispatch(setSelectedProject(projectId)),
  getCapacities: (projectId) => dispatch(getCapacities(projectId)),
  setUsers: (users) => dispatch(setUsers(users)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(ProjectsPopUp));
