import React from 'react';
import './styles.css';

const TitlePanel = ({ children, title, history, total, icon, withFilter, onBack }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = (value) => {
    setOpen(value);
  };

  return (
    <div className="inventory-header-container">
      <div className="container">
        <div className="row">
          <div className="col-10 inventory-header">
            <div className="back-container" onClick={!onBack ? () => history.goBack() : onBack}>
              <i className="fa fa-chevron-left icon"></i>
            </div>
            <div className="clipboard-container">
              <i className={icon}></i>
            </div>
            <div className="inventory-title">{title}</div>
            {total && <div className="bag d-none d-md-block">{total}</div>}
          </div>
          {withFilter && (
            <div className="col-2 d-flex d-md-none filter-button-container">
              <div className="filter-button" onClick={() => handleOpen(!open)}>
                <i className="fas fa-filter icon" />
              </div>
            </div>
          )}
        </div>
        {open && (
          <div className="row filter-fields-container d-md-none">
            {children}
            <button
              className="add-new-button"
              onClick={() => handleOpen(false)}
            >
              See results
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export { TitlePanel };
