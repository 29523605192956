import firebase from 'firebase/app';
import "firebase/database";
import { PRINT_IN_CONSOLE } from '../../components/shared/constants/constants'

export class ClientsApi {
    setClients() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`Clients`)
            .once("value")
            .then((clients) => {
                resolve(clients);
            });    
        });
    }

    createNewClient(name, statusId, clients) {
        return new Promise((resolve, reject) => {
            const clientKey = firebase
            .database()
            .ref(`Clients`)
            .push({
                name,
                statusId
            }).getKey();

            firebase.database()
                .ref(`Clients/${clientKey}`)
                .update({ id: clientKey })
                .then(()=> { 
                    resolve({
                        id: clientKey,
                        name,
                        statusId,
                        clients,
                        clientAdded: true,
                        clientUpdated: false,
                        clientAddedMessage: 'Successfully created new client:' + name
                    }); 
                })
                .catch((error) => {
                    if(PRINT_IN_CONSOLE) {
                        console.log('Error creating client:', error.message);
                    }
                    resolve(null); 
            })
        });
    }

    updateClient(clientId, name, statusId, clients) {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`Clients/${clientId}`)
            .update({ 
                name,
                statusId
            })
            .then(()=> { 
                resolve({
                    id: clientId,
                    name,
                    statusId,
                    clients,
                    clientAdded: false,
                    clientUpdated: true,
                    clientAddedMessage: 'Successfully updated the client'
                }); 
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error updating the client:', error.message);
                }
                resolve(null); 
        })
        });
    }
}
