import React, { Component } from 'react';

class ImageUpload extends Component {
    state = {  }
    render() {
        const labelInfo = (typeof this.props.name) !== 'undefined' && this.props.name && this.props.name.length > 0 ? this.props.name : "Choose or drop";
        return ( 
            <React.Fragment>
                {this.props.required ? 
                    <label className="label">{this.props.label}<span className="ml-1" style={{color: 'red'}}>*</span></label>
                    :
                    <label className="label">{this.props.label}</label>
                }
                <div className="input-group">
                    <div className="custom-file">
                        <input
                            type="file"
                            accept="image/*"  
                            className="custom-file-input"
                            id="inputGroupFile01"
                            aria-describedby="inputGroupFileAddon01"
                             
                            onChange={this.props.onChangeImage}
                        />
                        <label style={{overflow: 'hidden'}} className="custom-file-label" htmlFor="inputGroupFile01">{labelInfo}</label>
                    </div>
                </div>
                <div className={ this.props.errorLabelClass }>{this.props.errorMessage}</div>
            </React.Fragment>
         );
    }
}
 
export default ImageUpload;