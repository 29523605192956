import firebase from 'firebase/app';
import "firebase/database";
import { PRINT_IN_CONSOLE } from '../../components/shared/constants/constants'
//console.log(process.env);
import { config } from '../../utilities/env';

//const config = process.env.REACT_APP_PRODUCTION === 'production' ? require('../config/1950labs-prod.json') : (process.env.REACT_APP_TESTING === 'testing' ? require('../config/1950labs-testing.json') : require('../config/1950labs-staging.json'));
//console.log(config);
firebase.initializeApp(config);

export class GeneralApi {

    getProjectsStatusTypes() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`ProjectsStatusTypes`)
            .once("value")
            .then((projectsStatusTypes) => {
                resolve(projectsStatusTypes);
            });    
        });
    }

    getClientsStatusTypes() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`ClientsStatusTypes`)
            .once("value")
            .then((clientStatusTypes) => {
                resolve(clientStatusTypes);
            });    
        });
    };

    getUserRoles() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserRoles`)
            .once("value")
            .then((userRoles) => {
                resolve(userRoles);
            });    
        });
    };

    getUserStatusTypes() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserStatusTypes`)
            .once("value")
            .then((userStatusTypes) => {
                resolve(userStatusTypes);
            });    
        });
    }

    getUserStatusType(id) {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserStatusTypes/${id}`)
            .once("value")
            .then((userStatusType) => {
                resolve(userStatusType);
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('error getting user status type with id: ' + id, error.message);
                }
                resolve(null);
            });
        });
    }

    getUserTypes() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserTypes`)
            .once("value")
            .then((userTypes) => {
                resolve(userTypes);
            });    
        });
    }

    getUserProjects() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserProjects`)
            .once("value")
            .then((userProjects) => {
                resolve(userProjects);
            });    
        });
    }

    createNewUserProject(userId, projectId, beginDate, endDate, userProjects, status) {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserProjects/${userId}`)
            .once("value")
            .then((userProjects) => {
                if(userProjects !== null) {
                    const userProjectKey = userProjects.ref.push({
                        userId,
                        projectId, 
                        beginDate, 
                        endDate,
                        status
                    }).getKey();

                    firebase.database()
                    .ref(`UserProjects/${userId}/${userProjectKey}`)
                    .update({ 
                        id: userProjectKey 
                    })
                    .then(()=> { 
                        resolve({
                            id: userProjectKey,
                            userId,
                            projectId, 
                            beginDate, 
                            endDate,
                            userProjects,
                            userProjectAdded: true,
                            userProjectUpdated: false,
                            status,
                            userProjectAddedMessage: 'Successfully created capacity'
                        }); 
                    })
                    .catch((error) => {
                        if(PRINT_IN_CONSOLE) {
                            console.log('Error creating user capacity:', error.message);
                        }
                        resolve(null); 
                    })
                } else {
                    const userProjectKey = firebase
                    .database()
                    .ref(`UserProjects/${userId}`)
                    .push({
                        userId,
                        projectId,
                        beginDate,
                        endDate,
                        status
                    }).getKey();

                    firebase.database()
                    .ref(`UserProjects/${userId}/${userProjectKey}`)
                    .update({ 
                        id: userProjectKey 
                    })
                    .then(()=> { 
                        resolve({
                            id: userProjectKey,
                            userId,
                            projectId, 
                            beginDate, 
                            endDate,
                            userProjects,
                            userProjectAdded: true,
                            userProjectUpdated: false,
                            status,
                            userProjectAddedMessage: 'Successfully created capacity'
                        }); 
                    })
                    .catch((error) => {
                        if(PRINT_IN_CONSOLE) {
                            console.log('Error creating user capacity:', error.message);
                        }
                        resolve(null); 
                    });
                }
            });  
        });
    }

    updateUserCapacity(userProjectId, userId, projectId, beginDate, endDate, userProjects, oldUserId, status) {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserProjects/${userId}/${userProjectId}`)
            .update({ 
                userId,
                id: userProjectId,
                projectId,
                beginDate,
                endDate,
                status
            })
            .then(()=> { 
                if((typeof oldUserId) !== 'undefined' && oldUserId && userId.toString() !== oldUserId.toString()) {
                    firebase
                    .database()
                    .ref(`UserProjects/${oldUserId}/${userProjectId}`)
                    .remove()
                    .then(()=> {
                        resolve({
                            id: userProjectId,
                            userId,
                            projectId, 
                            beginDate, 
                            endDate,
                            userProjects,
                            userProjectAdded: false,
                            userProjectUpdated: true,
                            status,
                            userProjectUpdatedMessage: 'Successfully updated capacity'
                        }); 
                    })
                    .catch(error => {
                        if(PRINT_IN_CONSOLE) {
                            console.log('Error updating capacity', error.message);
                        }
                        resolve(null); 
                    });
                } else {
                    resolve({
                        id: userProjectId,
                        userId,
                        projectId, 
                        beginDate, 
                        endDate,
                        userProjects,
                        userProjectAdded: false,
                        userProjectUpdated: true,
                        status,
                        userProjectUpdatedMessage: 'Successfully updated capacity'
                    }); 
                }
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error updating capacity', error.message);
                }
                resolve(null); 
            })    
        });
    }

    deleteUserCapacity(userProjectId, userId, userProjects) {
        return new Promise((resolve, reject) => {
            firebase
            .database()
            .ref(`UserProjects/${userId}/${userProjectId}`)
            .remove()
            .then(()=> { 
                resolve({
                    userProjectId,
                    userProjects
                });
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error removing capacity:', error.message);
                }
                //resolve(null);
                resolve({ 'message': 'Error removing capacity'});
            });
        });
    }

    getUserRolesButtonNavigation() {
        return new Promise((resolve, reject) => {
            firebase
            .database()
            .ref(`UserRoleButtonNavigation`)
            .once("value")
            .then((data)=> { 
                const userRolesButtonNavigation = data.val();
                resolve({
                    userRolesButtonNavigation
                });
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error getting the user roles button navigation:', error.message);
                }
                resolve(null); 
            });
        });
    }

    getNavigationItems(currentUserId) {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`Users/${currentUserId}`)
            .once("value")
            .then((userResponse) => {
                const user = userResponse.val();
                const userRoleId = user.userRoleId;
                firebase.database()
                .ref(`UserRoleNavigation/${userRoleId}`)
                .once("value")
                .then((navItems) => {
                    let navigation = [];
                    Object.values(navItems.val()).forEach(item=> {
                        firebase.database()
                        .ref(`Navigation/${item}`)
                        .once("value")
                        .then(navItem => {
                            navigation.push(navItem.val())
                            if(Object.values(navItems.val()).length === navigation.length) {
                                resolve({
                                    navigation
                                });
                            }
                        })
                    })
                })
                .catch((error) => {
                    if(PRINT_IN_CONSOLE) {
                        console.log('Error getting the navigation items ', error.message);
                    }
                    resolve(null); 
                });
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error getting the navigation items ', error.message);
                }
                resolve(null); 
            });
        }); 
    }

    getButtonNavigationItems(currentUserId) {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`Users/${currentUserId}`)
            .once("value")
            .then((user) => {
                firebase.database()
                .ref(`UserRoleButtonNavigation/${user.val().userRoleId}`)
                .once("value")
                .then((buttonNavItems) => {
                    let navigation = [];
                    let btnNavItems = buttonNavItems.val();
                    if((typeof btnNavItems) !== 'undefined' && btnNavItems) {
                        Object.values(btnNavItems).forEach(item=> {
                            firebase.database()
                            .ref(`ButtonsNavigation/${item}`)
                            .once("value")
                            .then(navItem => {
                                const navigationItem = navItem.val();
                                if(navigationItem)
                                    navigation.push(navigationItem)
                                if(Object.values(btnNavItems).length === navigation.length) {
                                    resolve({
                                        navigation: navigation.sort((a,b) => (String(a.order).toLowerCase() > String(b.order).toLowerCase()) ? 1 : ((String(b.order).toLowerCase() > String(a.order).toLowerCase()) ? -1 : 0))
                                    });
                                }
                            })
                            .catch(error => {
                                if(PRINT_IN_CONSOLE) {
                                    console.log(error);
                                }
                                //revisar pero creo que deberia hacer resolve
                                resolve(null);
                            });
                        })
                    } else {
                        resolve({
                            navigation: navigation
                        });
                    }
                })
                .catch((error) => {
                    if(PRINT_IN_CONSOLE) {
                        console.log('Error getting the button navigation items ', error.message);
                    }
                    resolve(null); 
                });
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error getting the navigation items ', error.message);
                }
                resolve(null); 
            });
        }); 
    }

    createButtonNavigation(button, order, userRoles, buttonNavigationItems) {
        return new Promise((resolve, reject) => {
            const { title, description, icon, link } = button;
            const iconName = icon.name;
            const storageRef = firebase.storage().ref();
            let iconUrl = "";
            const external = true;
            const buttonNavigationKey = firebase
                    .database()
                    .ref(`ButtonsNavigation`)
                    .push({
                        title, 
                        description, 
                        external, 
                        iconName,
                        iconUrl, 
                        link, 
                        order
                    }).getKey();
                    firebase.database()
                    .ref(`ButtonsNavigation/${buttonNavigationKey}`)
                    .update({ 
                        id: buttonNavigationKey, 
                    })
                    .then(()=> { 
                        storageRef.child(buttonNavigationKey+'/'+icon.name)
                        .put(icon)
                        .then(res => {
                            firebase.storage().ref().child(buttonNavigationKey+'/'+icon.name).getDownloadURL()
                            .then((url) => {
                                iconUrl = url;
                                    firebase.database()
                                    .ref(`ButtonsNavigation/${buttonNavigationKey}`)
                                    .update({
                                        iconUrl
                                    })
                                    .then(()=> {
                                        let counter = 0;
                                        userRoles.forEach(userRole => {
                                            counter++;
                                            if(userRole.checked) {
    
                                                firebase.database()
                                                .ref(`UserRoleButtonNavigation/${userRole.id}`)
                                                .update({
                                                    [buttonNavigationKey]: buttonNavigationKey
                                                })
                                                .then(() => {
                                                    if(counter === userRoles.length) {
                                                        resolve({
                                                            id: buttonNavigationKey,
                                                            title, 
                                                            description, 
                                                            external, 
                                                            iconName,
                                                            iconUrl,
                                                            link, 
                                                            order,
                                                            buttonNavigationItems,
                                                            buttonAdded: true,
                                                            buttonUpdated: false,
                                                            buttonAddedMessage: 'Successfully created button'
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    if(PRINT_IN_CONSOLE) {
                                                        console.log('Error creating navigation button (user role can not be updated):', error.message);
                                                    }
                                                    resolve(null); 
                                                })
                                            }
                                        });
                                    })
                                    .catch((error) => {
                                        if(PRINT_IN_CONSOLE) {
                                            console.log('Error creating navigation button (updating image and icon url):', error.message);
                                        }
                                        resolve(null); 
                                    })
                                
                            }).catch((error) => {
                                if(PRINT_IN_CONSOLE) {
                                    console.log('error getting icon url ' + error.message, error);
                                }
                                resolve(null);
                            });
                        })
                        .catch(error => {
                            if(PRINT_IN_CONSOLE) {
                                console.log(error);
                            }
                            resolve(null);
                        });
                    })
                    .catch((error) => {
                        if(PRINT_IN_CONSOLE) {
                            console.log('Error creating navigation button:', error.message);
                        }
                        resolve(null); 
                    })  
        })
    }

    putImage = (id, image) => {
        return new Promise((resolve, reject) => {
            firebase.storage().ref().child(id+'/'+image.name)
            .put(image)
            .then(res => {
                resolve(true);
            })
            .catch(error => {
                if(PRINT_IN_CONSOLE)
                    console.log(error);
                resolve(false);
            });
        });
    }

    deleteImage = (url) => {
        return new Promise((resolve, reject) => {
            firebase.storage().ref().child(url)
            .delete()
            .then(res => {
                resolve(true);
            })
            .catch(error => {
                if(PRINT_IN_CONSOLE)
                    console.log(error);
                resolve(false);
            });
        });
    }

    getImageUrl = (url) => {
        return new Promise((resolve, reject) => {
            firebase.storage().ref().child(url)
            .getDownloadURL()
            .then(downloadUrl => {
                resolve(downloadUrl);
            })
            .catch(error => {
                if(PRINT_IN_CONSOLE)
                    console.log(error);
                resolve("");
            });
        });
    }

    uploadImageOnStorage = async (id, image, originalName) => {
        let putImageResult = false, downloadUrl = "";
        if((typeof(originalName) !== 'undefined' && originalName !== "")) {
            await this.deleteImage(id+'/'+originalName);
            putImageResult = await this.putImage(id, image);
            if(putImageResult) {
                downloadUrl = await this.getImageUrl(id+'/'+image.name);
                return downloadUrl;
            }
        } else {
            putImageResult = await this.putImage(id, image);
            if(putImageResult) {
                downloadUrl = await this.getImageUrl(id+'/'+image.name);
                return downloadUrl;
            }
        }
    }

    updateButtonNavigationImages = async (button, uploadIcon) => {
        const { id, icon, image, originalIconName } = button;
        let iconUrl = "";
        let uploadObject = {}
        if(uploadIcon) {
            iconUrl = await this.uploadImageOnStorage(id, icon, originalIconName);
            uploadObject['iconName'] = button['iconName'] = icon.name;
            uploadObject['iconUrl'] = button['iconUrl'] = iconUrl;
        }
        let result = await firebase.database()
        .ref(`ButtonsNavigation/${id}`)
        .update(uploadObject)
        .then(()=> {
            button["buttonUpdated"] = true;
            button["buttonUpdatedMessage"] = 'Successfully updated button';
            return button;
        })
        .catch((error) => {
            if(PRINT_IN_CONSOLE) {
                console.log('Error updating navigation button (updating image and icon url):', error.message);
            }
            button["buttonUpdated"] = false;
            button["buttonUpdatedMessage"] = 'Error updating navigation button (updating image and icon url)';
            return button;
        });
        return result;
    }

    deleteUserRoleButtonNavigationItem = async (roleId, buttonNavigationId) => {
        return await firebase.database()
        .ref(`UserRoleButtonNavigation/${roleId}/${buttonNavigationId}`)
        .remove();
    }

    addUserRoleButtonNavigationItem = async (roleId, buttonNavigationId) => {
        return await firebase.database()
        .ref(`UserRoleButtonNavigation/${roleId}`)
        .update({
            [buttonNavigationId]: buttonNavigationId
        });
    }

    updateButtonNavigation = (button) => {
        return new Promise((resolve, reject) => {
            const { id, title, description, link } = button;
            const external = true;
            firebase.database()
            .ref(`ButtonsNavigation/${id}`)
            .update({ 
                title, 
                description, 
                external, 
                link
            })
            .then(()=> {
                button["buttonAdded"] = false;
                button["buttonUpdated"] = true;
                button["buttonUpdatedMessage"] = 'Successfully updated button';
                resolve(button);
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error updating navigation button:', error.message);
                }
                button["buttonUpdated"] = false;
                button["buttonUpdatedMessage"] = "Error updating navigation button";
                resolve(button);
            })  
        });
    }


    getButtonNavigation(id) {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`ButtonsNavigation/${id}`)
            .once("value")
            .then((btn) => {
                resolve(btn);
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('error getting button navigation with id: ' + id, error.message);
                }
                resolve(null);
            });    
        });
    }

    getUserRoleButtonNavigation() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserRoleButtonNavigation`)
            .once("value")
            .then((buttonNavItems) => {
                resolve(buttonNavItems);
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('error getting user role button navigation items: ', error.message);
                }
                resolve(null);
            });
        });
    }

    handleDeleteButtonNavigation = (userRoleButtonNavigationItems, key, id, userRoleButtonNavigationItemsToDelete, roleIdentifier) => {
        Object.keys(userRoleButtonNavigationItems[key]).forEach(keyItem => {
            if(keyItem === id) {
                userRoleButtonNavigationItemsToDelete.push(roleIdentifier+'/'+id);
            }
        });
    }

    deleteButtonNavigation(id) {
        const storageRef = firebase.storage().ref();
        let roleIdentifier = '';
        let counter = 0;
        let userRoleButtonNavigationItemsToDelete = [];
        return new Promise((resolve, reject) => {
            this.getButtonNavigation(id).then(btn => {
                if(!btn) {
                    resolve({ 'message': 'Error getting button navigation'});
                } else {
                    btn = btn.val();
                    // storageRef.child(id+'/'+btn.imageName)
                    // .delete()
                    // .then(res => {
                        storageRef.child(id+'/'+btn.iconName)
                        .delete()
                        .then(res => {
                            this.getUserRoleButtonNavigation().then(userRoleButtonNavigationItems => {
                                if(!userRoleButtonNavigationItems) {
                                    resolve({ 'message': 'Error getting user role button navigation items to remove button navigation'});
                                } else {
                                    userRoleButtonNavigationItems = userRoleButtonNavigationItems.val();
                                    for (let key in userRoleButtonNavigationItems) {
                                        roleIdentifier = key;
                                        this.handleDeleteButtonNavigation(userRoleButtonNavigationItems, key, id, userRoleButtonNavigationItemsToDelete, roleIdentifier)
                                    }
                                    userRoleButtonNavigationItemsToDelete.forEach(userRoleButtonNavigationItemToDelete => {
                                        firebase.database()
                                        .ref(`UserRoleButtonNavigation/${userRoleButtonNavigationItemToDelete}`)
                                        .remove()
                                        .then(response => {
                                            counter++;
                                            if(counter === userRoleButtonNavigationItemsToDelete.length) {
                                                firebase
                                                .database()
                                                .ref(`ButtonsNavigation/${id}`)
                                                .remove()
                                                .then(()=> { 
                                                    let response = { "id": id };
                                                    resolve(response);
                                                })
                                                .catch((error) => {
                                                    if(PRINT_IN_CONSOLE) {
                                                        console.log('Error removing button navigation:', error.message);
                                                    }
                                                    resolve({ 'message': 'Error removing button navigation'});
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            if(PRINT_IN_CONSOLE) {
                                                console.log('Error removing user role button navigation item: ' + userRoleButtonNavigationItemToDelete, error.message);
                                            }
                                            resolve({ 'message': 'Error removing user role button navigation item'});
                                        });
                                    });
                                }
                            });
                        })
                        .catch((error) => {
                            if(PRINT_IN_CONSOLE) {
                                console.log('Error removing button navigation icon');
                            }
                            resolve({ 'message': 'Error removing button navigation icon'});
                        });
                    // })
                    // .catch((error) => {
                    //     if(PRINT_IN_CONSOLE) {
                    //         console.log('Error removing button navigation image');
                    //     }
                    //     resolve({ 'message': 'Error removing button navigation image'});
                    // });
                }
            })             
        });
    }
}
