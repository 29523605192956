import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './../../../App.css';
import RequiredInputLabel from '../../shared/required-input-label';
import { isEmailValid, isTextValid } from '../../../utilities/string.utilities';
import { Services } from '../../../api/services';
import _ from 'lodash';

const PersonalFormDataStep2 = ({
  updateLocalUser,
  updateUser,
  user,
  title,
  next,
  nextText="Next Step",
  profile,
  handleCloseNotification
}) => {
  const labelClass = 'mb-2 rb-textbox-style';
  const inputClass = 'form-control mb-2 rb-textbox-style';

  const [state, setState] = useState({
    location: '',
    locationIsSet: false,
    locations: [],
  });

  const [error, setError] = useState({});

  const getLocations = (user) => {
    Services.Locations.getLocations()
      .then((data) => {
        if (data.val() !== null) {
          const response = data.val();
          let locations = [];
          let location = '';
          let locationId = user.locationId;
          for (var key in response) {
            if (
              locationId &&
              response[key].id.toString() === locationId.toString()
            )
              location = response[key].name;
            locations.push(response[key]);
          }
          locations = locations.sort((a, b) =>
            String(a.name).toLowerCase() > String(b.name).toLowerCase()
              ? 1
              : String(b.name).toLowerCase() > String(a.name).toLowerCase()
              ? -1
              : 0
          );
          if (location === '') {
            location = locations[0].name;
            locationId = locations[0].id;
          }

          setState({
            ...state,
            locations,
            location,
            locationIsSet: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getLocations(user);
  }, []);

  React.useEffect(() => {
    getLocations(user);
  }, [user.id]);

  const onSubmitPersonalData2 = async () => {
    handleCloseNotification();
    setError({});
    try {
      isPersonalData2Valid(user);
      updateUser(user, 2);
      !profile && next();
    } catch (err) {
      console.table(err);
      console.log(err);
      await setError(err);
      throw err;
    }
  };

  const isPersonalData2Valid = (data) => {
    let error = {};

    if (isTextValid(data.cellularNumber) === false) {
      error = {...error,
        ['cellularNumber']: 'Cellular number  is required',
      };
    }

    if (isTextValid(data.homeAddress) === false) {
      error = {...error,
        ['homeAddress']: 'Home address is required',
      };
    }
   
    if (isTextValid(data.personalEmail) === false) {
      error = {...error,
        ['personalEmail']: 'Personal email is required',
      };
    }else{
      if (isEmailValid(data.personalEmail) === false) {
        error = {...error,
          ['personalEmail']: 'Personal email is invalid',
        };
      }
    }

    
    if (!_.isEmpty(error)) throw error;
  };

  return (
    <React.Fragment>
      <h2 className="onboarding-title">{title}</h2>
      <div className="container" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className="col-12 col-lg-6">
          <div className="form-group rb-label-input">
            <label className={labelClass}>
              Location:
              <span className="ml-1" style={{ color: 'red' }}>
                *
              </span>
            </label>
            <div className="dropdown" style={{ textAlign: 'center' }}>
              <button
                className="btn btn-sm btn-info dropdown-toggle mb-2"
                style={{ width: '100%' }}
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {state.locationIsSet &&
                user.locationId !== '' &&
                typeof state.locations.find(
                  (loc) => loc.id === user.locationId
                ) !== 'undefined'
                  ? state.locations.find((loc) => loc.id === user.locationId)
                      .name
                  : 'Select location'}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                {state.locations &&
                  state.locations.map((location) => {
                    return (
                      <button
                        key={location.id}
                        onClick={async (event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          await setState({
                            ...state,
                            location: location.name,
                            locationIsSet: true,
                          });
                          updateLocalUser({
                            locationId: location.id,
                          });
                        }}
                        className="dropdown-item"
                        type="button"
                      >
                        {location.name}
                      </button>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <RequiredInputLabel
            labelName="Home address:"
            labelClass={labelClass}
            inputClass={inputClass}
            inputName="homeAddress"
            value={user.homeAddress}
            errorMessage={error.homeAddress}
            onChangeFunction={(event) => {
              updateLocalUser({
                homeAddress: event.target.value,
              });
            }}
          />
        </div>
        <div className="col-12 col-lg-6">
          <RequiredInputLabel
            labelName="Cellular number:"
            labelClass={labelClass}
            inputClass={inputClass}
            inputName="cellularNumber"
            inputType="number"
            value={user.cellularNumber}
            errorMessage={error.cellularNumber}
            onChangeFunction={(event) => {
              updateLocalUser({
                cellularNumber: event.target.value,
              });
            }}
          />
        </div>
        <div className="col-12 col-lg-6">
          <RequiredInputLabel
            labelName="Personal email:"
            labelClass={labelClass}
            inputClass={inputClass}
            inputName="personalEmail"
            value={user.personalEmail}
            errorMessage={error.personalEmail}
            onChangeFunction={(event) => {
              updateLocalUser({
                personalEmail: event.target.value,
              });
            }}
          />
        </div>
        <div className="col-12 text-center">
          <button type="button" onClick={onSubmitPersonalData2}  className="next-button">
            {nextText}{!profile && <i class="fas fa-arrow-right next-button-icon"></i>}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PersonalFormDataStep2;
