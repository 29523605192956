import { UsersApi } from "./users/users.api";
import { InventoryApi } from "./inventory/inventory.api";
import { GeneralApi } from "./general/general.api";
import { ClientsApi } from "./clients/clients.api";
import { ProjectsApi } from "./projects/projects.api";
import { LocationsApi } from "./locations/locations.api";
import { RolesApi } from './roles/roles.api';
import { UserTypesApi } from './users/user-types.api';

const usersApi = new UsersApi();
const inventoryApi = new InventoryApi();
const generalApi = new GeneralApi();
const clientsApi = new ClientsApi();
const projectsApi = new ProjectsApi();
const locationsApi = new LocationsApi();
const rolesApi = new RolesApi();
const userTypesApi = new UserTypesApi();

export const Services = {
  Users: usersApi,
  InventoryItems:  inventoryApi,
  General: generalApi,
  Clients: clientsApi,
  Projects: projectsApi,
  Locations: locationsApi,
  Roles: rolesApi,
  UserTypes: userTypesApi
};