import React, { useState, useEffect } from 'react';
import RequiredInputLabel from '../../shared/required-input-label';
import TextArea from '../../shared/text-area';
import Cookies from 'universal-cookie';
import 'react-datepicker/dist/react-datepicker.css';
import './../../../App.css';
import { isTextValid } from '../../../utilities/string.utilities';
import { getPropertyValue } from './userUtilities';
import { Services } from '../../../api/services';
import _ from 'lodash';

const BankFormData = ({
  user,
  title,
  notify,
  next,
  notRequired,
  nextText = 'Next Step',
  profile,
  handleCloseNotification
}) => {
  const labelClass = 'mb-2 rb-textbox-style';
  const inputClass = 'form-control mb-2 rb-textbox-style';
  const [error, setError] = useState({});
  const [state, setState] = useState({
    action: '',
    comments: '',
    bankData: {
      accountNumber: undefined,
      accountType: 'Saving account (Caja de ahorro)',
      bankBranch: '',
      bankName: '',
      comments: '',
      currency: 'Uruguayan peso ($)',
      id: 0,
      userId: user.id,
    },

    alertClass: '',
    processing: false,
  });

  const getBankData = async (userId) => {
    const response = await Services.Users.getUserBankData(userId)
      .then(async (data) => {
        if (typeof data !== 'undefined' && data !== null)
          data = await data.val();
        return data;
      })
      .catch((_) => {
        setState({
          ...state,
          bankData: {
            ...state.bankData,
            accountNumber: undefined,
            accountType: 'Saving account (Caja de ahorro)',
            bankBranch: undefined,
            bankName: '',
            comments: '',
            currency: 'Uruguayan peso ($)',
            id: 0,
            userId: user.id,
          },
        });
      });
    if (typeof response !== 'undefined' && response !== null) {
      response.forEach((bankData) => {
        if (
          typeof bankData !== 'undefined' &&
          bankData !== null &&
          bankData.id !== null
        ) {
          setState({
            ...state,
            bankData: {
              ...state.bankData,
              accountNumber: bankData.accountNumber,
              accountType:
                bankData.accountType || 'Saving account (Caja de ahorro)',
              bankBranch: bankData.bankBranch,
              bankName: bankData.bankName,
              comments: bankData.comments,
              currency: bankData.currency || 'Uruguayan peso ($)',
              id: bankData.id,
              userId: bankData.userId,
            },
          });
          return;
        }
      });
    }
  };

  React.useEffect(() => {
    getBankData(user.id);
  }, []);

  React.useEffect(() => {
    getBankData(user.id);
  }, [user.id]);

  const onSubmitBankData = async (action) => {
    handleCloseNotification();
    setError({});
    try {
      let bankData = state.bankData;
      if (typeof bankData === 'undefined' || bankData === null) return;

      bankData.currency =
        typeof state.bankData.currency !== 'undefined' &&
        state.bankData.currency !== null &&
        state.bankData.currency !== ''
          ? state.bankData.currency
          : 'Uruguayan peso ($)';
      bankData.accountType =
        typeof state.bankData.accountType !== 'undefined' &&
        state.bankData.accountType !== null &&
        state.bankData.accountType !== ''
          ? state.bankData.accountType
          : 'Saving account (Caja de ahorro)';
      bankData.comments =
        typeof state.bankData.comments !== 'undefined' &&
        state.bankData.comments !== null
          ? state.bankData.comments
          : '';
      console.log('submit info', bankData);

      if(profile)
        await isBankDataValid(bankData);

      await setState({ ...state, processing: true });
      const userId =
        getPropertyValue(user.id) !== ''
          ? user.id
          : new Cookies().get('1950SystemUserId');
      const id = !state.bankData
        ? 0
        : getPropertyValue(state.bankData.id) !== ''
        ? state.bankData.id
        : 0;
      bankData.userId = userId;
      if (id !== 0) bankData.id = id;

      if (userId !== '') {
        const userBankData = await Services.Users.getUserBankData(userId)
          .then(async (data) => {
            data = await data.val();
            return data;
          })
          .catch((_) => {
            return undefined;
          });
        console.log(userBankData);
        let existsBankData = false;
        let result;
        if (typeof userBankData !== 'undefined' && userBankData !== null) {
          userBankData.forEach((bankInfo) => {
            if (bankInfo.id === bankData.id) {
              existsBankData = true;
            }
          });
          if (existsBankData) {
            await Services.Users.updateBankData(bankData)
              .then((updateResponse) => {
                result = {
                  success: true,
                  message: 'The information was successfully updated',
                  step: 2,
                  bankData,
                };
                showNot(result, action);
              })
              .catch((error) => {
                console.log('error updating bankData', error);
                result = {
                  success: false,
                  message:
                    'An error ocurred when trying to update the information. Please try again later',
                  step: 2,
                };
                showNot(result, action);
              });
          } else {            
            bankData.id = 1; //bankInfo.lastId + 1; TODO: REVIEW THIS
            await Services.Users.postBankData(bankData)
              .then(() => {
                result = {
                  success: true,
                  message: 'The information was successfully saved',
                  step: 2,
                  bankData,
                };
                showNot(result, action);
              })
              .catch((error) => {
                console.log('error saving bankData', error);
                result = {
                  success: false,
                  message:
                    'An error ocurred when trying to save the information. Please try again later',
                  step: 2,
                };
                showNot(result, action);
              });                        
          }
        } else {
          if(isTextValid(bankData.accountNumber)){
            bankData.id = 1; //bankInfo.lastId + 1; TODO: REVIEW THIS
            await Services.Users.postBankData(bankData)
              .then(() => {
                result = {
                  success: true,
                  message: 'The information was successfully saved',
                  step: 2,
                  bankData,
                };
                showNot(result, action);
              })
              .catch((error) => {
                console.log('error saving bankData', error);
                result = {
                  success: false,
                  message:
                    'An error ocurred when trying to save the information. Please try again later',
                  step: 2,
                };
                showNot(result, action);
              });
          }
        }
      } else {
        console.log('no se encontró el id del usuario');
      }
      !profile && next();
    } catch (err) {
      await setError(err);
      throw err;
    }
  };

  const currencyChange = async (data) => {
    await setState({
      ...state,
      bankData: {
        ...state.bankData,
        currency: data,
      },
    });
  };

  const accountTypeChange = async (data) => {
    await setState({
      ...state,
      bankData: {
        ...state.bankData,
        accountType: data,
      },
    });
  };

  const showNot = async (result, action) => {
    console.log(result);
    if (typeof result !== 'undefined' && result !== null) {
      if (result.success === true) {
        notify(result.message, 'success');
      } else {
        notify(result.message, 'danger');
      }
    }
  };

  const isBankDataValid = async (data) => {
    let error = {};
    if (isTextValid(data.bankName) === false) {
      error = { ...error, ['bankName']: 'Bank name is required' };
    }
    if (isTextValid(data.accountType) === false) {
      error = { ...error, ['accountType']: 'Account type is required' };
    }
    if (isTextValid(data.currency) === false) {
      error = { ...error, ['currency']: 'Currency is required' };
    }
    if (isTextValid(data.accountNumber) === false) {
      error = { ...error, ['accountNumber']: 'Account number is required' };
    }

    if (!_.isEmpty(error)) throw error;
  };

  return (
    <React.Fragment>
      <h2 className="onboarding-title">{title}</h2>
      <div className="container" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className="col-12 col-lg-6">
          <RequiredInputLabel
            labelName="Bank Name:"
            labelClass={labelClass}
            inputClass={inputClass}
            inputName="bankName"
            value={state.bankData.bankName}
            errorMessage={error.bankName}
            onChangeFunction={(event) => {
              setState({
                ...state,
                bankData: {
                  ...state.bankData,
                  bankName: event.target.value,
                },
              });
            }}
            notRequired={notRequired}
          />
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group rb-label-input">
            <label className={labelClass}>
              Account type:{' '}
              {notRequired === true ? (
                ''
              ) : (
                <span className="ml-1" style={{ color: 'red' }}>
                  *
                </span>
              )}{' '}
            </label>
            <div
              className="dropdown"
              style={{ textAlign: 'center', overFlow: 'hidden' }}
            >
              <button
                className="btn btn-sm btn-info dropdown-toggle mb-2"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {state.bankData &&
                typeof state.bankData.accountType !== 'undefined' &&
                state.bankData.accountType !== null &&
                state.bankData.accountType !== ''
                  ? state.bankData.accountType
                  : 'Saving account (Caja de ahorro)'}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                <button
                  key="2"
                  onClick={async (event) => {
                    await accountTypeChange('Saving account (Caja de ahorro)');
                  }}
                  className="dropdown-item"
                  type="button"
                  value="Saving account (Caja de ahorro)"
                >
                  Saving account (Caja de ahorro)
                </button>
                <button
                  key="1"
                  onClick={async (event) => {
                    await accountTypeChange(
                      'Current account (Cuenta corriente)'
                    );
                  }}
                  className="dropdown-item"
                  type="button"
                  value="Current account (Cuenta corriente)"
                >
                  Current account (Cuenta corriente)
                </button><button
                  key="3"
                  onClick={async (event) => {
                    await accountTypeChange(
                      'Other'
                    );
                  }}
                  className="dropdown-item"
                  type="button"
                  value="Other"
                >
                  Other
                </button>
              </div>
            </div>
            <div className="text-danger">{error.accountType}</div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group rb-label-input">
            <label className={labelClass}>
              Currency:{' '}
              {notRequired === true ? (
                ''
              ) : (
                <span className="ml-1" style={{ color: 'red' }}>
                  *
                </span>
              )}
            </label>
            <div className="dropdown" style={{ textAlign: 'center' }}>
              <button
                className="btn btn-sm btn-info dropdown-toggle mb-2"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {state.bankData &&
                typeof state.bankData.currency !== 'undefined' &&
                state.bankData.currency !== null &&
                state.bankData.currency !== ''
                  ? state.bankData.currency
                  : 'Uruguayan peso ($)'}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                <button
                  key="2"
                  onClick={async (event) => {
                    await currencyChange('Uruguayan peso ($)');
                  }}
                  className="dropdown-item"
                  type="button"
                  value="Uruguayan peso ($)"
                >
                  Uruguayan peso ($)
                </button>
                <button
                  key="1"
                  onClick={async (event) => {
                    await currencyChange('US dollars (U$S)');
                  }}
                  className="dropdown-item"
                  type="button"
                  value="US dollars (U$S)"
                >
                  US dollars (U$S)
                </button>
              </div>
            </div>
            <div className="text-danger">{error.currency}</div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <RequiredInputLabel
            labelName="Account number:"
            labelClass={labelClass}
            inputClass={inputClass}
            inputName="accountNumber"
            inputType="number"
            value={state.bankData.accountNumber}
            errorMessage={error.accountNumber}
            notRequired={notRequired}
            onChangeFunction={(event) => {
              setState({
                ...state,
                bankData: {
                  ...state.bankData,
                  accountNumber: event.target.value,
                },
              });
            }}
          />
        </div>
        <div className="col-12 col-lg-6">
          <RequiredInputLabel
            labelName="Bank branch (Número de sucursal):"
            labelClass={labelClass}
            inputClass={inputClass}
            //refProp={register}
            inputName="bankBranch"
            inputType="number"
            value={state.bankData.bankBranch}
            errorMessage={error.bankBranch}
            notRequired={true}
            onChangeFunction={(event) => {
              setState({
                ...state,
                bankData: {
                  ...state.bankData,
                  bankBranch: event.target.value,
                },
              });
            }}
          />
        </div>
        <div className="col-12 col-lg-6">
          <TextArea
            labelName="Comments:"
            labelClass={labelClass}
            inputClass={inputClass}
            inputName="comments"
            value={state.bankData.comments}
            errorMessage={error.comments}
            onChangeFunction={(event) =>
              setState({
                ...state,
                bankData: {
                  ...state.bankData,
                  comments: event.target.value,
                },
              })
            }
            maxLength="300"
          />
        </div>
        <div className="col-12 text-center">
          <button
            type="button"
            onClick={onSubmitBankData}
            className="next-button"
          >
            {nextText}
            {!profile && <i class="fas fa-arrow-right next-button-icon"></i>}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BankFormData;
