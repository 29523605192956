import React, { Component } from 'react';
import Textbox from '../shared/textbox';
import './login-form.css';
import logo from '../../assets/images/logo.png';
import {resetPassword} from '../../actions/user-actions'
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner'

class PasswordReset extends Component {
    state = { 
        actionCode:"",
        newPassword: "",
        newPasswordConfirm: "",
        newPasswordError: "",
        newPasswordConfirmError: "",
        passwordResetProcessResult: false,
        processing: false,
        passwordResetResult: ""
    }

    componentDidUpdate(prevProps) {
        
        if(prevProps.passwordResetSuccess !== this.props.passwordResetSuccess && (typeof this.props.passwordResetSuccess) !== 'undefined' && this.props.passwordResetSuccess !== null) {
            let processResult = '';
            let passwordResetProcessResult = false;
            if(this.props.passwordResetSuccess)
            {
                passwordResetProcessResult = true;
                processResult = "The password has been updated. You can login with the new password"
            } else {
                processResult = "Error while updating the password. Please try again later, or try the forgot password link"
            }
             this.setState({
                passwordResetResult: processResult,
                passwordResetProcessResult,
                processing: false
            })
        }
    }

    handleTextboxChange = (e) => {
        this.setState({[e.target.name]: e.currentTarget.value});
    }

    resetPassword= (e) => {
        this.setState({
            newPasswordConfirmError: "",
            newPasswordError: "",
            processing: true
        });
        const formValid = this.validateForm();

        if(formValid)
        {
            this.props.resetPassword(this.props.authCode, this.state.newPassword);
        }
    }

    validateForm = () => {
        const {newPassword, newPasswordConfirm } = this.state
        if(newPassword.length >= 6) {            
            if((newPasswordConfirm.length >= 6)) {
                if(newPassword !== newPasswordConfirm)  {
                    this.setState({
                        ...this.state,
                        newPasswordError: "",
                        newPasswordConfirmError: "The password don't match"
                    })
                    return false
                } else {
                    return true;
                }
            } else {
                this.setState({
                    ...this.state,
                    newPasswordError: "",
                    newPasswordConfirmError: "The new password is required and should have at least 6 characters"
                })
                return false
            } 
        } else {
            this.setState({
                ...this.state,
                newPasswordError: "The new password is required and should have at least 6 characters"
            })
            return false
        }
    }

    render() { 
        return ( 
            <React.Fragment >
                <header className="App-header">
                    <a href='/'>
                        <img src={logo} className="App-logo" alt="logo" />
                    </a>
                </header>
                {!this.state.passwordResetResult ? 
                <form className="login-form-main" >
                    <Textbox 
                        name = "newPassword"
                        errorText={this.state.newPasswordError}
                        inputLabel="New Password" 
                        inputType="password" 
                        inputPlaceholder="new password" 
                        inputClass="form-control"
                        handleTextboxChange={this.handleTextboxChange.bind(this)}
                        errorMessage={this.state.newPasswordError}/>    
                        
                    <Textbox 
                        name = "newPasswordConfirm"
                        errorText={this.state.emailError}
                        inputLabel="Re-type new password" 
                        inputType="password" 
                        inputPlaceholder="new password" 
                        inputClass="form-control"
                        handleTextboxChange={this.handleTextboxChange.bind(this)}
                        errorMessage={this.state.newPasswordConfirmError}/>    
                        { this.state.processing ? 
                            <div style={{textAlign: 'center'}}>
                                <Spinner style={{color: 'white'}} animation="border" />
                            </div> 
                            :
                            <button type="button" 
                                    onClick={ this.resetPassword } 
                                    disabled={this.state.newPassword.length <= 0 || this.state.newPasswordConfirm.length <= 0 }
                                    className="btn btn-primary">Reset password</button> 
                            
                        }
                </form>
                : 
                <div style={{color: 'white', textAlign: 'center', marginTop: '4rem'}}>
                    <label>{this.state.passwordResetResult}</label> 
                </div> }
            </React.Fragment>
         );
    }
}


const mapStateToProps = ({usersStore}) => {
    return {
      passwordResetSuccess: usersStore.passwordResetSuccess
    };
  };
  
  const mapDispatchToProps = dispatch => ({
    resetPassword: (newPassword, actionCode) => dispatch(resetPassword(newPassword, actionCode))
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(PasswordReset);
