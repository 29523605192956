import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Textbox from '../shared/textbox';
import { connect } from 'react-redux';
import { updateItem, setSelectedItem } from '../../actions/inventory-actions';
import { setLocations } from '../../actions/location-actions';
import Cookies from 'universal-cookie';
import { isTextValid } from '../../utilities/string.utilities';
import { ActionButton, LocalModal, LocalSelect } from '../common';
import defaultImage from '../../assets/images/default-user.png';
class EditItemPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        brand: '',
        codeId: '',
        detail: '',
        id: '',
        inventoryItemStatusTypeId: 2,
        locationId: 0,
        model: '',
        type: '',
        userId: '',
      },
      userId: 0,
      selectedUser: {},
      selectedUserId: null,
      users: [],
      locations: [],
      codeIdError: '',
      typeError: '',
      brandError: '',
      modelError: '',
      locationError: '',
      detailError: '',
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showCreateItemMessage: false,
      itemAddMessage: '',
      itemUpdatedMessage: '',
      processing: false,
      locationButtonText: 'Select',
      selectedLocationId: '',
      inventoryItemTypeSuggestions: [],
      suggestions: [],
      notification: '',
    };
    this.handleSaveEdit = this.handleSaveEdit.bind(this);
  }
  handleTextboxChange = (e) => {
    this.setState({
      ...this.state,
      item: {
        ...this.state.item,
        [e.target.name]: e.currentTarget.value,
      },
    });
  };

  componentDidMount = () => {
    const cookies = new Cookies();
    this.setState({
      ...this.state,
      userId: cookies.get('1950SystemUserId'),
    });
    this.props.setLocations(this.state.locations);
  };

  componentWillReceiveProps = (nextProps) => {
    const users = this.props.users;
    if (nextProps.selectedItem) {
      this.setState({
        ...this.state,
        selectedUser: users.find((u) => u.id == nextProps.selectedItem.userId),
        selectedUserId: nextProps.selectedItem.userId,
        item: {
          brand: nextProps.selectedItem.brand || '',
          codeId: nextProps.selectedItem.codeId || '',
          detail: nextProps.selectedItem.detail || '',
          id: nextProps.selectedItem.id || '',
          inventoryItemStatusTypeId:
            nextProps.selectedItem.inventoryItemStatusTypeId || '',
          model: nextProps.selectedItem.model || '',
          type: nextProps.selectedItem.type || '',
          userId: nextProps.selectedItem.userId || '',
        },
      })
    }else{
      // Initialize selected user
      this.setState({
        ...this.state,
        selectedUser: "",
        selectedUserId: "-1",
      })
    }
  };

  handleSaveEdit = async (event) => {
    //const isNewItem = (typeof this.props.selectedItem) === 'undefined' || this.props.selectedItem === null;
    
    const userAux = {
      firstName: 'NOT',
      lastName: 'ASSIGNED',
      userStatusTypeId: '1',
      id: '-1',
    };
    const users = this.props.users;

    const { selectedUser } = this.state;

    let item = this.state.item;

    item.lastUpdate = new Date().toString();
    const isInventoryItemValid = await this.validateInventoryItem();
    if (isInventoryItemValid) {
      this.setState({ processing: true });
      item.locationId = this.state.selectedLocationId;
      item.locationName = this.state.locationButtonText;
      selectedUser && (item.userId = selectedUser.id);

      await this.props.updateItem(
        item,
        selectedUser,
        this.props.inventoryItems
      );

      await this.setState({
        ...this.state,
        processing: false,
        notification: 'Successfully updated item!',
        alertClass: this.state.baseAlertClass + 'success',
      });
    }
  };

  validateInventoryItem = async () => {
    const { codeId, type, brand, model, detail, id } = this.state.item;
    const { inventoryItems, selectedItem } = this.props;
    const selectedItemId =
      typeof selectedItem !== 'undefined' && selectedItem
        ? selectedItem.id.toString()
        : typeof id !== 'undefined' && id && id.toString().length > 0
        ? id
        : null;
    let isInventoryItemValid = true;
    inventoryItems.forEach((inventoryItem) => {
      if (
        inventoryItem.codeId
          .toString()
          .toLowerCase()
          .trim() ===
        codeId
          .toString()
          .toLowerCase()
          .trim()
      ) {
        if (!selectedItemId) {
          isInventoryItemValid = false;
        } else {
          if (inventoryItem.id.toString() !== selectedItemId) {
            isInventoryItemValid = false;
          }
        }
      }
    });
    await this.setState({
      codeIdError: isTextValid(codeId)
        ? !isInventoryItemValid
          ? 'Already exists an inventory item with this code'
          : ''
        : 'Code is required',
      typeError: isTextValid(type) ? '' : 'Type is required',
      brandError: isTextValid(brand) ? '' : 'Brand is required',
      modelError: isTextValid(model) ? '' : 'Model is required',
      // locationError:
      //   this.state.selectedLocationId !== '' &&
      //   this.state.selectedLocationId.toString() !== '-1'
      //     ? ''
      //     : 'Location is required',
      detailError: isTextValid(detail) ? '' : 'Detail is required',
    });
    return (
      this.state.codeIdError === '' &&
      this.state.typeError === '' &&
      this.state.brandError === '' &&
      this.state.modelError === '' &&
      this.state.locationError === '' &&
      this.state.detailError === ''
    );
  };

  componentDidUpdate = async (prevProps) => {
    const { selectedUser, selectedUserId } = this.state;

    if (selectedUser && selectedUser.id != this.state.selectedUserId) {
      let suggestions = [];
      let inventoryItemTypeSuggestions = [];
      let suggestionKey = 0;
      if (this.state.inventoryItemTypeSuggestions.length === 0) {
        this.props.inventoryItems.forEach((inventoryItem) => {
          suggestions[
            inventoryItem.type.toString()
          ] = inventoryItem.type.toString();
        });
        Object.keys(suggestions).forEach((suggestion) => {
          inventoryItemTypeSuggestions.push({
            id: ++suggestionKey,
            label: suggestion,
          });
        });
      }
      if (this.props.users.length > 0) {
        let userLocationId = -1;
        let locationText = '';
        this.props.users.forEach((user) => {
          if (user.id.toString() === selectedUser.id.toString()) {
            userLocationId = user.locationId.toString();
          }
        });
        if (this.props.locations.length > 0) {
          if (userLocationId !== -1) {
            this.props.locations.forEach((location) => {
              if (location.id.toString() === userLocationId.toString()) {
                locationText = location.name;
              }
            });
          }
          userLocationId =
            locationText !== ''
              ? userLocationId
              : userLocationId === -1 &&
                typeof this.props.locations[0] !== 'undefined' &&
                this.props.locations[0]
              ? this.props.locations[0].id
              : -1;
          locationText =
            locationText !== ''
              ? locationText
              : locationText === '' &&
                typeof this.props.locations[0] !== 'undefined' &&
                this.props.locations[0]
              ? this.props.locations[0].name
              : 'Select';
          await this.setState({
            ...this.state,
            alertClass: '',
            selectedUserId: selectedUser.id,
            showCreateItemMessage: false,
            itemAddMessage: '',
            itemUpdatedMessage: '',
            processing: false,
            locationButtonText: locationText,
            selectedLocationId: userLocationId,
            inventoryItemTypeSuggestions: inventoryItemTypeSuggestions,
          });
        } else {
          if (userLocationId.toString() !== '-1') {
            await this.setState({
              ...this.state,
              alertClass: '',
              selectedUserId: selectedUser.id,
              showCreateItemMessage: false,
              itemAddMessage: '',
              itemUpdatedMessage: '',
              processing: false,
              selectedLocationId: userLocationId,
              inventoryItemTypeSuggestions: inventoryItemTypeSuggestions,
            });
          }
        }
      } else {
        await this.setState({
          ...this.state,
          alertClass: '',
          selectedUserId: selectedUser.id,
          showCreateItemMessage: false,
          itemAddMessage: '',
          itemUpdatedMessage: '',
          processing: false,
          inventoryItemTypeSuggestions: inventoryItemTypeSuggestions,
        });
      }
    }
    if (prevProps.users !== this.props.users) {
      if (this.props.users.length > 0) {
        let userLocationId = -1;
        let locationText = '';
        this.props.users.forEach((user) => {
          if (this) {
            userLocationId = user.locationId.toString();
          }
        });
        if (this.props.locations.length > 0) {
          this.props.locations.forEach((location) => {
            if (location.id.toString() === userLocationId.toString()) {
              locationText = location.name;
            }
          });
          await this.setState({
            ...this.state,
            users: this.props.users,
            alertClass: '',
            showCreateItemMessage: false,
            itemAddMessage: '',
            itemUpdatedMessage: '',
            processing: false,
            locationButtonText: locationText,
            selectedLocationId: userLocationId,
          });
        } else {
          if (userLocationId.toString() !== '-1') {
            await this.setState({
              ...this.state,
              users: this.props.users,
              alertClass: '',
              showCreateItemMessage: false,
              itemAddMessage: '',
              itemUpdatedMessage: '',
              processing: false,
              selectedLocationId: userLocationId,
            });
          }
        }
      }
    }
    if (
      prevProps.selectedItem !== this.props.selectedItem &&
      typeof this.props.selectedItem !== 'undefined' &&
      this.props.selectedItem &&
      typeof this.props.selectedItem.itemDeleted === 'undefined'
    ) {
      if (this.props.locations.length > 0) {
        let locationName = '';
        this.props.locations.forEach((location) => {
          if (
            this.props.selectedItem.locationId.toString() ===
            location.id.toString()
          ) {
            locationName = location.name;
          }
        });
        this.setState({
          ...this.state,
          item: {
            brand: this.props.selectedItem.itemAdded
              ? ''
              : this.state.item.brand,
            codeId: this.props.selectedItem.itemAdded
              ? ''
              : this.state.item.codeId,
            detail: this.props.selectedItem.itemAdded
              ? ''
              : this.state.item.detail,
            id: this.props.selectedItem.itemAdded ? '' : this.state.item.id,
            inventoryItemStatusTypeId: 2,
            model: this.props.selectedItem.itemAdded
              ? ''
              : this.state.item.model,
            type: this.props.selectedItem.itemAdded ? '' : this.state.item.type,
            userId: this.props.selectedItem.itemAdded
              ? ''
              : this.state.item.userId,
          },
          itemAddMessage: this.props.selectedItem.itemAdded
            ? 'Item has been added.'
            : this.props.selectedItem.itemAddedMessage,
          itemUpdatedMessage: this.props.selectedItem.itemUpdated
            ? 'Item has been updated.'
            : this.props.selectedItem.itemUpdatedMessage,
          alertClass:
            this.props.selectedItem.itemAdded ||
            this.props.selectedItem.itemUpdated
              ? this.state.baseAlertClass + 'success'
              : this.state.baseAlertClass + 'danger',
          showCreateItemMessage:
            this.props.selectedItem.itemAdded ||
            this.props.selectedItem.itemUpdated
              ? true
              : false,
          processing: false,
          locationButtonText: this.props.selectedItem.itemAdded
            ? 'Select'
            : locationName,
          selectedLocationId: this.props.selectedItem.itemAdded
            ? ''
            : this.props.selectedItem.locationId,
          //selectedUser: undefined
        });
      } else {
        this.setState({
          ...this.state,
          item: {
            brand: this.props.selectedItem.itemAdded
              ? ''
              : this.state.item.brand,
            codeId: this.props.selectedItem.itemAdded
              ? ''
              : this.state.item.codeId,
            detail: this.props.selectedItem.itemAdded
              ? ''
              : this.state.item.detail,
            id: this.props.selectedItem.itemAdded ? '' : this.state.item.id,
            inventoryItemStatusTypeId: 2,
            model: this.props.selectedItem.itemAdded
              ? ''
              : this.state.item.model,
            type: this.props.selectedItem.itemAdded ? '' : this.state.item.type,
            userId: this.props.selectedItem.itemAdded
              ? ''
              : this.state.item.userId,
          },
          itemAddMessage: this.props.selectedItem.itemAdded
            ? 'Item has been added.'
            : this.props.selectedItem.itemAddedMessage,
          itemUpdatedMessage: this.props.selectedItem.itemUpdated
            ? 'Item has been updated.'
            : this.props.selectedItem.itemUpdatedMessage,
          alertClass:
            this.props.selectedItem.itemAdded ||
            this.props.selectedItem.itemUpdated
              ? this.state.baseAlertClass + 'success'
              : this.state.baseAlertClass + 'danger',
          showCreateItemMessage:
            this.props.selectedItem.itemAdded ||
            this.props.selectedItem.itemUpdated
              ? true
              : false,
          processing: false,
          locationButtonText: this.props.selectedItem.itemAdded
            ? 'Select'
            : this.state.locationButtonText,
          selectedLocationId: this.props.selectedItem.itemAdded
            ? ''
            : this.state.selectedLocationId,
        });
      }
    }
  };

  handleCloseAlert = () => {
    this.props.setSelectedItem('');
    this.setState({
      item: {
        brand: '',
        codeId: '',
        detail: '',
        id: '',
        inventoryItemStatusTypeId: 2,

        model: '',
        type: '',
        userId: '',
      },
      userId: 0,
      selectedUserId: undefined,
      users: [],
      codeIdError: '',
      typeError: '',
      brandError: '',
      modelError: '',
      locationError: '',
      detailError: '',
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showCreateItemMessage: false,
      itemAddMessage: '',
      itemUpdatedMessage: '',
      processing: false,
      locationButtonText: 'Select',
      selectedLocationId: '',
      inventoryItemTypeSuggestions: [],
      suggestions: [],
    });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.inventoryItemTypeSuggestions.filter(
          (suggestion) =>
            suggestion.label.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  getSuggestionValue = async (suggestion) => {
    console.log('');
    if (
      suggestion &&
      suggestion.label &&
      typeof suggestion.label === 'string'
    ) {
      await this.setState({
        ...this.state,
        item: {
          ...this.state.item,
          type: suggestion.label,
        },
        suggestions: [],
      });
    }
  };

  renderSuggestion = (suggestion) => (
    <li
      style={{ color: 'black' }}
      key={suggestion.id}
      onClick={() => this.getSuggestionValue(suggestion)}
    >
      {suggestion.label}
    </li>
  );

  handleAutoSuggestChange = async (e) => {
    if (e && e.target && typeof e.target.value === 'string') {
      await this.setState({
        ...this.state,
        item: {
          ...this.state.item,
          type: e.target.value,
        },
        suggestions: this.getSuggestions(e.target.value),
      });
    }
  };

  handleCloseNotification = () => {
    this.setState({
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showCreateItemMessage: false,
      itemAddMessage: '',
      itemUpdatedMessage: '',
    });
  };

  handleClickOutside = () => {
    this.setState({ selectedUser: {}, selectedUserId: null });
    this.handleCloseAlert();
  };

  render() {
    const {
      selectedItem,
      locations,
      users,
      modalId,
      modalTitleId,
    } = this.props;
    const { selectedUser,selectedUserId, notification } = this.state;
    const isNewItem =
      selectedItem === '' ||
      (typeof selectedItem !== 'undefined' &&
        typeof selectedItem.itemAdded !== 'undefined');
    //const inputProps = { placeholder: 'Laptop or Headset..', value: this.state.item.type, onChange: this.handleAutoSuggestChange };
    return (
      <React.Fragment>
        <LocalModal
          modalName={modalId}
          title={modalTitleId}
          processing={this.state.processing}
          icon="fa fa-clipboard-list"
          onConfirm={this.handleSaveEdit}
          onClose={this.handleCloseAlert}
          notification={isNewItem ? this.state.itemAddMessage : this.state.itemUpdatedMessage}
          alertClass={this.state.alertClass}
          onCloseNotification={() => this.setState({ notification: '' })}
        >
          <div className="row">
            <div className="col-12 col-sm-6">
              <Textbox
                name="codeId"
                value={this.state.item.codeId}
                errorText={this.state.codeIdError}
                inputLabel="Code ID"
                inputType="text"
                inputPlaceholder="AAA000"
                inputClass="form-control form-control-modal"
                labelClass="modal-label"
                handleTextboxChange={this.handleTextboxChange.bind(this)}
                errorMessage={this.state.codeIdError}
                errorLabelClass="text-danger"
                required
              />
            </div>
            <div className="col-12 col-sm-6">
              <Textbox
                name="type"
                value={this.state.item.type}
                errorText={this.state.typeError}
                inputLabel="Type"
                inputType="text"
                inputPlaceholder="Laptop or Headset.."
                inputClass="form-control form-control-modal"
                labelClass="modal-label"
                handleTextboxChange={this.handleAutoSuggestChange.bind(this)}
                errorMessage={this.state.typeError}
                errorLabelClass="text-danger"
                suggestions={this.state.suggestions}
                renderSuggestion={this.renderSuggestion}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <Textbox
                name="brand"
                value={this.state.item.brand}
                errorText={this.state.brandError}
                inputLabel="Brand"
                inputType="text"
                inputPlaceholder="AAA000"
                inputClass="form-control form-control-modal"
                labelClass="modal-label"
                handleTextboxChange={this.handleTextboxChange.bind(this)}
                errorMessage={this.state.brandError}
                errorLabelClass="text-danger"
                required
              />
            </div>
            <div className="col-12 col-sm-6">
              <Textbox
                name="model"
                value={this.state.item.model}
                errorText={this.state.modelError}
                inputLabel="Model"
                inputType="text"
                inputPlaceholder="Model"
                inputClass="form-control form-control-modal"
                labelClass="modal-label"
                handleTextboxChange={this.handleTextboxChange.bind(this)}
                errorMessage={this.state.modelError}
                errorLabelClass="text-danger"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <LocalSelect
                label="Location"
                value={this.state.selectedLocationId}
                onChange={(event) => {
                  const location = locations.find(
                    ({ id }) => id === event.target.value
                  );
                  const locationButtonText = location ? location.name : '';
                  this.setState({
                    selectedLocationId: event.target.value,
                    locationButtonText,
                  });
                }}
                options={locations}
                error={this.state.locationError}
              />
            </div>
            <div className="col-12 col-sm-6">
              <Textbox
                name="detail"
                value={this.state.item.detail}
                errorText={this.state.detailError}
                inputLabel="Detail"
                inputType="text"
                inputPlaceholder="Detail..."
                inputClass="form-control form-control-modal"
                labelClass="modal-label"
                handleTextboxChange={this.handleTextboxChange.bind(this)}
                errorMessage={this.state.detailError}
                errorLabelClass="text-danger"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <LocalSelect
                label="User"
                value={selectedUser ? selectedUser.id : 'na'}
                onChange={(e) => {
                  const selected = users.find((u) => u.id == e.target.value);

                  this.setState({ selectedUser: selected });
                }}
                error={this.state.locationError}
              >
                <option aria-label="None" value="" />
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName + ' ' + user.lastName}
                  </option>
                ))}
              </LocalSelect>
            </div>
            <div className="col-12 col-sm-6 align-items-center">
              {selectedUser && selectedUserId && (
                <div className="inventory-user">
                  <img
                    className="profile-image"
                    src={
                      selectedUser.file ? selectedUser.file.url : defaultImage
                    }
                  />
                  <div className="user-name">
                    {selectedUser.firstName + ' ' + selectedUser.lastName}{' '}
                  </div>

                  <ActionButton
                    row={selectedUser}
                    handleAction={() =>
                      this.setState({
                        selectedUser: null,
                        selectedUserId: null,
                        item: {...this.state.item,
                          userId: '-1'
                        },
                      })
                    }
                    icon="fas fa-trash-alt"
                    style={{ backgroundColor: '#FFC0C0', marginRight:0 }}
                  />
                </div>
              )}
            </div>
          </div>
        </LocalModal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inventoryItems: state.inventoryStore.inventoryItems,
    selectedItem: state.inventoryStore.selectedItem,
    users: state.usersStore.users,
    locations: state.locationsStore.locations,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateItem: (item, selectedUser, inventoryItems) =>
    dispatch(updateItem(item, selectedUser, inventoryItems)),
  setSelectedItem: (id) => dispatch(setSelectedItem(id)),
  setLocations: (locations) => dispatch(setLocations(locations)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(EditItemPopUp));
