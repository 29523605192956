import React, { useState } from 'react';
import './../../../App.css';
import { Services } from '../../../api/services';
import { getPropertyValue } from './userUtilities';
import { Button } from '@material-ui/core';

const DependentFormData = ({
  user,
  title,
  notify,
  next,
  nextText="Next Step",
  profile,
  handleCloseNotification
}) => {
  const [state, setState] = useState({
    action: '',
    file: undefined,
    specificData: {
      url: '',
      fileName: '',
    },
    processing: false,
  });

  const getSpecificFormData = async (userId) => {
    const specificData = await Services.Users.getUserSpecificFormData(user.id)
      .then((data) => {
        data = data.val();
        if (getPropertyValue(data) !== '') {
          if (data.url) {
            data.originalUrl = data.url;
          }
          if (data.fileName) {
            data.originalName = data.fileName;
          }
        }
        return data;
      })
      .catch((_) => undefined);
    return specificData;
  };

  React.useEffect(() => {
    getSpecificFormData(user.id).then((specificFormData) => {
      if (getPropertyValue(specificFormData) !== '') {
        setState({
          ...state,
          specificData: {
            ...state.specificData,
            fileName: specificFormData.fileName,
            url: specificFormData.url,
            userId: specificFormData.userId,
          },
        });
      } else {
        setState({
          ...state,
          specificData: {
            ...state.specificData,
            userId: user.id,
          },
        });
      }
    });
  }, [user.id]);

  const onChangeDependentFile = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      let { specificData } = state;
      if (typeof specificData === 'undefined') specificData = {};
      specificData.url = '';
      specificData.fileName = file.name;

      await setState({
        ...state,
        specificData,
        file,
      });
    }
  };

  const showNot = (registerFormResult, specificData) => {
    if (
      typeof registerFormResult !== 'undefined' &&
      registerFormResult !== null
    ) {
      if (typeof notify !== 'undefined' && notify !== null)
        notify(
          registerFormResult.message,
          registerFormResult.success === true ? 'success' : 'danger'
        );

      if (typeof specificData !== 'undefined' && specificData !== null) {
        setState({
          ...state,
          specificData,
          processing: false,
        });
      }
    }
  };

  const onSubmitDependentForm = async (data) => {
    
    let { specificData, file } = state;
    
    handleCloseNotification();

    const originalName = specificData.originalName;
    let registerFormResult;
    if (
      typeof specificData !== 'undefined' &&
      specificData !== null &&
      typeof file !== 'undefined' &&
      file
    ) {
      await setState({ ...state, processing: true });
      const response = await Services.Users.uploadImageOnStorage(
        user.id,
        file,
        originalName
      );

      if (response.success) {
        specificData.url = response.downloadUrl;
        specificData.userId = user.id;
        delete specificData.bpsCertificate;
        delete specificData.dgiCertificate;
        delete specificData.companyName;
        delete specificData.companyNumber;
        delete specificData.companyIdentifier;
        const specificFormData = await Services.Users.getUserSpecificFormData(
          data.userId
        )
          .then((specificFormData) => {
            return specificFormData;
          })
          .catch((error) => {
            console.log('error getting specific form data', error);
            const registerFormResult = {
              success: false,
              message:
                'An error ocurred when trying to save the information. Please try again later',
              step: 3,
            };
            return registerFormResult;
          });

        if (
          typeof specificFormData.val() !== 'undefined' &&
          specificFormData.val() !== null
        ) {
          registerFormResult = await Services.Users.updateUserSpecificFormData(
            specificData
          )
            .then(async () => {
              return await Services.Users.setRegisterFormCompleted(
                specificData.userId
              )
                .then(() => {
                  registerFormResult = {
                    success: true,
                    message: 'The information was successfully updated',
                    step: 3,
                    specificData,
                  };
                  showNot(registerFormResult, specificData);
                  !profile && next();
                  return registerFormResult;
                })
                .catch((error) => {
                  console.log('error updating specific form data', error);
                  registerFormResult = {
                    success: false,
                    message:
                      'An error ocurred when trying to update the information. Please try again later',
                    step: 3,
                  };
                  showNot(registerFormResult, specificData);
                  return registerFormResult;
                });
            })
            .catch((error) => {
              console.log('error updating specific form data', error);
              registerFormResult = {
                success: false,
                message:
                  'An error ocurred when trying to update the information. Please try again later',
                step: 3,
              };
              showNot(registerFormResult, specificData);
              return registerFormResult;
            });
        } else {
          registerFormResult = Services.Users.postUserSpecificFormData(
            specificData
          )
            .then(async () => {
              return await Services.Users.setRegisterFormCompleted(
                specificData.userId
              )
                .then(() => {
                  registerFormResult = {
                    success: true,
                    message: 'The information was successfully saved',
                    step: 3,
                    specificData,
                  };
                  showNot(registerFormResult, specificData);
                  !profile && next();
                  return registerFormResult;
                })
                .catch((error) => {
                  console.log('error saving specific form data', error);
                  registerFormResult = {
                    success: false,
                    message:
                      'An error ocurred when trying to save the information. Please try again later',
                    step: 3,
                  };
                  showNot(registerFormResult, specificData);
                  return registerFormResult;
                });
            })
            .catch((error) => {
              console.log('error saving specific form data', error);
              registerFormResult = {
                success: false,
                message:
                  'An error ocurred when trying to save the information. Please try again later',
                step: 3,
              };
              showNot(registerFormResult, specificData);
              return registerFormResult;
            });
        }
      }
    } else {
      if(profile){
        registerFormResult = {
        success: false,
        message:
          'There is not file to upload. Please select a file and try again',
        };
        showNot(registerFormResult, specificData);
      }else next();
    }
  };


  return (
    <React.Fragment>
      <h2 className="onboarding-title">{title}</h2>
      <div className="container" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className="col-12 col-lg-6">
          <br />
          <Button
            variant="contained"
            component="label"
            className="upload-button"
          >
            <span className="upload-button-text">Upload 3100 DGI form</span>
            <i class="fas fa-paperclip"></i>
            <input
              type="file"
              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
              style={{ display: 'none' }}
              onChange={onChangeDependentFile}
            />
          </Button>
          <a href={state.specificData.url} className="certificate-link" target="_blank">
            {typeof state.specificData !== 'undefined' &&
            state.specificData !== null &&
            typeof state.specificData.url !== 'undefined' &&
            state.specificData.url !== null &&
            state.specificData.fileName !== '' ? (
              <label className="ml-4">
                To download the file please click{' '}
                <a href={state.specificData.url} target="_blank">here</a>
              </label>
            ) : (
              ''
            )}
          </a>
        </div>
        <div className="col-12 text-center">
          <button type="button" onClick={onSubmitDependentForm} className="next-button">
            {nextText}{!profile && <i class="fas fa-arrow-right next-button-icon"></i>}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DependentFormData;
