const initialState = {
  project: {},
  projects: [],
  selectedProjectId: ""
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
      case "SET_PROJECTS":
          return {
              ...state,
              projects: action.projects
          };
      case "CREATE_NEW_PROJECT":
          return  {
              ...state,
              project: action.payload.project,
              projects: action.payload.projects
          }
      case "UPDATE_PROJECT":
          return  {
              ...state,
              project: action.payload.project,
              projects: action.payload.projects
          }
      case 'SET_PROJECT_ID':
          return {
              ...state,
              selectedProjectId: action.selectedProjectId
          }
      default:
        return state;
    }
  };
  
export default projectsReducer;