
import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import {Services} from '../services';
import { PRINT_IN_CONSOLE } from '../../components/shared/constants/constants'
import { config } from '../../utilities/env';
import { verificationEmailTemplate } from '../../utilities/verification.email.template'
require('firebase/functions');

// const config = process.env.REACT_APP_PRODUCTION ? require('../config/1950labs-prod.json') : (process.env.REACT_APP_TESTING ? require('../config/1950labs-testing.json') : require('../config/1950labs-staging.json')); 
// firebase.initializeApp(config);

export class UsersApi {

    getUserLoggedIn() {
        if(firebase.auth())
            return firebase.auth().currentUser;
        return undefined;
    }

    getAuthUser(){
        return JSON.parse(localStorage.getItem('authUser'));
    }

    logout() {
        try {
            return firebase.auth().signOut().then(response => {
                return true;
            });
        } catch(e) {
            return false;
        }
    }

    login(email, password) {
        return new Promise((resolve, reject) => {
            const context = this;
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then(() => {
            firebase.auth().onAuthStateChanged(function (authUser) {
                
                const databaseRef = firebase.database().ref();
                if (authUser) {
                    localStorage.setItem('authUser', JSON.stringify(authUser));
                    databaseRef 
                    .child(`Users/${authUser.uid}`)
                    .once("value")
                    .then((user) => {
                        if(user.val()) {
                            if(authUser.email !== user.val().email) {
                                context.updateEmail(authUser.email, user.val().id).then(result => {
                                    context.getLoginInformation(user.val(), databaseRef).then(info => {
                                        resolve(info);
                                    });
                                }).catch(error => {
                                    if(PRINT_IN_CONSOLE) {
                                        console.log('error updating email', error.message);
                                    }
                                    context.getLoginInformation(user.val(), databaseRef).then(info => {
                                        resolve(info);
                                    });
                                });
                            }else {
                                context.getLoginInformation(user.val(), databaseRef).then(info => {
                                    resolve(info);
                                });
                            }
                            
                        }
                    });
                } else
                    localStorage.removeItem('authUser');
            });
        })
        .catch((error) => {
            resolve(null);
          });
        });
    }

    getRoleNavigationInfo(user) {
        return this.getLoginInformation(user, firebase.database().ref());
    }

    getLoginInformation(user, databaseRef) {
        return new Promise((resolve, reject) => {
            Services.General.getUserStatusTypes().then(userStatusTypes => {
                userStatusTypes.val().forEach(userStatusType => {
                    if(userStatusType.name === 'Inactive' 
                    && userStatusType.id.toString() === user.userStatusTypeId.toString()) {
                        resolve(null);
                    }
                });
                databaseRef
                .child(`UserRoleNavigation/${user.userRoleId}`)
                .once("value")
                .then((navItems) => {
                    let navigation = [];
                    if(navItems.val()) {
                        Object.values(navItems.val()).forEach(item=> {
                            databaseRef
                            .child(`Navigation/${item}`)
                            .once("value")
                            .then(navItem => {
                                navigation.push(navItem.val())
                                if(Object.values(navItems.val()).length === navigation.length) {
                                    resolve({
                                        user,
                                        navigation: navigation.sort((a,b) => (String(a.order).toLowerCase() > String(b.order).toLowerCase()) ? 1 : ((String(b.order).toLowerCase() > String(a.order).toLowerCase()) ? -1 : 0))
                                    });
                                }
                            } )  
                        })
                    }
                })
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('error getting user status types', error.message);
                }
                resolve(null);
            });
        });
    }

    updateEmail = async(email, userId) => {
        const emailUpdated = await firebase.database()
            .ref(`Users/${userId}`)
            .update({ 
                email
            })
            .then(()=> {
                return true;
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error updating email:', error.message);
                }
                return false;
            });
        return emailUpdated;
    }

    getEmail = () => {
        const user = firebase.auth().currentUser;
        if(!user || !user.email) return "";
        return user.email;
    }

    isPasswordCorrect = async (password) => {
        var user = firebase.auth().currentUser;
        var credential = await firebase.auth.EmailAuthProvider.credential(
            user.email,
            password
        );
        var response;
        await user.reauthenticateAndRetrieveDataWithCredential(credential).then(function() {
            response = true;
        }).catch(function(error) {
            response = false;
        });
        return response;
    }

    getUsers() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`Users`)
            .once("value")
            .then((users) => {
                resolve(users);
            });    
        });
    }

    getUser(userId) {
        return firebase.database().ref(`Users/${userId}`)
        .once("value");
    }

    changePassword(email, oldPassword, newPassword) {
        return new Promise((resolve, reject) => {
            const cred = firebase.auth.EmailAuthProvider.credential(
                email, oldPassword);
            firebase.auth().signInAndRetrieveDataWithCredential(cred)
                .then( async response => {
                    firebase.auth().currentUser.updatePassword(newPassword)
                    .then(()=> {
                        resolve({ message: 'The password was changed successfully', success: true });
                    })
                    .catch(error => {
                        if(PRINT_IN_CONSOLE) {
                            console.log('Error changing password:', error.message);
                        }
                        resolve({ message: 'Error when trying to change the password. Please re try.', success: false });
                    });
                })
                .catch(error => {
                    if(PRINT_IN_CONSOLE) {
                        console.log('Error changing password:', error.message);
                    }
                    resolve({ message: 'Error when trying to change the password. Please re try.', success: false });
                });
        });
    }

    isSignInWithEmailLink = (link) => {
        return firebase.auth().isSignInWithEmailLink(link);
    }

    signInWithEmailLink = (email, link) => {
        return firebase.auth().signInWithEmailLink(email, link);
    }

    sendSignInLinkToEmail = (email, userTypeId, locationId, userRoleId, userStatusTypeId) => {
        const registrationCode = firebase.database().ref(`Registration`)
        .push({
            email,            
            userTypeId,
            locationId,
            userRoleId,
            userStatusTypeId,
            needsToFillRegister: true
        }).getKey();
        return firebase.database().ref(`Registration/${registrationCode}`)
        .update({ id: registrationCode })
        .then(()=> {
            const link = config.url+"authActions?apiKey=AIzaSyBLnQXVTFVTlKWietUWRn__zhQX65oMO74&mode=signIn&oobCode=MdgjC1CTe-qhrnuS_28dC8im7FEKTCtmMH01Tz6gyS4AAAF3guG4zA&continueUrl="+config.url+"?registration_code%3D"+registrationCode;//config.projectId === "labs-prod-ff285" ? "" : config.projectId === "labs-51e50" ? "" : ""
            const mailBody = verificationEmailTemplate(link, config.verificationEmailImageUrl);
            console.log(mailBody)
            const data = {
                "HtmlBody": mailBody,
                "From": "noreply@1950labs.com",
                "NameFrom": "1950Labs",
                "Subject": "Verify your e-mail",
                "To": email,
                "Name To": "",
                "tos": {
                    "noreply@1950labs.com": "1950Labs"
                }
            };
            return fetch(config.notificationsApi, {
                method: 'POST', 
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data)
            })
            // firebase.auth().sendSignInLinkToEmail(email, {
            //     handleCodeInApp: true,
            //     url: config.url+"?registration_code="+registrationCode
            // })
            .then(response => {
                console.log(response);
                return { success: true, response };
            }).catch(error => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error registering user:', error.message);
                }
                console.log(error);
                return { success: false, error, message: "Error sending the email to the user"};
            });
        })
        .catch((error) => {
            console.log(error);
            if(PRINT_IN_CONSOLE) {
                console.log('Error registering user:', error.message);
            }
            return { success: false, error, message: "Error registering user"};
        })
    }


    getRegistrationInfo(registrationCode) {
        return firebase.database().ref(`Registration/${registrationCode}`).once("value").then(response => {
            return response;
        }).catch(error => {
            if(PRINT_IN_CONSOLE) {
                console.log('Error registering user:', error.message);
            }
            return { success: false, error, message: "Error getting registration info"};
        });
    }

    deleteRegistrationInfo(registrationCode) {
        return firebase.database().ref(`Registration/${registrationCode}`).remove()
        .then(()=> { 
            return true;
        })
        .catch((error) => {
            if(PRINT_IN_CONSOLE) {
                console.log('Error removing registration:', error.message);
            }
            return false;
        });
    }
    
    //TODO: MANUEL FIREBASE ARREGLAR EL ADD ITEM (Y FALTA EL EDIT Y DELETE, y limpiar codigo)
    createNewUser(email, firstName, lastName, password, userRoleId, userTypeId, locationId, userStatusTypeId, needsToFillRegister, users,nationality,birthDate) {
        console.log({email, firstName, lastName, password, userRoleId, userTypeId, locationId, userStatusTypeId, needsToFillRegister, users,nationality,birthDate})
        return new Promise((resolve, reject) => {
            //firebase.auth().sendSignInLinkToEmail(email, {url: config.url, handleCodeInApp: true})
            firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((userRecord) => {
                console.log(userRecord);
              setTimeout(()=> {
                return firebase
                .database()
                .ref(`Users/${userRecord.user.uid}`)
                .set({
                    email,
                    firstName,
                    id: userRecord.user.uid,
                    lastName,
                    locationId,
                    userRoleId,
                    userStatusTypeId,
                    userTypeId,
                    needsToFillRegister,
                    nationality,
                    birthDate
                })
                .then(()=> {
                    resolve({
                            users,
                            id: userRecord.user.uid,
                            firstName,
                            lastName,
                            email,
                            password,
                            userRoleId,
                            userTypeId,
                            userStatusTypeId,
                            locationId,
                            userAdded: true,
                            userUpdated: false,
                            needsToFillRegister,
                            userAddedMessage: 'Successfully created new user',
                            nationality,
                            birthDate
                        });
                })}, 1500)})
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error creating new user:', error.message);
                }
                resolve({
                    firstName,
                    lastName,
                    email,
                    userAdded: false,
                    userAddedMessage: error.message
                });
            });   
        });
    }

    updateUser(userId, firstName, lastName, userRoleId, userTypeId, locationId, userStatusTypeId, users) {
        return new Promise((resolve, reject) => {
            Services.Roles.getRole(userRoleId).then(roleResponse => {
                if(roleResponse) {
                    let role = roleResponse.val();
                    if(role.status === "Active") {
                        firebase.database()
                        .ref(`Users/${userId}`)
                        .update({ 
                            firstName,            
                            lastName,
                            userRoleId,
                            userTypeId,
                            userStatusTypeId,
                            locationId
                        })
                        .then(()=> { 
                            resolve({
                                users,
                                id: userId,
                                firstName,
                                lastName,
                                userAdded: false,
                                userUpdated: true,
                                userUpdatedMessage: 'Successfully updated user'
                            });
                        })
                        .catch((error) => {
                            if(PRINT_IN_CONSOLE) {
                                console.log('Error updating user:', error.message);
                            }
                            resolve(null); 
                        });
                    } else {
                        //get userStatus con userStatusTypeId
                        Services.General.getUserStatusType(userStatusTypeId).then(userStatusTypeResponse => {
                            if(userStatusTypeResponse) {
                                let userStatusType = userStatusTypeResponse.val();
                                if(userStatusType.name === "Active") {
                                    //no se puede poner activo un usuario cuyo rol esta inactivo
                                    resolve({
                                        users,
                                        userAdded: false,
                                        userUpdated: false,
                                        userUpdatedMessage: 'It is not possible to set user as active when the role is inactive'
                                    });
                                } else {
                                    firebase.database()
                                    .ref(`Users/${userId}`)
                                    .update({ 
                                        firstName,            
                                        lastName,
                                        userRoleId,
                                        userTypeId,
                                        userStatusTypeId,
                                        locationId
                                    })
                                    .then(()=> { 
                                        resolve({
                                            users,
                                            id: userId,
                                            firstName,
                                            lastName,
                                            userAdded: false,
                                            userUpdated: true,
                                            userUpdatedMessage: 'Successfully updated user'
                                        });
                                    })
                                    .catch((error) => {
                                        if(PRINT_IN_CONSOLE) {
                                            console.log('Error updating user:', error.message);
                                        }
                                        resolve(null); 
                                    });
                                }
                            }
                        })
                        .catch(error => {
                            if(PRINT_IN_CONSOLE) {
                                console.log('Error updating user: error getting user status types', error.message);
                            }
                            resolve(null); 
                        });
                    }
                } else {
                    resolve(null);
                }
                
            });
              
        });
    }

    downloadImage(fileUrl) {
        return new Promise((resolve, reject) => {
            firebase.storage().ref().child(fileUrl).getDownloadURL()
            .then((url) => 
                resolve(url))
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('error getting image url ' + error.message, error);
                }
                resolve(null);
            });
        });
    };

    reAuthenticate = async (credentials) => {
        const cred = firebase.auth.EmailAuthProvider.credential(
            credentials.email, credentials.password);
        const success = await firebase.auth().currentUser
                            .reauthenticateAndRetrieveDataWithCredential(cred)
                            .then(() => {
                                return true;
                            }).catch(error => {
                                if(PRINT_IN_CONSOLE)
                                    console.log(error);
                                return false;
                            });
        return success;
    }

    putImage = (id, image) => {
        return new Promise((resolve, reject) => {
            firebase.storage().ref().child(id+'/'+image.name)
            .put(image)
            .then(res => {
                resolve(true);
            })
            .catch(error => {
                if(PRINT_IN_CONSOLE)
                    console.log(error);
                resolve(false);
            });
        });
    }

    deleteImage = (url) => {
        return new Promise((resolve, reject) => {
            firebase.storage().ref().child(url)
            .delete()
            .then(res => {
                resolve(true);
            })
            .catch(error => {
                if(PRINT_IN_CONSOLE)
                    console.log(error);
                resolve(false);
            });
        });
    }

    getImageUrl = (url) => {
        return new Promise((resolve, reject) => {
            firebase.storage().ref().child(url)
            .getDownloadURL()
            .then(downloadUrl => {
                resolve(downloadUrl);
            })
            .catch(error => {
                if(PRINT_IN_CONSOLE)
                    console.log(error);
                resolve("");
            });
        });
    }

    updateUserFileOnly = async (userId, file) => {
        const success = await this.getUser(userId).then(async (user) => {
            user = user.val();
            if(user) {
                user.file = file;
                return { success: await this.uploadUserData(user), user };
            }
            return { success: false };
        }).catch(_ => { return { success: false } });
        return success;
    }

    uploadImageOnStorage = async (id, image, originalName) => {
        let deleteResult = false, putImageResult = false, downloadUrl = "";
        if((typeof(originalName) !== 'undefined' && originalName !== null && originalName !== "")) {
            deleteResult = await this.deleteImage(id+'/'+originalName);
            if(!deleteResult) return { success: false, message: 'Error when deleting the old image. Please try again later.'};
            console.log("delete result: " + deleteResult);
            putImageResult = await this.putImage(id, image);
            console.log("put image result: " + putImageResult);
            if(putImageResult) {
                downloadUrl = await this.getImageUrl(id+'/'+image.name);
                console.log("downloadUrl: " + downloadUrl);
                if(downloadUrl !== "")
                    return { success: true, downloadUrl };
            }
            
        } else {
            putImageResult = await this.putImage(id, image);
            console.log("put image result: " + putImageResult);
            if(putImageResult) {
                downloadUrl = await this.getImageUrl(id+'/'+image.name);
                console.log("downloadUrl: " + downloadUrl);
                if(downloadUrl !== "")
                    return { success: true, downloadUrl };
            }
            return { success: false, message: 'Error when updating the image. Please try again later.'};
        }
    }

    alreadyExistsAnAccountWithThatEmail = async (userEmail, userId) => {
        if(!userEmail) return { success: false, users: [] };
        let newUsers = [];
        const success = await this.getUsers().then(response => {
            let usersResponse = response.val();
            for (var key in usersResponse) {
                newUsers.push(usersResponse[key]);
                if(usersResponse[key]['email'].toLowerCase() === userEmail.toLowerCase() && usersResponse[key]['id'] !== userId) {
                    return true;
                }
            }
            return false;
        });
        return { success, users: newUsers };
    }

    updateAuthEmail = async (email) => {
        const success = await firebase.auth().currentUser.updateEmail(email).then(() => {
            return true;
        }).catch(error => {
            if(PRINT_IN_CONSOLE)
                console.log(error);
            return false;
        });
        return success;
    }

    updatePassword = async (password) => {
        const success = await firebase.auth().currentUser.updatePassword(password).then(() => {
            return true;
        }).catch(error => {
            if(PRINT_IN_CONSOLE)
                console.log(error);
            return false;
        });
        return success;
    }

    changeCredentials = async (updateEmail, updatePassword, credentials, email, newPassword) => {
        updateEmail = (typeof updateEmail) !== 'undefined' && updateEmail !== null ? updateEmail : false;
        updatePassword = (typeof updatePassword) !== 'undefined' && updatePassword !== null ? updatePassword : false;
        if(updateEmail) {
            const reAuthenticateResult = await this.reAuthenticate(credentials);
            if(reAuthenticateResult) {
                const updateEmailResult = await this.updateAuthEmail(email);
                if(!updateEmailResult) {
                    return { success: false, message: 'Error updating email' };
                }
                if(updateEmailResult && updatePassword) {
                    const updatePasswordResult = await this.updatePassword(newPassword);
                    if(updatePasswordResult) return { success: true };
                    return { success: false, message: 'Error updating email' };
                }
                return { success: true };
            } else {
                return { success: false, message: 'Error updating email. The system can´t confirm your credentials' };
            }
        } else {
            if(updatePassword) {
                const reAuthenticateResult = await this.reAuthenticate(credentials);
                if(reAuthenticateResult) {
                    const updatePasswordResult = await this.updatePassword(newPassword);
                    if(!updatePasswordResult) {
                        return { success: false, message: 'Error updating password' };
                    }
                    return { success: true };
                } else {
                    return { success: false, message: 'Error updating password. The system can´t confirm your credentials' };
                }
            }
        }
    }

    uploadUserData = async (user) => {
        const success = await firebase.database()
        .ref(`Users/${user.id}`)
        .update(user)
        .then(()=> {
            return true;
        })
        .catch(error => {
            if(PRINT_IN_CONSOLE)
                console.log(error);
            return false;
        });
        return success;
    }

    updateProfile = async (user, uploadImage, image, oldFile) => {
        //const users = user.users;
        if(!user.id) {
            user["userUpdated"] = false;
            user['userUpdatedMessage'] = "Error updating profile";
            return user;
        }
        const userPasswordUpdate = user.passwordUpdate;
        const userEmailUpdate = user.emailUpdate;
        const credentials = user.credentials;
        delete user.credentials;
        delete user.users;
        delete user.passwordUpdate;
        delete user.emailUpdate;
        const password = user.password;
        delete user.password;
        //let userCounter = 0;
        //let isEmailValid = true;
        let newUsers = [];
        uploadImage = (typeof uploadImage) !== 'undefined' && uploadImage !== null ? uploadImage : false;
        const existsUserEmail = await this.alreadyExistsAnAccountWithThatEmail(user.email, user.id);
        newUsers = existsUserEmail.users;
        if(existsUserEmail.success) {
            user["userUpdated"] = false;
            user['userUpdatedMessage'] = "Already exists an account with that email";
            return user;
        }
        if(uploadImage) {
            const imageUrlResult = await this.uploadImageOnStorage(user.id, image, oldFile.name);
            if(imageUrlResult.success === false) {
                user["userUpdated"] = false;
                user['userUpdatedMessage'] = (typeof imageUrlResult.message) !== 'undefined' && imageUrlResult.message ? imageUrlResult.message : "The user image could not be updated";
                return user;
            }
            const file = { 'name': image.name, 'type': image.type, 'url': imageUrlResult.downloadUrl };
            user.file = file;
        }
        if((typeof user.needsToFillRegister) === 'undefined' || user.needsToFillRegister === undefined || user.needsToFillRegister === null)
            user.needsToFillRegister = true;
        const userUpdateResult = await this.uploadUserData(user);
        user['userAdded'] = false;
        user["userUpdated"] = true;
        user["userUpdatedMessage"] = 'The information was successfully updated';
        user['uploadImage'] = uploadImage;
        user['users'] = newUsers;
        if(!userUpdateResult) {
            user["userUpdated"] = false;
            user['userUpdatedMessage'] = 'The user could not be updated';
            return user;
        } 
        if(userUpdateResult && !userEmailUpdate && !userPasswordUpdate) return user;
        if(userUpdateResult && (userEmailUpdate || userPasswordUpdate)) {
            const changeCredentialsResult = await this.changeCredentials(userEmailUpdate, userPasswordUpdate, credentials, user.email, password);
            if(changeCredentialsResult.success) return user;
            user["userUpdated"] = false;
            user['userUpdatedMessage'] = (typeof changeCredentialsResult.message) !== 'undefined' && changeCredentialsResult.message ? changeCredentialsResult.message : "The credentials could not be updated";
            return user;
        }                          
    };

    getUserBankData = userId => {
        return firebase.database().ref(`BankData/${userId}`)
        .once("value");
    }

    postBankData = bankData => {
        return firebase.database().ref(`BankData/${bankData.userId}/${bankData.id}`).set(bankData);
    }

    updateBankData = bankData => {
        return firebase.database().ref(`BankData/${bankData.userId}/${bankData.id}`).update(bankData);
    }

    getUserSpecificFormData = userId => {
        return firebase.database().ref(`UserSpecificFormData/${userId}`)
        .once("value");
    }

    postUserSpecificFormData = specificFormData => {
        return firebase.database().ref(`UserSpecificFormData/${specificFormData.userId}`).set(specificFormData);
    }

    updateUserSpecificFormData = specificFormData => {
        return firebase.database().ref(`UserSpecificFormData/${specificFormData.userId}`).update(specificFormData);
    }

    setRegisterFormCompleted = (userId) => {
        return firebase.database()
        .ref(`Users/${userId}`)
        .update({
            needsToFillRegister: false
        });
    }

    sendResetPasswordLink(email) {
        return new Promise((resolve, reject) => {
            firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                resolve({
                    email,
                    linkSent: true,
                    message: 'The link was sent'
                });
            }).catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error sending reset password link:', error.message);
                }
                resolve({
                    email,
                    linkSent: false,
                    message: 'The link could not be sent'
                });
            });
        });
    }

    resetPassword(actionCode, newPassword) {
        return new Promise((resolve, reject) => {
            firebase.auth().confirmPasswordReset(actionCode, newPassword)
            .then(() => {
                resolve({
                    success: true
                });
            }).catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error updating item:', error.message);
                }
                resolve({
                    success: false
                });
            });
        });
    }

    verifyEmail = async (authCode) => {
        const authEmailVerified = firebase.auth().applyActionCode(authCode).then(function () {
            return true;
        })
        .catch((error) => {
            if(PRINT_IN_CONSOLE) {
                console.log('Error updating item:', error.message);
            }
            return false;
        });
        return authEmailVerified;
    }

    recoverEmail = async (authCode) => {
        let restoredEmail = '';
        await firebase.auth().checkActionCode(authCode).then(async (info) => {
            restoredEmail = await info['data']['email'];
        });
        if((typeof restoredEmail) !== 'undefined' && restoredEmail !== null && restoredEmail !== '') {
            const authEmailUpdated = await firebase.auth().applyActionCode(authCode).then(() => {
                return true;
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error updating item:', error.message);
                }
                return false;
            });
            return authEmailUpdated;
        } else {
            return false;
        }
    }
}
