const initialState = {
    locations: [],
    selectedLocationId: undefined
};

const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_LOCATIONS":
          return {
            ...state,
            locations: action.locations
          };
        case "SET_LOCATION_ID":
          return {
            ...state,
            selectedLocationId: action.selectedLocationId
          }
          case 'SET_NEW_LOCATION':
                return  {
                    ...state,
                    location: action.payload.location,
                    locations: action.payload.locations
                }
            case 'SET_NEW_LOCATION_ERROR':
                return  {
                    ...state,
                    location: action.location
                }
            case 'SET_UPDATED_LOCATION':
                return  {
                    ...state,
                    location: action.payload.location,
                    locations: action.payload.locations
                }
            case 'SET_UPDATED_LOCATION_ERROR':
                return  {
                    ...state,
                    location: action.location
                }
            case 'REMOVE_LOCATION':
                let locationToRemove = state.locations.find(location=> {
                    if(location.id === action.location.id) {
                        return location;
                    } 
                    return undefined;
                });
                let newLocations = [...state.locations];
                if((typeof locationToRemove) !== 'undefined') {
                    let index = newLocations.indexOf(locationToRemove);
                    if (index !== -1) {
                        newLocations.splice(index, 1);
                        return {
                            ...state,
                            ...state.selectedLocationId,
                            locations: newLocations,
                            location: action.location
                        }
                    }
                }
                return {
                    ...state,
                    ...state.selectedLocationId,
                    ...state.newLocations,
                    location: action.location
                }
            case 'REMOVE_LOCATION_ERROR_MESSAGE':
                return {
                    ...state,
                    location: action.location
                }
          default:
            return state;
    }
}
export default locationReducer;