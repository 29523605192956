import React from 'react';
import './../../App.css';

function RequiredInputLabel(props) {
  return (
    <div className="form-group rb-label-input">
      <label className={props.labelClass}>
        {props.labelName}

        <span
          className={'ml-1 ' + (props.notRequired === true ? 'd-none' : '')}
          style={{ color: 'red' }}
        >
          {' '}
          *
        </span>
      </label>
      <div className="d-flex align-items-center w-100" >
        {typeof props.inputType !== 'undefined' &&
        props.inputType &&
        typeof props.value !== 'undefined' &&
        props.value ? (
          <input
            name={props.inputName}
            className={props.inputClass}
            ref={props.refProp}
            type={props.inputType}
            defaultValue={props.value}
            autoComplete="new-password"
            onKeyUp={props.onChangeFunction}
          />
        ) : typeof props.inputType !== 'undefined' && props.inputType ? (
          <input
            name={props.inputName}
            className={props.inputClass}
            ref={props.refProp}
            type={props.inputType}
            autoComplete="new-password"
            onKeyUp={props.onChangeFunction}
          />
        ) : typeof props.value !== 'undefined' && props.value ? (
          <input
            name={props.inputName}
            className={props.inputClass}
            ref={props.refProp}
            defaultValue={props.value}
            autoComplete="new-password"
            onKeyUp={props.onChangeFunction}
          />
        ) : (
          <input
            name={props.inputName}
            className={props.inputClass}
            ref={props.refProp}
            autoComplete="new-password"
            onKeyUp={props.onChangeFunction}
          />
        )}
        {props.showPasswordComponent}
      </div>
      {typeof props.errorMessage !== 'undefined' &&
      props.errorMessage &&
      props.errorMessage.length > 0 ? (
        <div className="text-danger">{props.errorMessage}</div>
      ) : (
        ''
      )}
    </div>
  );
}

export default RequiredInputLabel;
