import React, { Component } from 'react';
import UserTypesModal from '../userTypes/user-types-modal';
import Spinner from 'react-bootstrap/Spinner'
import { connect } from "react-redux";
import { setUserTypes, setSelectedUserType, deleteUserType } from '../../actions/user-types-actions'
import { getUserTypes } from '../../utilities/api.calls';
import { ActionButton, AddNew, LocalModal, LocalTable, Sort } from '../common';
import { TitlePanel } from '../common/LocalPanels';
import edit from '../../assets/images/icons/edit.svg';

class UserTypesMain extends Component {
  constructor(props) {
    super(props);
    this.handleSelectedEdit = this.handleSelectedEdit.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.state = { 
      userTypes: [],
      userTypesLoaded: false,
      userType: {
          id: 0,
          name: "",
          description: ""
      },
      selectedUserTypeId: "",
      orderByNameAsc: false,
      orderByDescriptionAsc: false,
      showUserTypeDeletedMessage: false,
      baseAlertClass: "text-center alert alert-dismissible fade show alert-",
      alertClass: "",
      confirmButton: true,
      userTypeDeletedMessage: "",
      processing: false,
      buttons: [{
        id: 1,
        className: "btn btn-sm btn-success mr-2",
        dataToggle: "modal",
        dataTarget: "#createUserType",
        title: "Add User Type",
        onClick: this.handleSelectedEdit.bind(this)
      }],
      sortField: '',
      sortDirection: 'asc',
      tableColumns: [
        {
          position: 1,
          field: 'name',
          label: 'Name',
          sortable: true,
        },

        {
          position: 2,
          field: 'description',
          label: 'Description',
          sortable: true,
        },
        {
          stylesHeader: { maxWidth: '40px', textAlign: 'right' },
          stylesCell: { textAlign: 'right' },
          position: 4,
          field: '',
          label: 'Actions',
          sortable: false,
          type: 'button',
          customComponent: (row) => {
            return (
              <div className="button-container">
                <ActionButton
                  row={row}
                  dataToggle="modal"
                  dataTarget="#createUserType"
                  handleAction={() => this.handleSelectedEdit({ target: { value: row.id}})}
                  icon="fa fa-edit icon edit"
                  iconSrc={edit}
                  color=""
                />
                <ActionButton
                  row={row}
                  dataToggle="modal"
                  dataTarget="#confirmDelete"
                  handleAction={async () => {
                    await this.setState({
                      deleteUserTypeId: row.id,
                      deleteUserTypeName: row.name,
                      deleteUserTypeDescription: row.description
                    });
                  }}
                  icon="fas fa-trash-alt"
                  style={{ backgroundColor: '#FFC0C0' }}
                />
              </div>
            );
          },
        },
      ]
    }

  }
    

    componentDidMount = () => {
        getUserTypes().then(response => {
          if(!response || response.success === false) {
            this.setState({...this.state, userTypesLoaded: true, errorMessage: response.error});
          } else {
            if(response.success === true) {
                this.setState({...this.state, userTypesLoaded: true, userTypes: response.types});
            }
          }
          
        })
    }

    componentDidUpdate = async (prevProps) => {
      const { userType } = this.props;
      if(prevProps.userType !== userType && (typeof userType) !== 'undefined' && userType 
       && (typeof userType.userTypeDeleted) !== 'undefined' && (typeof userType.userTypeDeletedMessage) !== 'undefined'
       && userType.userTypeDeletedMessage && userType.userTypeDeletedMessage.length > 0) {
        const className = userType.userTypeDeleted ? "success" : "danger";
        await this.setState({
          confirmButton: false,
          showUserTypeDeletedMessage: true,
          userTypeDeletedMessage: userType.userTypeDeletedMessage,
          alertClass: this.state.baseAlertClass+className,
          processing: false
        });
      }
      if(prevProps.userTypes !== this.props.userTypes){
          await this.setState({
              ...this.state,
              userTypes: this.props.userTypes
          });
      }
     }

     handleSelectedEdit = event => {
         this.setState({
            selectedUserTypeId: event.target.value
         })
        this.props.setSelectedUserType(event.target.value);
      };

      alphabeticSortByNameAsc = () => {
        this.setState({
          userTypes: this.state.userTypes.sort(
            (a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 
              ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)
          ),
          orderByNameAsc: false
        });
      }

      alphabeticSortByNameDesc = () => {
        this.setState({
          userTypes: this.state.userTypes.sort(
            (a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : 
              ((b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : 0)
          ),
          orderByNameAsc: true
        });
      }

      alphabeticSortByDescriptionAsc = () => {
        this.setState({
            userTypes: this.state.userTypes.sort(
              (a,b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : 
                ((b.description.toLowerCase() > a.description.toLowerCase()) ? -1 : 0)
            ),
            orderByDescriptionAsc: false
        });
      }

      alphabeticSortByDescriptionDesc = () => {
        this.setState({
            userTypes: this.state.userTypes.sort(
              (a,b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? -1 : 
                ((b.description.toLowerCase() > a.description.toLowerCase()) ? 1 : 0)
            ),
            orderByDescriptionAsc: true
        });
      }

      sortUserTypes = (field, sortDirection) => {
        const asc = sortDirection === 'asc';
        let { userTypes } = this.state;
        userTypes = userTypes.sort((a, b) =>
          a[field].toLowerCase() > b[field].toLowerCase()
            ? asc
              ? 1
              : -1
            : b[field].toLowerCase() > a[field].toLowerCase()
            ? asc
              ? -1
              : 1
            : 0
        );
        this.setState({
          sortField: field,
          sortDirection: sortDirection,
          userTypes
        });
      };

      handleCloseAlert = () => {
        this.props.setSelectedUserType(undefined);
        this.setState({
          confirmButton: true,
          showUserTypeDeletedMessage: false,
          userTypeDeletedMessage: "",
          alertClass: "",
          processing: false
        });
      }

      handleCloseNotification = () => {
        this.setState({
          showUserTypeDeletedMessage: false,
          userTypeDeletedMessage: "",
          alertClass: ""
        });
      }

      goToHome = () => {
          this.props.history.push('/app')
      }
      
    render() {
        const { 
          userTypes,
          userTypesLoaded,
          errorMessage,
          sortDirection,
          sortField,
          tableColumns
        } = this.state;
        
        if(!userTypesLoaded) {
            return (
                <React.Fragment>
                    <div style={{textAlign: 'center', marginTop: '5rem'}}>
                        <Spinner style={{color: 'white'}} animation="border" />
                    </div>
                </React.Fragment> 
            )
        }
        if(userTypes.length === 0 && errorMessage) {
          alert(errorMessage);
        }
        return ( 
            <React.Fragment>
              <TitlePanel
                {...this.props}
                title="User Types"
                icon="fas fa-user-tag"
                total={userTypes.length}
              />
              <Filter
                {...this.props}
                handleNewItem={this.handleSelectedEdit}
                sortField={sortField}
                handleSort={this.sortUserTypes}
                tableColumns={tableColumns}
              ></Filter>
              <Items
                items={userTypes}
                sortDirection={sortDirection}
                sortField={sortField}
                handleSort={this.sortUserTypes}
                handleSelectedEdit={this.handleSelectedEdit}
                handleDelete={async (row) => {
                  await this.setState({
                    deleteUserTypeId: row.id,
                    deleteUserTypeName: row.name,
                    deleteUserTypeDescription: row.description
                  });
                }}
                tableColumns={tableColumns}
              />
                
                <UserTypesModal modalId="createUserType" modalTitleId={ this.state.selectedUserTypeId === "" ? "Create User Type" : "Update User Type"}/>
                <LocalModal
                  modalName="confirmDelete"
                  title="Confirm Delete"
                  processing={this.state.processing}
                  icon="fas fa-trash-alt"
                  onConfirm={()=> {this.props.deleteUserType(this.state.deleteUserTypeId); 
                    this.setState({processing: true});} }
                  onClose={this.handleCloseAlert}
                  onConfirmText="Delete"
                >
                  <div key="userTypeDelete">
                    <div
                      className={
                        this.state.showUserTypeDeletedMessage ? '' : 'd-none'
                      }
                    >
                      <div className={this.state.alertClass} role="alert">
                        {this.state.userTypeDeletedMessage}
                        <button
                          type="button"
                          className="close"
                          onClick={this.handleCloseAlert}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                    <div className="text-center">
                      <div>Name: {this.state.deleteUserTypeName}</div>
                      <div>Description: {this.state.deleteUserTypeDescription}</div>
                    </div>
                  </div>
                </LocalModal>
            </React.Fragment>
         );
    }
}

const Filter = ({
  children,
  handleSort,
  sortField,
  handleNewItem,
  tableColumns,
}) => {
  return (
    <div className="container ">
      <div className="row filter-container">
        <div className="col-10 col-sm-10 col-md-3 col-lg-3 d-none">
          <div className="inner-addon right-addon">
            <i className="fa fa-search icon"></i>
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="col-md-10 col-lg-10 d-none d-md-block">{children}</div>
        <div className="col-12 col-md-2 filter-buttons-container">
          <AddNew handleNew={handleNewItem} dataTarget="#createUserType" />
          <Sort
            handleSort={handleSort}
            sortField={sortField}
            tableColumns={tableColumns}
          />
        </div>
      </div>
    </div>
  );
};

const Items = (props) => {
  return (
    <>
      <Cards {...props} />
      <LocalTable
        {...{
          ...props,
          rows: props.items,
          columns: props.tableColumns,
        }}
      />
    </>
  );
};

const Card = ({ id, name, description, handleSelectedEdit, handleDelete }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="col-12 col-lg-3 col-md-4 col-sm-6 col-exsm-12">
      <div className="inventory-card">
        <div>{name}</div>
        <div className="card-grey">{description}</div>
        {open && (
          <>
            <div className="action-buttons-card-container w-100 mt-1 d-flex justify-content-center">
              <ActionButton
                dataToggle="modal"
                dataTarget="#createUserType"
                handleAction={() => handleSelectedEdit({ target: { value: id}})}
                icon="fa fa-edit icon edit"
                iconSrc={edit}
                color=""
              />
              <ActionButton
                dataToggle="modal"
                dataTarget="#confirmDelete"
                handleAction={() => handleDelete({
                  id, name, description
                })}
                icon="fas fa-trash-alt"
                style={{ backgroundColor: '#FFC0C0' }}
              />
            </div>
          </>
        )}
        <div className="see-more-container">
          <div className="see-more" onClick={() => setOpen(!open)}>
            <i className={'fas fa-chevron-' + (open ? 'up' : 'down')} />
          </div>
        </div>
      </div>
    </div>
  );
}

const Cards = ({ items, handleSelectedEdit, handleDelete }) => {
  return (
    <div className="container d-md-none cards-container">
      <div className="row">
        {items.map((item) => (
          <Card key={'userType_' + item.name} {...item}
          handleSelectedEdit={handleSelectedEdit}
          handleDelete={handleDelete} />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
    return {
      userType: state.userTypesStore.userType,
      userTypes: state.userTypesStore.userTypes
    };
  };
  
  const mapDispatchToProps = dispatch => ({
    setUserTypes: (userTypes) => dispatch(setUserTypes(userTypes)),
    setSelectedUserType: (userTypeId) => dispatch(setSelectedUserType(userTypeId)),
    deleteUserType: (id) => dispatch(deleteUserType(id))
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserTypesMain);