import React, { Component, useState } from 'react';
import UsersPopUp from '../users/users-popup';
import { setUsers, setSelectedUser } from '../../actions/user-actions';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { setGeneralEnums } from '../../actions/general-actions';
import { generateString } from '../../utilities/string.utilities';
import AdminSection from './admin-section';

import {
  getUsers,
  getLocations,
  getRoles,
  getStatusTypes,
  getUserTypes,
} from '../../utilities/api.calls';
import { ActionButton, AddNew, LocalTable, Sort } from '../common';
import { TitlePanel } from '../common/LocalPanels';
import './styles.css';
import defaultImage from '../../assets/images/default-user.png';
class UsersMain extends Component {
  constructor(props) {
    super(props);
    this.showActiveUsers = this.showActiveUsers.bind(this);
    this.showAllUsers = this.showAllUsers.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.state = {
      usersWereLoaded: false,
      errorMessage: '',
      inventoryItems: [],
      users: [],
      user: {
        Id: 0,
        Firstname: '',
        Lastname: '',
        Email: '',
        Password: generateString(8),
      },
      selectedUserId: '0',
      activeUsersOnly: true,
      orderByFirstName: true,
      orderByLastName: true,
      orderByEmail: true,
      orderByRole: true,
      orderByType: true,
      orderByLocation: true,
      orderByStatus: true,
      buttons: [
        {
          id: 1,
          className: 'btn btn-sm btn-success mr-2',
          dataToggle: 'modal',
          dataTarget: '#createUser',
          title: 'Add User',
          onClick: this.handleSelectedEdit,
        },
        {
          id: 2,
          className: 'btn btn-sm btn-primary',
          onClick: this.showAllUsers.bind(this),
          title: 'Show All',
        },
      ],
      editMode: false,
      sortField: '',
      sortDirection: 'asc',
      tableColumns: [
        {
          position: 1,
          field: 'firstName',
          label: 'Users',
          sortable: true,
          customComponent: (row) => {
            return (
              <div className="d-flex">
                <img
                  className="profile-image"
                  src={
                    row !== undefined && row !== null && row.file
                      ? row.file.url
                      : defaultImage
                  }
                />
                <div>
                  <div>
                    {row !== undefined && row !== null
                      ? row.firstName + ' ' + row.lastName
                      : ''}
                  </div>
                  <div style={{ fontWeight: 'bold' }}>
                    {row !== undefined && row !== null ? row.email : ''}
                  </div>
                </div>
              </div>
            );
          },
        },

        {
          position: 4,
          className: 'd-none d-lg-table-cell',
          field: 'role',
          label: 'Role',
          sortable: true,
        },
        { position: 5, field: 'type', label: 'Type', sortable: true },
        {
          className: 'd-none d-lg-table-cell',
          position: 6,
          field: 'location',
          label: 'Location',
          sortable: true,
        },
        {
          position: 7,
          field: 'statusType',
          label: 'Status',
          sortable: true,
          customComponent: (row) => {
            return (
              <div className="d-flex justify-content-start">
                <div
                  className="status-dot"
                  style={{
                    position: 'relative',
                    left: '20px',
                    backgroundColor:
                      row.statusType === 'Active' ? '#73FF98' : '#FF6666',
                  }}
                ></div>
              </div>
            );
          },
        },
        {
          position: 9,
          field: '',
          label: 'Actions',
          sortable: false,
          type: 'button',
          customComponent: (row) => {
            return (
              <div className="button-container">
                <ActionButton
                  row={row}
                  handleAction={() => this.goToEdit(row)}
                  icon="fa fa-edit icon edit"
                  color=""
                />
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount = () => {
    this.getUsers();
    getLocations();
    getRoles();
    getStatusTypes();
    getUserTypes();
  };

  getUsers = async () => {
    await getUsers().then((response) => {
      if (response && response.success === true) {
        this.props.setUsers(response.users);
        this.setState({
          ...this.state,
          usersWereLoaded: true,
        });
      }
      if (response && response.success === false) {
        this.setState({
          ...this.state,
          usersWereLoaded: true,
          errorMessage: response.error,
        });
      }
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.users !== this.props.users ||
      prevProps.locations !== this.props.locations
    ) {
      if (typeof this.props.users !== 'undefined' && this.props.users) {
        const { locations, userRoles, userStatusTypes, userTypes } = this.props;
        if (
          typeof locations !== 'undefined' &&
          locations &&
          locations.length > 0 &&
          typeof userRoles !== 'undefined' &&
          userRoles &&
          userRoles.length > 0 &&
          typeof userStatusTypes !== 'undefined' &&
          userStatusTypes &&
          userStatusTypes.length > 0 &&
          typeof userTypes !== 'undefined' &&
          userTypes &&
          userTypes.length > 0
        ) {
          var locationsById = [];
          locations.forEach((location) => {
            locationsById[location.id] = location.name;
          });
          var userRolesById = [];
          userRoles.forEach((userRole) => {
            userRolesById[userRole.id] = userRole.name;
          });
          var userStatusTypesById = [];
          userStatusTypes.forEach((userStatusType) => {
            userStatusTypesById[userStatusType.id] = userStatusType.name;
          });
          var userTypesById = [];
          userTypes.forEach((userType) => {
            userTypesById[userType.id] = userType.name;
          });
          var users = this.props.users;
          users.forEach((user) => {
            if (user !== undefined && user !== null) {
              user['location'] =
                typeof locationsById[user.locationId] !== 'undefined'
                  ? locationsById[user.locationId]
                  : '';
              user['role'] =
                typeof userRolesById[user.userRoleId] !== 'undefined'
                  ? userRolesById[user.userRoleId]
                  : '';
              user['statusType'] =
                typeof userStatusTypesById[user.userStatusTypeId] !==
                'undefined'
                  ? userStatusTypesById[user.userStatusTypeId]
                  : '';
              user['type'] =
                typeof userTypesById[user.userTypeId] !== 'undefined'
                  ? userTypesById[user.userTypeId]
                  : '';
            }
          });
          this.setState({
            ...this.state,
            users: users,
          });
        } else {
          this.setState({
            ...this.state,
            users: this.props.users,
          });
        }
      }
    }
  };

  handleSelectedEdit = (event) => {
    this.setState({
      selectedUserId: event.target.value,
    });
  };

  sortUsers = (field, sortDirection) => {
    const asc = sortDirection === 'asc';
    let { users } = this.state;
    users = users.sort((a, b) =>
      a[field].toLowerCase() > b[field].toLowerCase()
        ? asc
          ? 1
          : -1
        : b[field].toLowerCase() > a[field].toLowerCase()
        ? asc
          ? -1
          : 1
        : 0
    );

    this.setState({
      users: users,
      sortField: field,
      sortDirection: sortDirection,
    });
  };

  showActiveUsers = async () => {
    let buttons = this.state.buttons;
    buttons[1]['onClick'] = this.showAllUsers.bind(this);
    buttons[1]['title'] = 'Show All';
    await this.setState({
      activeUsersOnly: true,
      buttons,
    });
  };

  showAllUsers = async () => {
    let buttons = this.state.buttons;
    buttons[1]['onClick'] = this.showActiveUsers.bind(this);
    buttons[1]['title'] = 'Show Active users only';
    await this.setState({
      activeUsersOnly: false,
      buttons,
    });
  };

  goToHome = () => {
    this.props.history.push('/app');
  };

  goToEdit = (user) => {
    this.setState({
      ...this.state,
      editMode: true,
      user,
    });
  };

  exitEditMode = async () => {
    await this.getUsers();
    this.setState({
      ...this.state,
      editMode: false,
    });
  };

  render() {
    const {
      users,
      editMode,
      usersWereLoaded,
      errorMessage,
      sortDirection,
      sortField,
      tableColumns,
    } = this.state;

    let { buttons } = this.state;

    if (!usersWereLoaded) {
      return (
        <React.Fragment>
          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <Spinner style={{ color: 'white' }} animation="border" />
          </div>
        </React.Fragment>
      );
    }
    if (users.length === 0 && errorMessage) {
      alert(errorMessage);
    }
    if (editMode === false) {
      return (
        <React.Fragment>
          <TitlePanel
            title="Users"
            history={this.props.history}
            total={users.length}
            icon="fas fa-users"
            onBack={() => {
              this.props.history.goBack();
            }}
          />
          <Filter
            {...this.props}
            handleNewItem={this.handleSelectedEdit}
            sortField={sortField}
            handleSort={this.sortUsers}
            tableColumns={tableColumns}
          ></Filter>

          <Items
            items={users}
            sortDirection={sortDirection}
            sortField={sortField}
            handleSort={this.sortUsers}
            tableColumns={tableColumns}
            goToEdit={this.goToEdit}
          />

          <UsersPopUp
            modalId="createUser"
            modalTitleId={
              this.state.selectedUserId === '' ? 'Create User' : 'Update User'
            }
          />
        </React.Fragment>
      );
    } else {
      return <AdminSection user={this.state.user} goBack={this.exitEditMode} />;
    }
  }
}

const Filter = ({
  children,
  handleSort,
  sortField,
  handleNewItem,
  tableColumns,
}) => {
  return (
    <div className="container ">
      <div className="row filter-container">
        <div className="col-10 col-sm-10 col-md-3 col-lg-3 d-none">
          <div className="inner-addon right-addon">
            <i className="fa fa-search icon"></i>
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="col-md-10 col-lg-10 d-none d-md-block">{children}</div>
        <div className="col-12 col-md-2 filter-buttons-container">
          {/* <ToggleButtonGroup
            orientation="vertical"
            value={'list'}
            exclusive
            name="list-type"
          >
            <ToggleButton value="list" aria-label="list">
              <AppsIcon />
            </ToggleButton>
            <ToggleButton value="module" aria-label="module">
              <ReorderIcon />
            </ToggleButton>
          </ToggleButtonGroup> */}
          <AddNew handleNew={handleNewItem} dataTarget="#createUser" />
          <Sort
            handleSort={handleSort}
            sortField={sortField}
            tableColumns={tableColumns}
          />
        </div>
      </div>
    </div>
  );
};

const Cards = ({ cards, goToEdit }) => {
  return (
    <div className="container d-md-none user-cards-container">
      <div className="row">
        {cards.map((card, index) => (
          <Card key={'card_' + index} {...card} 
          goToEdit={goToEdit} />
        ))}
      </div>
    </div>
  );
};

const Items = (props) => {
  return (
    <>
      <Cards {...{ ...props, cards: props.items }} />
      <LocalTable
        {...{
          ...props,
          rows: props.items,
          columns: props.tableColumns,
        }}
      />
    </>
  );
};

// const Card = ({ firstName, lastName, email, cellularNumber, role, file }) => {
//   const [open, setOpen] = useState(false);

//   return (
//     <div className="col-12 col-lg-3 col-md-4 col-sm-6 col-exsm-12">
//       <div className="user-card">
//         <div className="user-card-status"></div>
//         <div className="user-card-ellipsis">
//           <i className="fas fa-ellipsis-h"></i>
//         </div>
//         <img className="user-card-image" src={file ? file.url : defaultImage} />

//         <div className="user-card-name">{firstName + ' ' + lastName}</div>
//         <div className="user-card-ligth-grey">{cellularNumber}</div>
//         <div className="user-card-ligth-grey">{email}</div>
//         <br />
//         <div></div>
//         <div></div>
//         <div className="user-card-footer">
//           <div>{role}</div>
//         </div>
//       </div>
//     </div>
//   );
// };      

// const Card = ({
//   id,
//   codeId,
//   type,
//   brand,
//   model,
//   user,
//   locationName,
//   detail,
//   lastUpdate,
//   location,
//   handleSelectedEdit,
//   handleDelete,
// }) => {
  //const Card = ({ id,firstName, lastName, email, cellularNumber, role, file, handleSelectedEdit, goToEdit }) => {
  //console.log('handleSelectedEdit',handleSelectedEdit);
  const Card = ( card ) => {
  const {firstName, lastName, email, cellularNumber, role, file, goToEdit} = card;
  const [open, setOpen] = useState(false);
  return (
    <div className="col-12 col-lg-3 col-md-4 col-sm-6 col-exsm-12">
      <div className="inventory-card">
      <img className="user-card-image" src={file ? file.url : defaultImage} />

      <div className="user-card-name">{firstName + ' ' + lastName}</div>
      <div className="user-card-ligth-grey">{cellularNumber}</div>
      <div className="user-card-ligth-grey">{email}</div>
      <br />
      <div></div>
      <div></div>
      <div className="user-card-footer">
        <div>{role}</div>
      </div>
        {open && (
          <>
            {/* <div>
              <i className="fas fa-map-marker-alt location-icon" />
              <span className="card-font-size-min">{locationName}</span>
            </div> */}

            {/* <div className="card-grey card-font-size-min">{detail}</div> */}
            <div className="action-buttons-card-container w-100 mt-1 d-flex justify-content-center">
              <ActionButton style={{marginRight: '0px'}}
                handleAction={() => goToEdit(card)}
                icon="fa fa-edit icon edit"
                color=""
              />              
            </div>
          </>
        )}
        <div className="see-more-container">
          <div className="see-more" onClick={() => setOpen(!open)}>
            <i className={'fas fa-chevron-' + (open ? 'up' : 'down')} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.usersStore.users,
    locations: state.generalStore.locations,
    userRoles: state.generalStore.userRoles,
    userStatusTypes: state.generalStore.userStatusTypes,
    userTypes: state.generalStore.userTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUsers: (users) => dispatch(setUsers(users)),
  setSelectedUser: (userId) => dispatch(setSelectedUser(userId)),
  setGeneralEnums: (locations, userRoles, userStatusTypes, userTypes) =>
    dispatch(setGeneralEnums(locations, userRoles, userStatusTypes, userTypes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersMain);
