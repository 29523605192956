import userReducer from '../reducers/userReducer';
import inventoryReducer from '../reducers/inventoryReducer';
import generalReducer from '../reducers/generalReducer';
import clientReducer from '../reducers/clientReducer';
import projectReducer from '../reducers/projectReducer';
import locationReducer from '../reducers/locationReducer';
import roleReducer from '../reducers/roleReducer';
import userTypeReducer from './userTypeReducer';
import {combineReducers} from 'redux';

const rootReducer = combineReducers({
    
    usersStore: userReducer,
    inventoryStore: inventoryReducer,
    generalStore: generalReducer,
    clientsStore: clientReducer,
    projectsStore: projectReducer,
    locationsStore: locationReducer,
    rolesStore: roleReducer,
    userTypesStore: userTypeReducer
});

export default rootReducer;
