import React from 'react'

const ReadText = (props) => {
    return (
        <React.Fragment>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}> <strong>{props.labelText}</strong></div>
                <div>{props.inputText}</div>
            </div>
        </React.Fragment>
    )
}

export default ReadText;
