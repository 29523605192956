import { Services } from '../api/services';
import { PRINT_IN_CONSOLE } from '../components/shared/constants/constants';
import LogRocket from 'logrocket';

export const setUsers = (users) => {
  return (dispatch, getState) => {
    Services.Users.getUsers()
      .then((data) => {
        if (data.val() !== null) {
          users = [];
          const response = data.val();
          for (var key in response) {
            users.push(response[key]);
          }
          dispatch({
            type: 'SET_USERS',
            users: users.sort((a, b) =>
              String(a.firstName).toLowerCase() >
              String(b.firstName).toLowerCase()
                ? 1
                : String(b.firstName).toLowerCase() >
                  String(a.firstName).toLowerCase()
                ? -1
                : 0
            ),
          });
        } else {
          users = [];
          dispatch({ type: '', users });
        }
      })
      .catch((error) => {
        alert('Error while getting the users!');
      });
  };
};

export const getUser = (userId) => {
  return (dispatch, getState) => {
    const users = getState().usersStore.users;
    Services.Users.getUser(userId)
      .then((data) => {
        if (
          typeof data !== 'undefined' &&
          data !== null &&
          typeof data.val() !== 'undefined' &&
          data.val() !== null
        ) {
          dispatch({
            type: 'SET_UPDATED_USER',
            payload: { users, user: data.val() },
          });
        } else {
          console.log('error when getting user');
        }
      })
      .catch((error) => {
        console.log('error when getting user', error);
      });
  };
};

export const setUser = (users, user) => {
  return (dispatch, getState) => {
    const storedUsers = getState().usersStore.users;
    users =
      typeof storedUsers !== 'undefined' && storedUsers
        ? storedUsers
        : typeof users !== 'undefined' && users
        ? users
        : [];
    if((typeof user) !== 'undefined' && user !== null)
        logRocketSetUser(user);
    dispatch({ type: 'SET_UPDATED_USER', payload: { users, user } });
  };
};

const logRocketSetUser = (user) => {
  if (user) {
    // This is an example script - don't forget to change it!
    LogRocket.identify(user.id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,

      // Add your own custom user variables here, ie:
      subscriptionType: 'pro',
    });
  }
};

export const changePassword = (email, oldPassword, newPassword) => {
  return (dispatch, getState) => {
    Services.Users.changePassword(email, oldPassword, newPassword)
      .then((response) => {
        response['step'] = 0;
        dispatch({ type: 'REGISTER_FORM', registerFormResult: response });
      })
      .catch((error) => {
        if (PRINT_IN_CONSOLE) {
          console.log('Error changing password:', error.message);
        }
        dispatch({
          type: 'REGISTER_FORM',
          registerFormResult: {
            message: 'Error when trying to change the password. Please re try.',
            success: false,
            step: 0,
          },
        });
      });
  };
};

export const createNewUser = (
  email,
  firstName,
  lastName,
  password,
  userRoleId,
  userTypeId,
  locationId,
  userStatusTypeId,
  users
) => {
  return (dispatch, getState) => {
    Services.Users.createNewUser(
      email,
      firstName,
      lastName,
      password,
      userRoleId,
      userTypeId,
      locationId,
      userStatusTypeId,
      users
    )
      .then((data) => {
        var user = {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          userRoleId,
          userTypeId,
          locationId,
          userStatusTypeId,
          //password
        };
        if (data.userAdded) {
          users.push(user);
          user['userAdded'] = data.userAdded;
          user['userAddedMessage'] = data.userAddedMessage;
          dispatch({ type: 'SET_NEW_USER', payload: { users, user } });
        } else {
          user['userAdded'] = false;
          user['userAddedMessage'] = data.userAddedMessage;
          dispatch({ type: 'SET_NEW_USER_ERROR', user });
        }
      })
      .catch((error) => {
        var user = {};
        user['userAdded'] = false;
        user['userAddedMessage'] = 'Error when creating the new user';
        dispatch({ type: 'SET_NEW_USER_ERROR', user });
      });
  };
};

export const updateUser = (
  userId,
  email,
  firstName,
  lastName,
  userRoleId,
  userTypeId,
  locationId,
  userStatusTypeId,
  users
) => {
  return (dispatch, getState) => {
    Services.Users.updateUser(
      userId,
      firstName,
      lastName,
      userRoleId,
      userTypeId,
      locationId,
      userStatusTypeId,
      users
    )
      .then((data) => {
        var user = {
          id: userId,
          firstName,
          lastName,
          email,
          userRoleId,
          userTypeId,
          locationId,
          userStatusTypeId,
          //password
        };
        if (data.userUpdated) {
          users.forEach((userData) => {
            if (userData.id === user.id) {
              userData.email = user.email;
              userData.firstName = user.firstName;
              userData.lastName = user.lastName;
              userData.locationId = user.locationId ? user.locationId : 4;
              userData.userRoleId = user.userRoleId ? user.userRoleId : 1;
              userData.userTypeId = user.userTypeId ? user.userTypeId : 2;
              userData.userStatusTypeId = user.userStatusTypeId
                ? user.userStatusTypeId
                : 1;
            }
          });
          //users.push(user);
          user['userUpdated'] = data.userUpdated;
          user['userUpdatedMessage'] = data.userUpdatedMessage;
          dispatch({ type: 'SET_UPDATED_USER', payload: { users, user } });
        } else {
          user['userUpdated'] = false;
          user['userUpdatedMessage'] = data.userUpdatedMessage;
          dispatch({ type: 'SET_UPDATED_USER_ERROR', user });
        }
      })
      .catch((error) => {
        var user = {};
        user['userUpdated'] = false;
        user['userUpdatedMessage'] = 'Error when updating user';
        dispatch({ type: 'SET_UPDATED_USER_ERROR', user });
      });
  };
};

export const updateUsersInStore = (users) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_USERS', users });
  };
};

export const updateProfile = (user, uploadImage, file, oldFile) => {
  return (dispatch, getState) => {
    Services.Users.updateProfile(user, uploadImage, file, oldFile)
      .then((data) => {
        if (typeof data !== 'undefined' && data !== null) {
          if (data.userUpdated) {
            data.users.forEach((userData) => {
              if (userData.id === data.id) {
                userData.email = data.email;
                userData.firstName = data.firstName;
                userData.lastName = data.lastName;
                userData.locationId = user.locationId ? user.locationId : 1;
                if (data.uploadImage) {
                  userData['file'] = data.file;
                }
              }
            });
            //data.users.push(user);
            user['userUpdated'] = data.userUpdated;
            user['userUpdatedMessage'] = data.userUpdatedMessage;
            if (data.uploadImage) {
              user['uploadImage'] = data.uploadImage;
              user['file'] = data.file;
            }
            const users = data.users;
            dispatch({ type: 'SET_UPDATED_USER', payload: { users, user } });
          } else {
            user['userUpdated'] = false;
            user['userUpdatedMessage'] = data.userUpdatedMessage;
            dispatch({ type: 'SET_UPDATED_USER_ERROR', user });
          }
        } else {
          user['userUpdated'] = false;
          user['userUpdatedMessage'] = 'Error when updating the user profile';
          dispatch({ type: 'SET_UPDATED_USER_ERROR', user });
        }
      })
      .catch((error) => {
        user['userUpdated'] = false;
        user['userUpdatedMessage'] = 'Error when updating the user profile';
        dispatch({ type: 'SET_UPDATED_USER_ERROR', user });
      });
  };
};

export const setBankData = (bankData) => {
  return (dispatch, getState) => {
    dispatch({ type: 'BANK_DATA', bankData });
  };
};

const getUserBankDataFromAPI = (userId) => {
  //const userBankInfo = await Services.Users.getBankData(bankData.userId).then(response => {
  return Services.Users.getUserBankData(userId)
    .then((response) => {
      return response.val();
    })
    .catch((error) => {
      console.log('error in getUserBankDataFromAPI', error);
      return null;
    });
  //return userBankInfo;
};

const getBankDataFromAPI = (userId) => {
  return Services.Users.getUserBankData(userId)
    .then((response) => {
      return response.val();
    })
    .catch((error) => {
      console.log('error in getBankDataFromAPI', error);
      return null;
    });
};

export const getUserBankData = (userId) => {
  return (dispatch, getState) => {
    getUserBankDataFromAPI(userId)
      .then((userBankInfo) => {
        dispatch({ type: 'USER_BANK_DATA', userBankInfo });
      })
      .catch((error) => {
        const registerFormResult = {
          success: false,
          message: 'Error getting user bank data',
        };
        dispatch({ type: 'REGISTER_FORM', registerFormResult });
      });
  };
};

export const getBankData = (bankData) => {
  return getBankDataFromAPI(bankData.userId).then((userBankInfo) => {
    let lastId = 0;
    let userBankData = null;
    if (typeof userBankInfo !== 'undefined' && userBankInfo !== null) {
      for (var key in userBankInfo) {
        lastId = userBankInfo[key].id;
        if (
          typeof bankData.id !== 'undefined' &&
          bankData.id !== null &&
          userBankInfo[key].id === bankData.id
        ) {
          userBankData = userBankInfo[key];
        }
      }
    }

    return { lastId, userBankData };
  });
};

export const saveBankData = (bankData) => {
  return (dispatch, getState) => {
    getBankData(bankData)
      .then((bankInfo) => {
        //let response = null;
        let registerFormResult;
        if (
          typeof bankInfo.userBankData !== 'undefined' &&
          bankInfo.userBankData !== null
        ) {
          Services.Users.updateBankData(bankData)
            .then((updateResponse) => {
              registerFormResult = {
                success: true,
                message: 'The information was successfully updated',
                step: 2,
                bankData,
              };
              dispatch({ type: 'REGISTER_FORM', registerFormResult });
            })
            .catch((error) => {
              console.log('error updating bankData', error);
              registerFormResult = {
                success: false,
                message:
                  'An error ocurred when trying to update the information. Please try again later',
                step: 2,
              };
              dispatch({ type: 'REGISTER_FORM', registerFormResult });
            });
        } else {
          bankData['id'] = bankInfo.lastId + 1;
          Services.Users.postBankData(bankData)
            .then(() => {
              registerFormResult = {
                success: true,
                message: 'The information was successfully saved',
                step: 2,
                bankData,
              };
              dispatch({ type: 'REGISTER_FORM', registerFormResult });
            })
            .catch((error) => {
              console.log('error saving bankData', error);
              registerFormResult = {
                success: false,
                message:
                  'An error ocurred when trying to save the information. Please try again later',
                step: 2,
              };
              dispatch({ type: 'REGISTER_FORM', registerFormResult });
            });
        }
      })
      .catch((error) => {
        console.log('error getting bankData', error);
        const registerFormResult = {
          success: false,
          message:
            'An error ocurred when trying to save the information. Please try again later',
          step: 2,
        };
        dispatch({ type: 'REGISTER_FORM', registerFormResult });
      });
  };
};

const getSpecificFormDataFromAPI = (data) => {
  return Services.Users.getUserSpecificFormData(data);
};

export const getSpecificFormData = (userId) => {
  return (dispatch, getState) => {
    getSpecificFormDataFromAPI(userId)
      .then((specificFormData) => {
        specificFormData = specificFormData.val();
        specificFormData['success'] = true;
        dispatch({ type: 'USER_SPECIFIC_FORM_DATA', specificFormData });
      })
      .catch((error) => {
        console.log('error getting specific form data', error);
        const specificFormData = {
          success: false,
          message: 'Error getting specific form data',
        };
        dispatch({ type: 'REGISTER_FORM', specificFormData });
      });
  };
};

export const saveSpecificFormData = (data) => {
  return (dispatch, getState) => {
    Services.Users.getUserSpecificFormData(data.userId)
      .then((specificFormData) => {
        let registerFormResult;
        if (
          typeof specificFormData.val() !== 'undefined' &&
          specificFormData.val() !== null
        ) {
          Services.Users.updateUserSpecificFormData(data)
            .then(() => {
              Services.Users.setRegisterFormCompleted(data.userId)
                .then(() => {
                  registerFormResult = {
                    success: true,
                    message: 'The information was successfully updated',
                    step: 3,
                    specificFormData: data,
                  };
                  dispatch({ type: 'REGISTER_FORM', registerFormResult });
                })
                .catch((error) => {
                  console.log('error updating specific form data', error);
                  registerFormResult = {
                    success: false,
                    message:
                      'An error ocurred when trying to update the information. Please try again later',
                    step: 3,
                  };
                  dispatch({ type: 'REGISTER_FORM', registerFormResult });
                });
            })
            .catch((error) => {
              console.log('error updating specific form data', error);
              registerFormResult = {
                success: false,
                message:
                  'An error ocurred when trying to update the information. Please try again later',
                step: 3,
              };
              dispatch({ type: 'REGISTER_FORM', registerFormResult });
            });
        } else {
          Services.Users.postUserSpecificFormData(data)
            .then(() => {
              Services.Users.setRegisterFormCompleted(data.userId)
                .then(() => {
                  registerFormResult = {
                    success: true,
                    message: 'The information was successfully saved',
                    step: 3,
                    specificFormData: data,
                  };
                  dispatch({ type: 'REGISTER_FORM', registerFormResult });
                })
                .catch((error) => {
                  console.log('error saving specific form data', error);
                  registerFormResult = {
                    success: false,
                    message:
                      'An error ocurred when trying to save the information. Please try again later',
                    step: 3,
                  };
                  dispatch({ type: 'REGISTER_FORM', registerFormResult });
                });
            })
            .catch((error) => {
              console.log('error saving specific form data', error);
              registerFormResult = {
                success: false,
                message:
                  'An error ocurred when trying to save the information. Please try again later',
                step: 3,
              };
              dispatch({ type: 'REGISTER_FORM', registerFormResult });
            });
        }
      })
      .catch((error) => {
        console.log('error getting specific form data', error);
        const registerFormResult = {
          success: false,
          message:
            'An error ocurred when trying to save the information. Please try again later',
          step: 3,
        };
        dispatch({ type: 'REGISTER_FORM', registerFormResult });
      });
  };
};

export const setRegisterFormCompleted = (userId) => {
  return (dispatch, getState) => {
    Services.Users.setRegisterFormCompleted(userId).then((data) => {
      dispatch({ type: 'SET_REGISTER_FORM_COMPLETED', data });
    });
  };
};

export const downloadImage = (fileUrl) => {
  return (dispatch, getState) => {
    Services.Users.downloadImage(fileUrl).then((data) => {
      dispatch({ type: 'SET_DOWNLOAD_LINK', profileImage: data });
    });
  };
};

export const setProfileImage = (imageUrl) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_DOWNLOAD_LINK', profileImage: imageUrl });
  };
};

export const setSelectedUser = (selectedUserId) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_USER_ID', selectedUserId });
  };
};

export const sendResetPasswordLink = (email) => {
  return (dispatch, getState) => {
    Services.Users.sendResetPasswordLink(email)
      .then((data) => {
        if (data) {
          const result = {
            email,
            linkSent: data.linkSent,
            message: data.message,
          };
          dispatch({ type: 'SEND_PASSWORD_RESET_LINK', result });
        }
      })
      .catch((error) => {
        const result = {
          email,
          linkSent: false,
          message: 'The link could not be sent',
        };
        dispatch({ type: 'SEND_PASSWORD_RESET_LINK', result });
      });
  };
};

export const resetPassword = (actionCode, newPassword) => {
  return (dispatch, getState) => {
    let result = {
      success: false,
    };
    Services.Users.resetPassword(actionCode, newPassword)
      .then((data) => {
        if (data.success) {
          result.success = data.success;
          dispatch({ type: 'PASSWORD_RESET_SUCCESS', result });
        } else {
          dispatch({ type: 'PASSWORD_RESET_ERROR', result });
        }
      })
      .catch((error) => {
        dispatch({ type: 'PASSWORD_RESET_ERROR', result });
      });
  };
};

export const verifyEmail = (authCode) => {
  return (dispatch, getState) => {
    let result = {
      success: false,
      message:
        'There was an error trying to verify your email. Please try again later.',
    };
    Services.Users.verifyEmail(authCode)
      .then((data) => {
        if (typeof data !== 'undefined' && data !== null) {
          result.success = data;
          result.message = 'The email was verified successfully';
          dispatch({ type: 'AUTH_ACTION', result });
        } else {
          dispatch({ type: 'AUTH_ACTION', result });
        }
      })
      .catch((error) => {
        dispatch({ type: 'AUTH_ACTION', result });
      });
  };
};

export const recoverEmail = (authCode) => {
  return (dispatch, getState) => {
    let result = {
      success: false,
      message:
        'There was an error trying to recover your email. Please try again later.',
    };
    Services.Users.recoverEmail(authCode)
      .then((data) => {
        if (typeof data !== 'undefined' && data !== null) {
          result.success = data;
          result.message = 'The email was recovered successfully';
          dispatch({ type: 'AUTH_ACTION', result });
        } else {
          dispatch({ type: 'AUTH_ACTION', result });
        }
      })
      .catch((error) => {
        dispatch({ type: 'AUTH_ACTION', result });
      });
  };
};
