import { Services } from '../../../api/services';
import { isTextValid } from '../../../utilities/string.utilities';
import { isDependent, isIndependent } from '../../../utilities/utilities';

export const updateUserWithData = (user, data) => {
    //let { user } = this.state;
    if(data.id && data.id > 0) user.id = data.id;
    user.firstName = data.firstName;
    user.lastName = data.lastName;
    user.birthDate = data.birthDate;
    user.calendarBirthDate = data.calendarBirthDate;
    user.homeAddress = data.homeAddress;
    user.cellularNumber = data.cellularNumber;
    user.personalEmail = data.personalEmail;
    user.identificationType = data.identificationType;
    user.identificationNumber = data.identificationNumber;
    user.nationality = data.nationality;
    return user;
}

export const getBankData = async (bankData) => {
    const userBankInfo = await Services.Users.getUserBankData(bankData.userId).then(response => {
        return response.val();
    }).catch(error => {
        console.log('error in getBankDataFromAPI', error);
        return null;
    });
    let lastId = 0;
    let userBankData = null;
    if((typeof userBankInfo) !== 'undefined' && userBankInfo !== null) {
        for (var key in userBankInfo) {
            lastId = userBankInfo[key].id;
            if((typeof bankData.id) !== 'undefined' && bankData.id !== null && userBankInfo[key].id === bankData.id) {
                userBankData = userBankInfo[key];
            }
        }
    }
    
    return { lastId, userBankData };
    // return getBankDataFromAPI(bankData.userId).then(userBankInfo => {
    //     let lastId = 0;
    //     let userBankData = null;
    //     if((typeof userBankInfo) !== 'undefined' && userBankInfo !== null) {
    //         for (var key in userBankInfo) {
    //             lastId = userBankInfo[key].id;
    //             if((typeof bankData.id) !== 'undefined' && bankData.id !== null && userBankInfo[key].id === bankData.id) {
    //                 userBankData = userBankInfo[key];
    //             }
    //         }
    //     }
        
    //     return { lastId, userBankData };
    // })
}

export const getUserType = async (userId) => {
    let userType;
    const user = await Services.Users.getUser(userId).then(response => {
        return response.val();
    }).catch(_ => undefined);
    if(user) {
        const userTypes = await Services.UserTypes.getUserTypes().then(response => {
            return response.val();
        }).catch(_ => {
            return [];
        }); 
        console.log(userTypes);
        if(getPropertyValue(userTypes) !== "") { //" && userTypes.length > 0
            Object.values(userTypes).forEach(ut=> {
                if((typeof ut) !== 'undefined' && ut !== null && user.userTypeId.toString() === ut.id.toString()) {
                    userType = ut;
                }
            })
        }
    }
    return userType;
}

export const getUserTypeByTypeId = async (userTypeId) => {
    let userType;
        
    const userTypes = await Services.UserTypes.getUserTypes().then(response => {
        return response.val();
    }).catch(_ => {
        return [];
    }); 
    console.log(userTypes);
    if(getPropertyValue(userTypes) !== "") { //" && userTypes.length > 0
        Object.values(userTypes).forEach(ut=> {
            if((typeof ut) !== 'undefined' && ut !== null && userTypeId.toString() === ut.id.toString()) {
                userType = ut;
            }
        })
    }    
    return userType;
}

export const getSpecificData = (specificData, userTypeId) => {
    let data = {};
    //const userTypeId = this.state.user.userTypeId;
    console.log('getSpecificData', specificData);
    //isDependent, isIndependent, isAdministrator, isAnExternalDeveloper
    if(isDependent(userTypeId) === true) {
        if(specificData.file) {
            data.fileName = getPropertyValue(specificData.file) === "" && isTextValid(specificData.file.fileName) === "" ? "" : specificData.file.fileName;
            data.originalName = data.fileName;
            data.url = getPropertyValue(specificData.file) === "" && isTextValid(specificData.file.url) === "" ? "" : specificData.file.url;
        }
        
    } else {
        if(isIndependent(userTypeId) === true) {
            //parse independent info here
            data = specificData;
            if(data.bpsCertificate) {
                data.bpsCertificate.originalName = data.bpsCertificate.fileName;
            }
            if(data.dgiCertificate) {
                data.dgiCertificate.originalName = data.dgiCertificate.fileName;
            }
        } else {
            console.log('user is not dependent or independent');
        }
    }
    return data;
}

export const getPropertyValue = (value) => {
    if((typeof value) !== 'undefined' && value !== null) return value;
    return "";
}