import firebase from 'firebase/app';
import "firebase/database";
import { PRINT_IN_CONSOLE } from '../../components/shared/constants/constants'

export class ProjectsApi {
    setProjects() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`Projects`)
            .once("value")
            .then((projects) => {
                resolve(projects);
            });    
        });
    }

    getProjects() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`Projects`)
            .once("value")
            .then((projects) => {
                resolve(projects);
            });    
        });
    }

    isProjectValid(name, clientId, projectId) {
        return new Promise((resolve, reject) => {
            let projectValid = true;
            this.getProjects()
                .then((projects) => {
                    let projectsResponse = projects.val();
                    for (let key in projectsResponse) {
                        if(projectsResponse[key]['clientId'].toString() === clientId.toString() 
                        && projectsResponse[key]['name'].toString().toLowerCase().trim() 
                        === name.toString().toLowerCase().trim()) {
                            if((typeof projectId) === 'undefined') {
                                projectValid = false;
                            } else if((typeof projectId) !== 'undefined' && projectId.toString() 
                                    !== projectsResponse[key]['id'].toString()) {
                                projectValid = false;
                            }
                        }
                    }
                    resolve(projectValid);
                })
        });
    }

    createNewProject(name, clientId, statusId, projects) {
        return new Promise((resolve, reject) => {
            this.isProjectValid(name, clientId)
            .then(projectValid => {
                if(projectValid) {
                    const projectKey = firebase
                                    .database()
                                    .ref(`Projects`)
                                    .push({
                                        name,
                                        clientId,
                                        statusId
                                    }).getKey();

                    firebase.database()
                        .ref(`Projects/${projectKey}`)
                        .update({ id: projectKey })
                        .then(()=> { 
                            resolve({
                                id: projectKey,
                                name,
                                statusId,
                                projects,
                                projectAdded: true,
                                projectUpdated: false,
                                projectAddedMessage: 'Successfully created new project:' + name
                            }); 
                        })
                        .catch((error) => {
                            if(PRINT_IN_CONSOLE) {
                                console.log('Error creating project:', error.message);
                            }
                            resolve({
                                projectAdded: false,
                                projectUpdated: false,
                                projectAddedMessage: 'Error creating project'
                            }); 
                        });
                } else {
                    resolve({
                        projectAdded: false,
                        projectUpdated: false,
                        projectAddedMessage: 'Error creating project: already exist a project with this name for this client'
                    }); 
                }
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error creating project:', error.message);
                }
                resolve({
                    projectAdded: false,
                    projectUpdated: false,
                    projectAddedMessage: 'Error creating project: error when validating project name'
                });
            });
            
        });
    }

    updateProject(projectId, name, clientId, statusId, projects, capacities, statusName) {
        return new Promise((resolve, reject) => {
            this.isProjectValid(name, clientId, projectId)
            .then(projectValid => {
                if(projectValid) {
                    firebase.database()
                    .ref(`Projects/${projectId}`)
                    .update({ 
                        name,
                        clientId,
                        statusId
                    })
                    .then(()=> {
                        let capacitiesUpdated = 0;
                        if(capacities.length > 0) {
                            capacities.forEach((capacity) => {
                                firebase.database().ref(`UserProjects/${capacity.userId}/${capacity.id}`)
                                .update({ 
                                    status: statusName
                                })
                                .then(()=> { 
                                    capacitiesUpdated++;
                                    if(capacitiesUpdated === capacities.length) {
                                        resolve({
                                            id: projectId,
                                            name,
                                            statusId,
                                            clientId, 
                                            projects,
                                            projectAdded: false,
                                            projectUpdated: true,
                                            projectUpdatedMessage: 'Successfully updated the project'
                                            
                                        });
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                    resolve({
                                        id: projectId,
                                        name,
                                        statusId,
                                        clientId, 
                                        projects,
                                        projectAdded: false,
                                        projectUpdated: false,
                                        projectUpdatedMessage: 'Error when updating the project'
                                    });
                                });
                            });
                        } else {
                            resolve({
                                id: projectId,
                                name,
                                statusId,
                                clientId, 
                                projects,
                                projectAdded: false,
                                projectUpdated: true,
                                projectUpdatedMessage: 'Successfully updated the project'
                            });
                        }
                    })
                    .catch((error) => {
                        if(PRINT_IN_CONSOLE) {
                            console.log('Error updating the project:', error.message);
                        }
                        resolve({
                            projectAdded: false,
                            projectUpdated: false,
                            projectAddedMessage: 'Error updating the project'
                        }); 
                    });
                } else {
                    resolve({
                        projectAdded: false,
                        projectUpdated: false,
                        projectAddedMessage: 'Error updating the project: already exist a project with this name for this client'
                    }); 
                }
            })
            .catch((error) => {
                resolve({
                    projectAdded: false,
                    projectUpdated: false,
                    projectAddedMessage: 'Error updating the project: error when validating project name'
                }); 
            });
        });
    }
}
