const initialState = {
    users: [],
    selectedUserId: undefined,
    passwordReset: undefined,
    profileImage: undefined
  };

const userReducer = (state = initialState, action) => {
    
    switch(action.type)
    {
        case 'SET_USERS':
            return  {
                ...state,
                users: action.users
            }

        case 'SET_USER_ID':
            return {
                ...state,
                selectedUserId: action.selectedUserId
            }

        case 'SET_NEW_USER':
            return  {
                ...state,
                user: action.payload.user,
                users: action.payload.users
            }
        case 'SET_NEW_USER_ERROR':
            return  {
                ...state,
                user: action.user
            }
        case 'SET_UPDATED_USER':
            return  {
                ...state,
                user: action.payload.user,
                users: action.payload.users
            }
        case 'SET_UPDATED_USER_ERROR':
            return  {
                ...state,
                user: action.user
            }
        case 'SEND_PASSWORD_RESET_LINK':
            return {
                ...state,
                passwordReset: action.result
            }
        case 'PASSWORD_RESET_SUCCESS':
            return {
                ...state,
                passwordResetSuccess: action.result.success
            }
        case 'PASSWORD_RESET_ERROR':
            return {
                ...state,
                passwordResetSuccess: action.result.success
            }
        case 'SET_DOWNLOAD_LINK':
            return {
                ...state,
                profileImage: action.profileImage
            }
        case 'AUTH_ACTION': 
            return {
                ...state,
                authResult: action.result
            }
        case 'REGISTER_FORM':
            return {
                ...state,
                registerFormResult: action.registerFormResult
            }
        case 'USER_BANK_DATA': 
            return {
                ...state,
                userBankData: action.userBankInfo 
            }
        case 'BANK_DATA': 
            return {
                ...state,
                bankData: action.bankData
            }
        case 'USER_SPECIFIC_FORM_DATA':
            return {
                ...state,
                userSpecificFormData: action.specificFormData
            }
        default:
            return state;
    }

}

export default userReducer;