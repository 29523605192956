const initialState = {
    client: {},
    clients: [],
    selectedClientId: ""
};

const clientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CLIENTS":
            return {
                ...state,
                clients: action.clients
            };
        case "CREATE_NEW_CLIENT":
            return  {
                ...state,
                client: action.payload.client,
                clients: action.payload.clients
            };
        case 'CREATE_NEW_CLIENT_ERROR':
            return  {
                ...state,
                client: action.client
            };
        case "UPDATE_CLIENT":
            return  {
                ...state,
                client: action.payload.client,
                clients: action.payload.clients
            };
        case 'SET_CLIENT_ID':
            return {
                ...state,
                selectedClientId: action.selectedClientId
            };
        default:
          return state;
      }
    };
    
export default clientsReducer;