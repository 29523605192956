import { Services } from "../api/services";

export const setUserTypes = userTypes => {
  return (dispatch, getState) => {
    Services.UserTypes.getUserTypes()
      .then(data => {
        if (data.val() !== null) {
          userTypes = [];
          const response = data.val();
          for (var key in response) {
            userTypes.push(response[key]);
          }
          dispatch({
            type: "SET_USER_TYPES",
            userTypes: userTypes.sort((a, b) =>
              String(a.name).toLowerCase() > String(b.name).toLowerCase()
                ? 1
                : String(b.name).toLowerCase() > String(a.name).toLowerCase()
                ? -1
                : 0
            )
          });
        } else {
          userTypes = [];
          dispatch({ type: "", userTypes });
        }
      })
      .catch(error => {
        alert("Error while getting the user types!");
      });
  };
};

export const setSelectedUserType = selectedUserTypeId => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_USER_TYPE_ID", selectedUserTypeId });
  };
};

export const createNewUserType = (name, description, userTypes) => {
  return (dispatch, getState) => {
    Services.UserTypes.createNewUserType(name, description)
      .then(data => {
        let userType = {};
        if (data) {
          userType = {
            id: data.id,
            name: data.name,
            description: data.description
          };
          if (data.userTypeAdded) {
            userTypes.push(userType);
            userType["userTypeAdded"] = data.userTypeAdded;
            userType["userTypeAddedMessage"] = data.userTypeAddedMessage;
            dispatch({
              type: "SET_NEW_USER_TYPE",
              payload: { userTypes, userType }
            });
          }
        } else {
          userType["userTypeAdded"] = false;
          userType["userTypeAddedMessage"] =
            "Error when creating the new user type";
          dispatch({ type: "SET_NEW_USER_TYPE_ERROR", userType });
        }
      })
      .catch(error => {
        let userType = {};
        userType["userTypeAdded"] = false;
        userType["userTypeAddedMessage"] =
          "Error when creating the new user type";
        dispatch({ type: "SET_NEW_USER_TYPE_ERROR", userType });
      });
  };
};
export const updateUserType = (id, name, description, userTypes) => {
  return (dispatch, getState) => {
    Services.UserTypes.updateUserType(id, name, description)
      .then(data => {
        let userType = {};
        if (data) {
          userType = {
            id: data.id,
            name: data.name,
            description: data.description
          };
          if (data.userTypeUpdated) {
            userTypes.forEach(usrType => {
              if (usrType.id === data.id) {
                usrType.name = data.name;
                usrType.description = data.description;
              }
            });
            userType["userTypeUpdated"] = data.userTypeUpdated;
            userType["userTypeUpdatedMessage"] = data.userTypeUpdatedMessage;
            dispatch({
              type: "SET_UPDATED_USER_TYPE",
              payload: { userTypes, userType }
            });
          }
        } else {
          userType["userTypeUpdated"] = false;
          userType["userTypeUpdatedMessage"] =
            "Error when updating the user type";
          dispatch({ type: "SET_UPDATED_USER_TYPE_ERROR", userType });
        }
      })
      .catch(error => {
        let userType = {};
        userType["userTypeUpdated"] = false;
        userType["userTypeUpdatedMessage"] =
          "Error when updating the user type";
        dispatch({ type: "SET_UPDATED_USER_TYPE_ERROR", userType });
      });
  };
};

export const deleteUserType = id => {
  return (dispatch, getState) => {
    Services.UserTypes.deleteUserType(id).then(data => {
      if (data !== null) {
        if (typeof data.id !== "undefined" && data.id !== null) {
          let userType = {
            id: data.id,
            userTypeDeleted: true,
            userTypeDeletedMessage: "Successfully deleted user type"
          };
          dispatch({ type: "REMOVE_USER_TYPE", userType });
        } else {
          if (typeof data.message !== "undefined" && data.message) {
            let userType = {
              userTypeDeleted: false,
              userTypeDeletedMessage: data.message
            };
            //alert(data.message);
            dispatch({ type: "REMOVE_USER_TYPE_ERROR_MESSAGE", userType });
          }
        }
      }
    });
  };
};

export const updateTypesInStore = userTypes => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_USER_STATUS_TYPES", userTypes });
  };
};
