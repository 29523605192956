import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import './styles.css';

const LocalSelect = ({ children, value, options, onChange, error, label }) => (
  <div className="form-group modal-select-container">
    <span className="modal-label" htmlFor="exampleInputEmail1">
      {label}
    </span>

    <FormControl variant="outlined">
      <Select
        className="modal-select"
        native
        value={''}
        IconComponent={ExpandMore}
        value={value}
        onChange={onChange}
      >
        {options ? (
          <>
            <option aria-label="None" value="" />
            {options.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </>
        ) : (
          children
        )}
      </Select>
    </FormControl>

    {error && <p style={{ color: 'red' }}>{error}</p>}
  </div>
);

const AddNew = ({ handleNew, dataTarget }) => {
  return (
    <>
      <button
        data-toggle="modal"
        data-target={dataTarget}
        onClick={handleNew}
        className="add-new-button d-none d-md-block"
      >
        Add New
      </button>
      <button
        data-toggle="modal"
        data-target={dataTarget}
        onClick={handleNew}
        className="add-new-button-fixed d-block d-md-none"
      >
        <i className="fas fa-plus add-new-button-icon" />
      </button>
    </>
  );
};

const Sort = ({ sortField = '', handleSort, tableColumns }) => {
  const [direction, setDirection] = React.useState(true);

  const handleChangeDirection = (value) => {
    setDirection(value);
    sortField != '' && handleSort(sortField, value ? 'asc' : 'desc');
  };

  return (
    <>
      <FormControlLabel
        labelPlacement="start"
        className="label d-inline-flex d-md-none"
        control={
          <Select
            className="select-filter"
            IconComponent={ExpandMore}
            disableUnderline
            inputProps={{ 'aria-label': 'Without label' }}
            value={sortField}
            onChange={(event) =>
              handleSort(event.target.value, direction ? 'asc' : 'desc')
            }
          >
            <MenuItem value={''}>{'None'}</MenuItem>
            {tableColumns.map((column) => (
              <MenuItem key={'menu_' + column.field} value={column.field}>
                {column.label}
              </MenuItem>
            ))}
          </Select>
        }
        label="Sort by"
      />

      <button
        className="order-button d-block d-md-none"
        onClick={() => handleChangeDirection(!direction)}
      >
        <i className={'fas fa-sort-alpha-' + (direction ? 'down' : 'up')} />
      </button>
    </>
  );
};

export { LocalSelect, AddNew, Sort };
