import { Services } from '../api/services';
import { updateUsersInStore } from '../actions/user-actions';
import { updateLocationsInStore } from '../actions/location-actions';
import { updateRolesInStore } from '../actions/role-actions';
import { updateStatusTypesInStore, updateClientStatusTypes, updateProjectStatusTypes, updateCapacitiesInStore } from '../actions/general-actions';
import { updateTypesInStore } from '../actions/user-types-actions';
import { updateInventoryItems } from '../actions/inventory-actions';
import { updateClientsInStore } from '../actions/clients-actions';
import { updateProjectsInStore } from '../actions/projects-actions';
import { PRINT_IN_CONSOLE } from '../components/shared/constants/constants'

export const getUsers = () => {
    return Services.Users.getUsers().then((data)=>{
        let users = [];
        if(data.val() !== null) {
            const response = data.val();
            if(response) {
                for (var key in response) {
                    users.push(response[key]);
                }
            }
            if(users && users.length > 0) {
                users = users.sort((a,b) => (String(a.firstName).toLowerCase() > String(b.firstName).toLowerCase()) ? 1 : ((String(b.firstName).toLowerCase() > String(a.firstName).toLowerCase()) ? -1 : 0));
                updateUsersInStore(users);
            }
        }
        return { success: true, users };
    })
    .catch((error)=> {
        return { success: false, error };
    });
}

export const getLocations = () => {
    return Services.Locations.getLocations().then((data)=> {
        let locations = [];
        if(data.val() !== null) {
            const response = data.val();
            if(response) {
                for (var key in response) {
                    locations.push(response[key]);
                }
            }
            if(locations && locations.length > 0) {
                locations = locations.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0));
                updateLocationsInStore(locations);
            }
        }
        return { success: true, locations };
    })
    .catch((error)=> {
        if(PRINT_IN_CONSOLE) {
            console.log("Error while getting the locations!");
        }
        return { success: false, error };
    });
}

export const getRoles = () => {
    return Services.Roles.getRoles().then((data)=> {
        let roles = [];
        if(data.val() !== null) {
            const response = data.val();
            if(response) {
                for (var key in response) {
                    roles.push(response[key]);
                }
            }
            if(roles && roles.length > 0) {
                roles = roles.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0));
                updateRolesInStore(roles);
            }
        }
        return { success: true, roles };
    })
    .catch((error)=> {
        if(PRINT_IN_CONSOLE) {
            console.log("Error while getting the roles!");
        }
        return { success: false, error };
    });
}

export const getStatusTypes = () => {
    return Services.General.getUserStatusTypes().then((data)=> {
        let statusTypes = [];
        if(data.val() !== null) {
            const response = data.val();
            if(response) {
                for (var key in response) {
                    statusTypes.push(response[key]);
                }
            }
            if(statusTypes && statusTypes.length > 0) {
                statusTypes = statusTypes.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0));
                updateStatusTypesInStore(statusTypes);
            }
        }
        return { success: true, statusTypes };
    })
    .catch((error)=> {
        if(PRINT_IN_CONSOLE) {
            console.log("Error while getting the status types!");
        }
        return { success: false, error };
    });
}

export const getUserTypes = () => {
    return Services.UserTypes.getUserTypes().then((data)=> {
        let types = [];
        if(data.val() !== null) {
            const response = data.val();
            if(response) {
                for (var key in response) {
                    types.push(response[key]);
                }
            }
            if(types && types.length > 0) {
                types = types.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0));
                updateTypesInStore(types);
            }
        }
        return { success: true, types };
    })
    .catch((error)=> {
        if(PRINT_IN_CONSOLE) {
            console.log("Error while getting the user types!");
        }
        return { success: false, error };
    });
}

export const getInventoryItems = (userId) => {
    return Services.InventoryItems.setInventoryItems(userId).then(data => {
        let inventoryItems = [];
        if(data.val() !== null) {
            const response = data.val();
            if(response) {
                for (var key in response) {
                    inventoryItems.push(response[key]);
                }
            }
            if(inventoryItems && inventoryItems.length > 0) {
                updateInventoryItems(inventoryItems);
            }
        }
        return { success: true, inventoryItems };
    })
    .catch((error)=> {
        return { success: false, error };
    });
}

export const getClients = () => {
    return Services.Clients.setClients().then((data)=>{
        let clients = [];
        if(data.val() !== null) {
            const response = data.val();
            if(response) {
                for (var key in response) {
                    clients.push(response[key]);
                }
            }
            if(clients && clients.length > 0) {
                clients = clients.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0));
                updateClientsInStore(clients);
            }
        }
        return { success: true, clients };
    })
    .catch((error)=> {
        return { success: false, error };
    });
}

export const getClientStatusTypes = () => {
    return Services.General.getClientsStatusTypes().then((data)=>{
        let clientsStatusTypes = [];
        if(data.val() !== null) {
            const response = data.val();
            if(response) {
                for (var key in response) {
                    clientsStatusTypes.push(response[key]);
                }
            }
            if(clientsStatusTypes && clientsStatusTypes.length > 0) {
                clientsStatusTypes = clientsStatusTypes.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0));
                updateClientStatusTypes(clientsStatusTypes);
            }
        }
        return { success: true, clientsStatusTypes };
    })
    .catch((error)=> {
        return { success: false, error };
    });
}

export const getProjectStatusTypes = () => {
    return Services.General.getProjectsStatusTypes().then((data)=>{
        let projectsStatusTypes = [];
        if(data.val() !== null) {
            const response = data.val();
            if(response) {
                for (var key in response) {
                    projectsStatusTypes.push(response[key]);
                }
            }
            if(projectsStatusTypes && projectsStatusTypes.length > 0) {
                projectsStatusTypes = projectsStatusTypes.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0));
                updateProjectStatusTypes(projectsStatusTypes);
            }
        }
        return { success: true, projectsStatusTypes };
    })
    .catch((error)=> {
        return { success: false, error };
    });
}

export const getProjects = () => {
    return Services.Projects.getProjects().then((data)=>{
        let projects = [];
        if(data.val() !== null) {
            const response = data.val();
            if(response) {
                for (var key in response) {
                    projects.push(response[key]);
                }
            }
            if(projects && projects.length > 0) {
                projects = projects.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0));
                updateProjectsInStore(projects);
            }
        }
        return { success: true, projects };
    })
    .catch((error)=> {
        return { success: false, error };
    });
}

export const getCapacities = () => {
    return Services.General.getUserProjects().then((data)=>{
        let capacities = [];
        if(data.val() !== null) {
            const response = data.val();
            if(response) {
                for (var key in response) {
                    capacities.push(response[key]);
                }
            }
            if(capacities && capacities.length > 0) {
                updateCapacitiesInStore(capacities);
            }
        }
        return { success: true, capacities };
    })
    .catch((error)=> {
        return { success: false, error };
    });
}