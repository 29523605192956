import React, { Component } from 'react';
import LocationsModal from '../locations/locations-modal';
import Spinner from 'react-bootstrap/Spinner'
import { connect } from "react-redux";
import { setLocations, setSelectedLocation, deleteLocation } from '../../actions/location-actions'
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa/index';
import  DeleteSimpleModal  from '../shared/simple-modal/delete-simple-modal';
import Navbar from '../main/navbar';
import { getLocations } from '../../utilities/api.calls';
import { ActionButton, AddNew, LocalModal, LocalTable, Sort } from '../common';
import { TitlePanel } from '../common/LocalPanels';
import edit from '../../assets/images/icons/edit.svg';

class LocationsMain extends Component {
  constructor(props) {
    super(props);
    this.goToHome = this.goToHome.bind(this);
    this.handleSelectedEdit = this.handleSelectedEdit.bind(this);
    this.state = {
      locations: [],
      locationsWereLoaded: false,
      errorMessage: '',
      location: {
          id: 0,
          name: "",
          description: ""
      },
      selectedLocationId: "",
      orderByNameAsc: false,
      orderByDescriptionAsc: false,
      showLocationDeletedMessage: false,
      baseAlertClass: "text-center alert alert-dismissible fade show alert-",
      alertClass: "",
      confirmButton: true,
      locationDeletedMessage: "",
      processing: false,
      buttons: [
        {
          id: 1,
          className: "btn btn-sm btn-success",
          dataToggle: "modal",
          dataTarget: "#createLocation",
          title: "Add Location",
          onClick: this.handleSelectedEdit
        }
      ],
      sortField: '',
      sortDirection: 'asc',
      tableColumns: [
        {
          position: 1,
          field: 'name',
          label: 'Name',
          sortable: true,
        },

        {
          position: 2,
          field: 'description',
          label: 'Description',
          sortable: true,
        },
        {
          stylesHeader: { maxWidth: '40px', textAlign: 'right' },
          stylesCell: { textAlign: 'right' },
          position: 4,
          field: '',
          label: 'Actions',
          sortable: false,
          type: 'button',
          customComponent: (row) => {
            return (
              <div className="button-container">
                <ActionButton
                  row={row}
                  dataToggle="modal"
                  dataTarget="#createLocation"
                  handleAction={() => this.handleSelectedEdit({ target: { value: row.id}})}
                  icon="fa fa-edit icon edit"
                  iconSrc={edit}
                  color=""
                />
                <ActionButton
                  row={row}
                  dataToggle="modal"
                  dataTarget="#confirmDelete"
                  handleAction={async () => {
                    await this.setState({
                      deleteLocationId: row.id,
                      deleteLocationName: row.name,
                      deleteLocationDescription: row.description,
                      showDeleteButton: true
                    });
                  }}
                  icon="fas fa-trash-alt"
                  style={{ backgroundColor: '#FFC0C0' }}
                />
              </div>
            );
          },
        },
      ],
      showDeleteButton: false
    }
  }
    

    componentDidMount = () => {
        // const { locations } = this.state
        // this.props.setLocations(locations);
        getLocations().then(response => {
          if(response && response.success === true) {
            this.setState({
                ...this.state,
                locations: response.locations,
                locationsWereLoaded: true
            });
        }
        if(response && response.success === false) {
            this.setState({
                ...this.state,
                locationsWereLoaded: true,
                errorMessage: response.error
            });
        }
        })
    }

    componentDidUpdate = async (prevProps) => {
      const { location } = this.props;
      if(prevProps.location !== location && (typeof location) !== 'undefined' 
      && location && (typeof location.locationDeleted) !== 'undefined' 
      && (typeof location.locationDeletedMessage) !== 'undefined'
      && location.locationDeletedMessage && location.locationDeletedMessage.length > 0) {
        const className = location.locationDeleted ? "success" : "danger";
          await this.setState({
            confirmButton: false,
            showLocationDeletedMessage: true,
            locationDeletedMessage: location.locationDeletedMessage,
            alertClass: this.state.baseAlertClass+className,
            processing: false
          });
      }
      if(prevProps.locations !== this.props.locations){
          await this.setState({
              ...this.state,
              locations: this.props.locations
          });
      }
    }

    handleCloseAlert = () => {
      this.props.setSelectedLocation(undefined);
      this.setState({
        showLocationDeletedMessage: false,
        baseAlertClass: "text-center alert alert-dismissible fade show alert-",
        alertClass: "",
        confirmButton: true,
        locationDeletedMessage: "",
        processing: false
      })
    }

     handleSelectedEdit = event => {
         this.setState({
            selectedLocationId: event.target.value
         })
        this.props.setSelectedLocation(event.target.value);
      };

      alphabeticSortByNameAsc = () => {
        this.setState({
          locations: this.state.locations.sort(
            (a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 
              ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)
          ),
          orderByNameAsc: false
        });
      }

      alphabeticSortByNameDesc = () => {
        this.setState({
          locations: this.state.locations.sort(
            (a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : 
              ((b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : 0)
          ),
          orderByNameAsc: true
        });
      }

      alphabeticSortByDescriptionAsc = () => {
        this.setState({
            locations: this.state.locations.sort(
              (a,b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : 
                ((b.description.toLowerCase() > a.description.toLowerCase()) ? -1 : 0)
            ),
            orderByDescriptionAsc: false
        });
      }

      alphabeticSortByDescriptionDesc = () => {
        this.setState({
            locations: this.state.locations.sort(
              (a,b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? -1 : 
                ((b.description.toLowerCase() > a.description.toLowerCase()) ? 1 : 0)
            ),
            orderByDescriptionAsc: true
        });
      }

      sortLocation = (field, sortDirection) => {
        const asc = sortDirection === 'asc';
        let { locations } = this.state;
        locations = locations.sort((a, b) =>
          a[field].toLowerCase() > b[field].toLowerCase()
            ? asc
              ? 1
              : -1
            : b[field].toLowerCase() > a[field].toLowerCase()
            ? asc
              ? -1
              : 1
            : 0
        );
        this.setState({
          sortField: field,
          sortDirection: sortDirection,
          locations
        });
      };

      handleCloseNotification = () => {
        this.setState({
          locationDeletedMessage: "",
          showLocationDeletedMessage: false
        })
    }

    goToHome = () => {
      this.props.history.push('/app')
    }
      
    render() {
        const { 
          locations,
          locationsWereLoaded,
          errorMessage,
          sortDirection,
          sortField,
          tableColumns
         } = this.state;
        if(!locationsWereLoaded) {
            return (
                <React.Fragment>
                    <div style={{textAlign: 'center', marginTop: '5rem'}}>
                        <Spinner style={{color: 'white'}} animation="border" />
                    </div>
                </React.Fragment> 
            )
        }
        if(locations.length === 0 && errorMessage) {
          alert(errorMessage);
      }
        return ( 
            <React.Fragment>
              <TitlePanel
                {...this.props}
                title="Locations"
                icon="fas fa-location-arrow"
                total={locations.length}
              />
              <Filter
                {...this.props}
                handleNewItem={this.handleSelectedEdit}
                sortField={sortField}
                handleSort={this.sortLocation}
                tableColumns={tableColumns}
              ></Filter>
              <Items
                items={locations}
                sortDirection={sortDirection}
                sortField={sortField}
                handleSort={this.sortLocation}
                handleSelectedEdit={this.handleSelectedEdit}
                handleDelete={async (row) => {
                    await this.setState({
                      deleteLocationId: row.id,
                      deleteLocationName: row.name,
                      deleteLocationDescription: row.description,
                      showDeleteButton: true
                    });
                  }}
                tableColumns={tableColumns}
              />
                <LocationsModal modalId="createLocation" modalTitleId={ this.state.selectedLocationId === "" ? "Create Location" : "Update Location"}/>
                <LocalModal
                  modalName="confirmDelete"
                  title="Confirm Delete"
                  processing={this.state.processing}
                  icon="fas fa-trash-alt"
                  onConfirm={()=> {this.props.deleteLocation(this.state.deleteLocationId); 
                    this.setState({processing: true, showDeleteButton: false});} }
                  onClose={this.handleCloseAlert}
                  onConfirmText="Delete"
                  showDeleteButton={this.state.showDeleteButton}
                >
                  <div key="userTypeDelete">
                    <div
                      className={
                        this.state.showLocationDeletedMessage ? '' : 'd-none'
                      }
                    >
                      <div className={this.state.alertClass} role="alert">
                        {this.state.locationDeletedMessage}
                        <button
                          type="button"
                          className="close"
                          onClick={this.handleCloseAlert}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                    <div className="text-center">
                      <div>Name: {this.state.deleteLocationName}</div>
                      <div>Description: {this.state.deleteLocationDescription}</div>
                    </div>
                  </div>
                </LocalModal>
            </React.Fragment>
         );
    }
}

const Filter = ({
  children,
  handleSort,
  sortField,
  handleNewItem,
  tableColumns,
}) => {
  return (
    <div className="container ">
      <div className="row filter-container">
        <div className="col-10 col-sm-10 col-md-3 col-lg-3 d-none">
          <div className="inner-addon right-addon">
            <i className="fa fa-search icon"></i>
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="col-md-10 col-lg-10 d-none d-md-block">{children}</div>
        <div className="col-12 col-md-2 filter-buttons-container">
          <AddNew handleNew={handleNewItem} dataTarget="#createLocation" />
          <Sort
            handleSort={handleSort}
            sortField={sortField}
            tableColumns={tableColumns}
          />
        </div>
      </div>
    </div>
  );
};

const Items = (props) => {
  return (
    <>
      <Cards {...props} />
      <LocalTable
        {...{
          ...props,
          rows: props.items,
          columns: props.tableColumns,
        }}
      />
    </>
  );
};

const Card = ({ id, name, description, handleSelectedEdit, handleDelete }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="col-12 col-lg-3 col-md-4 col-sm-6 col-exsm-12">
      <div className="inventory-card">
        <div>{name}</div>
        <div className="card-grey">{description}</div>
        {open && (
            <>
              <div className="action-buttons-card-container w-100 mt-1 d-flex justify-content-center">
                <ActionButton
                  dataToggle="modal"
                  dataTarget="#createLocation"
                  handleAction={() => handleSelectedEdit({ target: { value: id}})}
                  icon="fa fa-edit icon edit"
                  iconSrc={edit}
                  color=""
                />
                <ActionButton
                  dataToggle="modal"
                  dataTarget="#confirmDelete"
                  handleAction={() => handleDelete({
                    id, name, description
                  })}
                  icon="fas fa-trash-alt"
                  style={{ backgroundColor: '#FFC0C0' }}
                />
              </div>
            </>
          )}
          <div className="see-more-container">
            <div className="see-more" onClick={() => setOpen(!open)}>
              <i className={'fas fa-chevron-' + (open ? 'up' : 'down')} />
            </div>
          </div>
      </div>
    </div>
  );
}
const Cards = ({ items, handleSelectedEdit, handleDelete }) => {
  return (
    <div className="container d-md-none cards-container">
      <div className="row">
        {items.map((item) => (
          <Card key={'location_' + item.name} {...item}
          handleSelectedEdit={handleSelectedEdit}
          handleDelete={handleDelete} />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
    return {
      locations: state.locationsStore.locations,
      location: state.locationsStore.location
    };
  };
  
  const mapDispatchToProps = dispatch => ({
    setLocations: (locations) => dispatch(setLocations(locations)),
    setSelectedLocation: (locationId) => dispatch(setSelectedLocation(locationId)),
    deleteLocation: (id) => dispatch(deleteLocation(id))
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(LocationsMain);