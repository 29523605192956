import firebase from 'firebase/app';
import "firebase/database";
import {Services} from '../services';
import { PRINT_IN_CONSOLE } from '../../components/shared/constants/constants'

export class LocationsApi {

    getLocations() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`Locations`)
            .once("value")
            .then((locations) => {
                resolve(locations);
            });    
        });
    };

    createNewLocation(name, description) {
        return new Promise((resolve, reject) => {
            const locationKey = firebase.database().ref(`Locations`).push({name, description}).getKey();
            firebase.database()
            .ref(`Locations/${locationKey}`)
            .update({ 
                id: locationKey 
            })
            .then(()=> { 
                resolve({
                    id: locationKey,
                    name,
                    description,
                    locationAdded: true,
                    locationUpdated: false,
                    locationAddedMessage: 'Successfully created location'
                }); 
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error creating location:', error.message);
                }
                resolve(null); 
            })
        });
    };

    updateLocation(id, name, description) {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`Locations/${id}`)
            .update({ 
                name: name,
                description: description 
            })
            .then(()=> { 
                resolve({
                    id,
                    name,
                    description,
                    locationAdded: false,
                    locationUpdated: true,
                    locationUpdatedMessage: 'Successfully updated location'
                }); 
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error updating location:', error.message);
                }
                resolve(null); 
            })
        });
    };

    deleteLocation(id) {
        return new Promise((resolve, reject) => {
            let usersUsingLocation = '', inventoryItemsUsingLocation = '';
            Services.Users.getUsers()
            .then((users) => {
                let usersResponse = users.val();
                for (let key in usersResponse) {
                    if(usersResponse[key]['locationId'].toString() === id.toString()) {
                        if(usersUsingLocation.length > 0) {
                            usersUsingLocation += ', ';
                        }
                        usersUsingLocation += usersResponse[key]['firstName'] + ' ' +  usersResponse[key]['lastName'];
                    }
                }
                Services.InventoryItems.getInventoryItems()
                .then(items => {
                    let inventoryItems = items.val();
                    for (let itemKey in inventoryItems) {
                        if(inventoryItems[itemKey]['locationId'].toString() === id.toString()) {
                            if(usersUsingLocation.length > 0) {
                                inventoryItemsUsingLocation += ', '; 
                            }
                            inventoryItemsUsingLocation += inventoryItems[itemKey]['brand'] + ' ' 
                            +  inventoryItems[itemKey]['model'] + ' (' + inventoryItems[itemKey]['codeId'] + ')';
                        }
                    }
                    if(usersUsingLocation.length === 0 && inventoryItemsUsingLocation.length === 0) {
                        firebase
                        .database()
                        .ref(`Locations/${id}`)
                        .remove()
                        .then(()=> { 
                            let response = { id };
                            resolve(response);
                        })
                        .catch((error) => {
                            if(PRINT_IN_CONSOLE) {
                                console.log('Error removing location:', error.message);
                            }
                            //resolve(null); 
                            resolve({ 'message': 'Error removing location'});
                        });
                    } else {
                        let message = 'Error removing location: ';
                        if(usersUsingLocation.length > 0) {
                            message += 'these users are using this location: ' + usersUsingLocation;
                            message += inventoryItemsUsingLocation.length > 0 ? '\n\n and ' : '.';
                        }
                        if(inventoryItemsUsingLocation.length > 0) {
                            message += 'these inventory items are using this location: ' 
                            + inventoryItemsUsingLocation + '.';
                        }
                        resolve({'message': message});
                    }
                })
                .catch((error) => {
                    if(PRINT_IN_CONSOLE) {
                        console.log('Error removing location:', error.message);
                    }
                    resolve({ 'message': 'Error removing location: can not get inventory items to verify if location is being used'});
                });
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error removing location:', error.message);
                }
                resolve({ 'message': 'Error removing location: can not get users to verify if location is being used'});
            });        
        });
    }

}