import {Services} from '../api/services';

export const setClients = (clients) => {
    return (dispatch, getState) => {
    Services.Clients.setClients().then((data)=>{
        if(data.val() !== null) {
            clients = [];
            const response = data.val();
            for (var key in response) {
                clients.push(response[key]);
            }
            clients = clients.sort(
                (a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 
                  ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)
              );
            dispatch({ type: 'SET_CLIENTS', clients });
        } else {
            clients = [];
            dispatch({ type: '', clients });
        } 
        })
        .catch((error)=>{
            alert("Error while getting the clients!");
            clients = [];
            dispatch({ type: '', clients });
        });
    }
}

export const createNewClient = (name, statusTypeId, clients) => {
    return (dispatch, getState) => {
        Services.Clients.createNewClient(name, statusTypeId, clients)
            .then((data) => {
                var client = {
                    id: data.id,
                    name: data.name,
                    statusId: data.statusId
                };
                if(data.clientAdded)
                {
                    clients.push(client);
                    client["clientAdded"] = data.clientAdded;
                    client["clientAddedMessage"] = data.clientAddedMessage;
                    dispatch({ type: 'CREATE_NEW_CLIENT', payload: { clients, client }});
                }
                else {
                    client["clientAdded"] = false;
                    client["clientAddedMessage"] = data.clientAddedMessage;
                    dispatch({ type: 'CREATE_NEW_CLIENT_ERROR', client });
                }
            }).catch((error) => {
                var client = { };
                client["clientAdded"] = false;
                client["clientAddedMessage"] = "Error when creating the new client";
                dispatch({ type: 'CREATE_NEW_CLIENT_ERROR', client });
            });
    }
};    


export const updateClient = (clientId, name, statusTypeId, clients) => {
    return (dispatch, getState) => {
        Services.Clients.updateClient(clientId, name, statusTypeId, clients)
        .then((data) => {
            var client = {
                id: data.id,
                name: data.name,
                statusId: data.statusId
            };
            if(data.clientUpdated) {
                clients.forEach(client => {
                    if(client.id.toString() === clientId.toString()) {
                        client.name = data.name;
                        client.statusId = data.statusId;
                    }
                });
                //clients.push(client);
                client["clientUpdated"] = data.clientUpdated;
                client["clientUpdatedMessage"] = data.clientUpdatedMessage;
                dispatch({ type: 'UPDATE_CLIENT', payload: { clients, client }});
            }
            else {
                client["clientUpdated"] = false;
                client["clientUpdatedMessage"] = data.clientUpdatedMessage;
                dispatch({ type: 'UPDATE_CLIENT', payload: { clients, client } });
            } 
           }).catch((error) => {
                var client = { };
                client["clientUpdated"] = false;
                client["clientUpdatedMessage"] = "Error when updating the client";
                dispatch({ type: 'UPDATE_CLIENT', payload: { clients, client } });
           });
   };    
};

export const setSelectedClient = (selectedClientId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_CLIENT_ID', selectedClientId });      
   };
}

export const updateClientsInStore = (clients) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_CLIENTS', clients });
    }
}