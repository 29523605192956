import React, { Component } from 'react';
import './login-form.css';
import logo from '../../assets/images/logo.png';
import {sendResetPasswordLink} from '../../actions/user-actions'
import { connect } from "react-redux";
import queryString from 'query-string'
import 'firebase/auth'
import { verifyEmail, recoverEmail } from '../../actions/user-actions'
import PasswordReset from './password-reset'

class AuthActions extends Component {
    state = {
        actionTitle: "",
        actionText: "",
        message: '',
        action: '',
        authCode: '',
        processing: false
      };

    componentDidMount = async () => {
        const values = queryString.parse(this.props.location.search);
        const action = values.mode;
        const authCode = values.oobCode;
        const title = action === "verifyEmail" ? 'Verify Email' : action === "recoverEmail" ? 'Recover Email' : '';
        await this.setState({
            action,
            authCode,
            actionTitle: title
        });

        if(action === "verifyEmail") {
            this.props.verifyEmail(authCode);
        } else {
            if(action === "recoverEmail") {
                this.props.recoverEmail(authCode);
            } else {
                if(action === "signIn") {
                    try {
                        const url = values.continueUrl;
                        console.log(url);
                        const registrationCode = url.split("=")[1];//url.split("?")[1].split("&")[0].split("%3D")[1];
                        console.log(registrationCode);
                        this.props.history.push({
                            pathname: '/registerForm',
                            state: {
                                registrationCode
                            },
                        });
                    }catch(error) {
                        console.log(error)
                        this.props.history.push({
                            pathname: '/login'
                        });
                    }
                }
            }
        }
      }

    componentDidUpdate = async(prevProps) => {
        if(prevProps.authResult !== this.props.authResult && (typeof this.props.authResult) !== 'undefined' && this.props.authResult) {
            await this.setState({
                ...this.state,
                processing: false,
                message: this.props.authResult.message
            });
        }
    }


    handleTextboxChange = (e) => {
        this.setState({[e.target.name]: e.currentTarget.value});
    }

    sendResetPasswordLink = async(e) => {
        const error = this.validateForm();
        if(!error)
        {
            await this.setState({
                ...this.state,
                processing: true
            })
            this.props.sendResetPasswordLink(this.state.email);
        }
    }

    validateForm = async() => {
        await this.setState({
            ...this.state,
            emailError: "",
            passwordError: ""
        });
        if(this.state.email.length <= 0){
            await this.setState({...this.state, emailError: "Email is Required."});
            return true;
        }     
    }

    render() { 
        const { action, authCode } = this.state;
        return ( 
            <React.Fragment >
                { action === "resetPassword" ?
                <PasswordReset authCode={authCode} />
                : action === "verifyEmail" || action === "recoverEmail" ?
                    <div>
                        <header className="App-header">
                            <a href='/'>
                                <img src={logo} className="App-logo" alt="logo" />
                            </a>
                        </header>
                        <div style={{color: 'white', textAlign: 'center', marginTop: '4rem'}}>
                            <label>{this.state.message}</label> 
                        </div>
                    </div>
                    : ""

                }


                 
            </React.Fragment>
         );
    }
}


const mapStateToProps = ({usersStore}) => {
    return {
        authResult: usersStore.authResult
    };
  };
  
  const mapDispatchToProps = dispatch => ({
    sendResetPasswordLink: (email) => dispatch(sendResetPasswordLink(email)),
    verifyEmail: (authCode) => dispatch(verifyEmail(authCode)),
    recoverEmail: (authCode) => dispatch(recoverEmail(authCode))
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthActions);
