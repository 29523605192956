import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getUser,
  setProfileImage,
  setUser,
  updateUsersInStore,
} from '../../../actions/user-actions';
import './../../../App.css';
import { Services } from '../../../api/services';
import { getPropertyValue } from './userUtilities';
import { Button } from '@material-ui/core';
import Cookies from 'universal-cookie';
import { LocalModal } from '../../common';

const MultimediaFormData = ({
  user,
  users,
  title,
  notify,
  setProfileImage,
  setUser,
  updateUsersInStore,
  toHome,
  nextText = 'Finish',
  onboarding,
  profile,
  getUser,
  updateLocalUser,
  handleCloseNotification,
  finishRegister,
  history
}) => {
  const [state, setState] = useState({
    file: undefined,
    fileName: '',
    fileType: '',
    fileURL: user.file && user.file.url,
  });

  const onSubmitImageForm = async (data) => {
    let { file, fileName, fileType } = state;

    handleCloseNotification();

    if (user.file && !file) {
      if(finishRegister) {
        await finishRegister();
        // setTimeout(() => {
        //   onboarding && document.getElementById('confirmModal').click();
        // }, 2000)
      }
      //onboarding && await finishRegister();
      //onboarding && document.getElementById('confirmModal').click();
    } else {
      await Services.Users.uploadImageOnStorage(user.id, file)
        .then(async (response) => {
          if (response.success === true) {
            const file = {
              name: fileName,
              type: fileType,
              url: response.downloadUrl,
            };

            const fileResponse = await Services.Users.updateUserFileOnly(
              user.id,
              file
            );
            if (fileResponse.success === true) {
              if (getPropertyValue(fileResponse.user) !== '') {
                let users = users;
                if (getPropertyValue(users) !== '') {
                  users = users.map((user) => {
                    if (user.id === state.userId) {
                      user = fileResponse.user;
                    }
                  });
                } else {
                  users = [];
                  users.push(fileResponse.user);
                }
                const userId = new Cookies().get('1950SystemUserId');
                if (fileResponse.user.id === userId)
                  setUser(users, fileResponse.user);
                else {
                  updateUsersInStore(users);
                }
              }
              if (
                getPropertyValue(file) !== '' &&
                getPropertyValue(file.url) !== ''
              ) {
                setProfileImage(file.url);
              }

              setState({ fileURL: response.downloadUrl });

              notify('Profile image uploaded successfully', 'success');
              if(finishRegister) {
                await finishRegister();
                // setTimeout(() => {
                //   onboarding && document.getElementById('confirmModal').click();
                // }, 2000)
              }
              //onboarding && await finishRegister();

              //onboarding && document.getElementById('confirmModal').click();
            } else {
              notify('Error trying to upload profile image', 'danger');
            }
          } else {
            notify('Error trying to upload profile image', 'danger');
          }
        })
        .catch((error) => {
          notify('Error trying to upload profile image', 'danger');
        });
    }
  };

  const onChangeImageFile = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileType = file.type;

      updateLocalUser({
        file: {
          name: fileName,
          type: fileType,
          url: URL.createObjectURL(file),
        },
      });

      await setState({
        ...state,
        file,
        fileName,
        fileType,
        fileURL: URL.createObjectURL(file),
      });
    }
  };

  return (
    <React.Fragment>
      <h2 className="onboarding-title">{title}</h2>
      <div
        className="container text-center"
        style={{ display: 'flex', flexWrap: 'wrap' }}
      >
        <div className="col-12 d-flex justify-content-center mb-4">
          <div
            className="onboarding-profile-image"
            style={{ backgroundImage: 'url(' + state.fileURL + ')' }}
          ></div>
        </div>
        <div className="col-12 d-flex justify-content-center mb-4">
          <Button
            variant="contained"
            component="label"
            className="upload-button"
            style={{ maxWidth: '183px' }}
          >
            <span className="upload-button-text">Upload Picture</span>
            <i class="fas fa-paperclip"></i>
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={onChangeImageFile}
            />
          </Button>
        </div>
        <div className="col-12 text-center">
          <button
            type="button"
            onClick={onSubmitImageForm}
            className="next-button"
          >
            {nextText}
          </button>
        </div>
      </div>
      <button
        id="confirmModal"
        className="action-button d-none"
        data-toggle="modal"
        data-target="#confirmOnboarding"
      ></button>
      <LocalModal
        modalName="confirmOnboarding"
        title="You are ready!"
        onConfirmText="Go to dashboard"
        onConfirm={() => {
          setTimeout(() => {
            document.getElementById('confirmModal').click();
            history.push('/app')
          }, 2000)
          
        }}
        hideCloseButton={true}
      >
        <div className="confirm-onboarding">
          <h1>Welcome Aboard {user.firstName}</h1>
          <p>
            Take your time to explore the app, not all your functions may be
            enabled, check with your PM, Leader or Administrator to finish the
            Onboarding.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            Thank you for being part of 1950Labs
          </p>
        </div>
      </LocalModal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.usersStore.users,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setProfileImage: (imageUrl) => dispatch(setProfileImage(imageUrl)),
  setUser: (users, user) => dispatch(setUser(users, user)),
  updateUsersInStore: (users) => dispatch(updateUsersInStore(users)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultimediaFormData);
