import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { connect } from "react-redux";
import { setProjects } from '../../actions/projects-actions'
import { setClients } from '../../actions/clients-actions'
import { setUsers } from '../../actions/user-actions'
import { createNewUserProject, updateUserCapacity, setSelectedUserProject, saveCapacityInStore } from '../../actions/general-actions'
import Spinner from 'react-bootstrap/Spinner'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment";
import { Services } from '../../api/services';

class CapacityPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            userProject: {
                id: "",
                projectId: "",
                userId: "",
                beginDate: moment().format("MM/DD/YYYY"),
                endDate: moment().format("MM/DD/YYYY")
            },
            nameError: "",
            projectIdError: "",
            userIdError: "",
            baseAlertClass: "text-center alert alert-dismissible fade show alert-",
            alertClass: "",
            showCreateUserProjectMessage: false,
            userProjectAddMessage: "",
            userProjectUpdatedMessage: "",
            processing: false,
            projectButtonText: "Select",
            userButtonText: "Select",
            selectedProjectId: 0,
            selectedUserId: 0,
            calendarBeginDate: new Date(),
            calendarEndDate: new Date(),
            beginDate: moment().format("MM/DD/YYYY"),
            endDate: moment().format("MM/DD/YYYY"),
            selectedUserProjectId: 0,
            statusOptions: [{id: 1, value: "Active"}, {id: 2, value: "Inactive"}],
            statusButtonText: "Active"
        }
    }

    handleTextboxChange = async (e) => {
        await this.setState({
            ...this.state,
            userProject: {
                ...this.state.userProject,
                [e.target.name]: e.currentTarget.value
            },
            alertClass: "",
            showCreateUserProjectMessage: false
        }); 
    }

    componentDidUpdate = async (prevProps) => {
        const { selectedUserProjectId, selectedUserId, projects, userProject, users } = this.props
        if(prevProps.userProject !== this.props.userProject  && (typeof this.props.userProject) !== 'undefined' 
        && this.props.userProject && (typeof this.props.userProject.userProjectDeleted) === 'undefined') {
            this.props.setUsers()
            this.setState({
                ...this.state,
                userProject:{
                    id: userProject.userProjectAdded ? "" : this.state.userProject.id,
                    userId: userProject.userProjectAdded ? "" : this.state.userProject.userId,
                    projectId: userProject.userProjectAdded ? "" : this.state.userProject.projectId,
                    beginDate: userProject.userProjectAdded ? moment().format("MM/DD/YYYY") : this.state.userProject.beginDate,
                    endDate: userProject.userProjectAdded ? moment().format("MM/DD/YYYY") : this.state.userProject.endDate
                },
                calendarBeginDate: userProject.userProjectAdded ? new Date() : new Date(userProject.beginDate),
                calendarEndDate: userProject.userProjectAdded ? new Date() : new Date(userProject.endDate),
                userProjects: this.props.userProjects,
                projects: this.props.projects.filter((project) => project.statusId === "-LVKu1L8RQg7woJlkpC5"),
                users: this.props.users.filter((user) => user.userStatusTypeId === 1),
                userProjectAddMessage: this.props.userProject.userProjectAdded ? "The capacity has been added." : this.props.userProject.userProjectAddedMessage,
                userProjectUpdatedMessage: this.props.userProject.userProjectUpdated ? "The capacity has been updated." : this.props.userProject.userProjectUpdatedMessage,
                alertClass: this.props.userProject.userProjectAdded || this.props.userProject.userProjectUpdated ? this.state.baseAlertClass + "success" : this.state.baseAlertClass + "danger" ,
                showCreateUserProjectMessage: true,
                processing: false,
                statusButtonText: userProject.userProjectAdded ? "Active" : this.state.statusButtonText,
                projectButtonText: userProject.userProjectAdded ? "Select" : this.state.projectButtonText,
                userButtonText: userProject.userProjectAdded ? "Select" : this.state.userButtonText,
                selectedUserId: userProject.userProjectAdded ? 0: this.state.selectedUserId,
                selectedProjectId: userProject.userProjectAdded ? 0: this.state.selectedProjectId,
            });
        } else if(prevProps.selectedUserProjectId !== this.props.selectedUserProjectId && (typeof this.props.selectedUserProjectId) !== 'undefined' && this.props.selectedUserProjectId) {
            const selectedUserProject = this.props.userProjects.find(userProject=> {if(userProject.id === this.props.selectedUserProjectId) {return userProject} return null }); //[0][selectedUserId][selectedUserProjectId] : undefined;
            const selectedUser = selectedUserId && selectedUserProject ? users.find(user => { if(user.id.toString() === selectedUserId.toString()) { return user } return undefined }) : undefined
            const status = (typeof selectedUserProject) !== 'undefined' && selectedUserProject && (selectedUserProject.status === "Active" || selectedUserProject.status === "Inactive") ? selectedUserProject.status : "Active";
            let projectAux = null;
            if((typeof selectedUserProject) !== 'undefined' && selectedUserProject)
                projectAux = projects.find(project => { 
                    if(project.id.toString() === selectedUserProject.projectId.toString()) { 
                        return project 
                    } 
                    return undefined 
                });
            const projectName = selectedUserProject ? (typeof projectAux) !== 'undefined' && projectAux ?  projectAux.name : "Select" : "Select";
            this.setState({
                ...this.state,
                userProject: {
                    id: selectedUserProject ? selectedUserProject.id : "",
                    userId: selectedUserProject ? selectedUserProject.userId : "",
                    projectId: selectedUserProject ? selectedUserProject.projectId : "",
                    beginDate: selectedUserProject ? selectedUserProject.beginDate : moment().format("MM/DD/YYYY"),
                    endDate: selectedUserProject ? selectedUserProject.endDate : moment().format("MM/DD/YYYY")
                },
                selectedUserProjectId: selectedUserProject ? selectedUserProject.id : 0,
                calendarBeginDate: selectedUserProject ? new Date(selectedUserProject.beginDate) : new Date(),
                calendarEndDate: selectedUserProject ? new Date(selectedUserProject.endDate) : new Date(),
                userButtonText: selectedUser ? selectedUser.firstName + " " + selectedUser.lastName : "Select",
                selectedUserId: selectedUserProject ? selectedUserProject.userId : 0,
                projectButtonText: projectName,
                selectedProjectId: selectedUserProject ? selectedUserProject.projectId : 0,
                statusButtonText: (typeof status) !== 'undefined' && status && status.length > 0 ? status : "Active"
            });
        } 
    }

    handleCreateProject = async () => {
        await this.setState({
            ...this.state,
            ...this.state.project,
            alertClass: "",
            showCreateUserProjectMessage: false
        });
        const { selectedProjectId, userProject, selectedUserId, baseAlertClass, statusButtonText } = this.state;
        const { userProjects } = this.props
        const formValid = await this.validateProjectForm(selectedUserId, selectedProjectId, userProject.beginDate, userProject.endDate, statusButtonText);
        
        if(formValid) {
            this.setState({ processing: true })
            if(userProjects) {
                const usrProjects = userProjects[0][selectedUserId];
                if(usrProjects && Object.values(usrProjects).find(up => { 
                    if(up.userId === selectedUserId && up.projectId === selectedProjectId) { 
                        return up 
                    } else {
                        return undefined 
                    }
                })) {
                    this.setState({
                        showCreateUserProjectMessage: true,
                        userProjectAddMessage: "The user has already been added to the project",
                        userProjectUpdatedMessage: "The user has already been added to the project",
                        alertClass: baseAlertClass + "danger",
                        processing: false
                    })
                } else {
                    this.postCapacity(selectedUserId, selectedProjectId, userProject.beginDate, userProject.endDate, this.props.userProjects, statusButtonText)
                    //this.props.createNewUserProject(selectedUserId, selectedProjectId, userProject.beginDate, userProject.endDate, this.props.userProjects, statusButtonText)
                }
            } else {
                this.postCapacity(selectedUserId, selectedProjectId, userProject.beginDate, userProject.endDate, [{}], statusButtonText)
                //this.props.createNewUserProject(selectedUserId, selectedProjectId, userProject.beginDate, userProject.endDate, [{}], statusButtonText)
            }
        }
    }

    postCapacity = (userId, projectId, beginDate, endDate, userProjects, status) => {
        Services.General.createNewUserProject(userId, projectId, beginDate, endDate, userProjects, status)
        .then((data) => {
            if((typeof data) !== undefined && data !== null) {
                
                if(data.userProjectAdded)
                {
                    const userProject = this.getUserProject(data);
                    // const rowUser = this.props.users.find(user=> {if(user.id === userProject.userId) {return user} return null });
                    // const rowProject = this.props.projects.find(project=> {if(project.id === userProject.projectId) {return project} return null });
                    // if(rowProject) {
                    //     const rowClient = this.props.clients.find(client=> {if(client.id === rowProject.clientId) {return client} return null });
                    //     userProject['user'] = rowUser ? rowUser.firstName + " " + rowUser.lastName : "";
                    //     userProject['project'] = rowProject ? rowProject.name : "";
                    //     userProject['client'] = rowClient ? rowClient.name : "";
                    //     userProject["userProjectAdded"] = data.userProjectAdded;
                    //     userProject["userProjectAddedMessage"] = data.userProjectAddedMessage;
                        userProjects.push({ 
                            beginDate: data.beginDate, 
                            endDate: data.endDate,
                            id: data.id, 
                            projectId: data.projectId, 
                            userId: data.userId,
                            status: data.status,
                            user: userProject.user,
                            project: userProject.project,
                            client: userProject.client
                        });
                    // } else {
                    //     userProjects.push({ 
                    //         beginDate: data.beginDate, 
                    //         endDate: data.endDate,
                    //         id: data.id, 
                    //         projectId: data.projectId, 
                    //         userId: data.userId,
                    //         status: data.status
                    //     });
                    // }
                    this.props.saveCapacityInStore(userProjects, userProject);
                    this.showNot(data.userProjectAddedMessage, 'success');
                }
                else {
                    this.showNot(data.userProjectAddedMessage, 'danger');
                }
            }
        }).catch((error) => {
            this.showNot("Error when creating the capacity", 'danger');
        });
    }

    getUserProject = (data) => {
        let userProject = {
            beginDate: data.beginDate, 
            endDate: data.endDate,
            id: data.id,
            projectId: data.projectId,
            userId: data.userId,
            status: data.status
        };
        const rowUser = this.props.users.find(user=> {if(user.id === userProject.userId) {return user} return null });
        const rowProject = this.props.projects.find(project=> {if(project.id === userProject.projectId) {return project} return null });
        if(rowProject) {
            const rowClient = this.props.clients.find(client=> {if(client.id === rowProject.clientId) {return client} return null });
            userProject['user'] = rowUser ? rowUser.firstName + " " + rowUser.lastName : "";
            userProject['project'] = rowProject ? rowProject.name : "";
            userProject['client'] = rowClient ? rowClient.name : "";
            userProject["userProjectAdded"] = data.userProjectAdded;
            userProject["userProjectAddedMessage"] = data.userProjectAddedMessage;
            userProject["userProjectUpdated"] = data.userProjectUpdated;
            userProject["userProjectAddedMessage"] = data.userProjectUpdatedMessage;
        } else {
            userProject['user'] = "";
            userProject['project'] = "";
            userProject['client'] = "";
            userProject["userProjectAdded"] = data.userProjectAdded;
            userProject["userProjectAddedMessage"] = data.userProjectAddedMessage;
            userProject["userProjectUpdated"] = data.userProjectUpdated;
            userProject["userProjectAddedMessage"] = data.userProjectUpdatedMessage;
        }
        return userProject;
    }

    showNot = (userProjectAddMessage, type, userProjectUpdatedMessage) => {
        this.setState({
            ...this.state,
            userProjectAddMessage,
            userProjectUpdatedMessage,
            alertClass: this.state.baseAlertClass + type,
            showCreateUserProjectMessage: true,
            processing: false
        })
    }

    handleUpdateProject = async () => {
        await this.setState({
            ...this.state,
            ...this.state.project,
            alertClass: "",
            showCreateUserProjectMessage: false
        });
        const { selectedUserProjectId, userProjects } = this.props
        let { selectedUserId, selectedProjectId, userProject, baseAlertClass, statusButtonText } = this.state
        let projectId = (typeof selectedProjectId) !== 'undefined' && selectedProjectId ? selectedProjectId : userProject.projectId;
        let userId = (typeof selectedUserId) !== 'undefined' && selectedUserId ? selectedUserId : this.props.selectedUserId;
        let formValid = await this.validateProjectForm(userId, projectId, userProject.beginDate, userProject.endDate, statusButtonText);
        const capacityId = (typeof selectedUserProjectId) !== 'undefined' && selectedUserProjectId ? selectedUserProjectId : userProject.id;
        if(formValid && (typeof capacityId) !== 'undefined' && capacityId) {
            if(userProjects) {
                const usrProjects = userProjects[0][userId];
                if((typeof usrProjects) !== 'undefined' && usrProjects) {
                    Object.values(usrProjects).forEach(up => {
                        if(up.userId === userId && up.projectId === projectId && capacityId !== up.id) {
                            formValid = false 
                        }
                    });
                    if(!formValid){
                        await this.setState({
                            showCreateUserProjectMessage: true,
                            userProjectAddMessage: "The user has already been added to the project",
                            userProjectUpdatedMessage: "The user has already been added to the project",
                            alertClass: baseAlertClass + "danger",
                            processing: false
                        });
                    }
                }
                if(formValid && (typeof capacityId) !== 'undefined' && capacityId) {
                    this.setState({ processing: true });
                    this.putCapacity(capacityId, userId, projectId, userProject.beginDate, userProject.endDate, this.props.userProjects, this.props.selectedUserId, statusButtonText);
                    //this.props.updateUserCapacity(capacityId, userId, projectId, userProject.beginDate, userProject.endDate, this.props.userProjects, this.props.selectedUserId, statusButtonText);   
                }
            }
        }
    }

    putCapacity = (userProjectId, userId, projectId, beginDate, endDate, userProjects, oldUserId, status) => {
        Services.General.updateUserCapacity(userProjectId, userId, projectId, beginDate, endDate, userProjects, oldUserId, status)
            .then((data) => {
                // let userProject = {
                //     beginDate: data.beginDate, 
                //     endDate: data.endDate,
                //     id: data.id,
                //     projectId: data.projectId,
                //     userId: data.userId,
                //     status: data.status
                // };
                if(data.userProjectUpdated)
                {
                    const userProject = this.getUserProject(data);
                    userProjects = userProjects.map(capacity => {
                        if((typeof capacity) !== 'undefined' && capacity !== null && capacity.id === userProjectId) {
                            capacity.beginDate = userProject.beginDate;
                            capacity.endDate = userProject.endDate;
                            capacity.projectId = userProject.projectId; 
                            capacity.userId = userProject.userId;
                            capacity.status = userProject.status;
                            capacity['user'] = userProject.user;
                            capacity['project'] = userProject.project;
                            capacity['client'] = userProject.client;
                        }
                    })
                    this.props.saveCapacityInStore(userProjects, userProject);
                    this.showNot("", 'success', data.userProjectUpdatedMessage);
                }
                else {
                    this.showNot("", 'danger', data.userProjectUpdatedMessage);
                }
            }).catch((error) => {
                this.showNot("", 'danger', "Error when updating the capacity");
        });
    }

    handleCloseAlert = () => {
        this.props.setSelectedUserProject("", "");
        this.setState({
            ...this.state,
            showCreateUserProjectMessage: false,
            userProject: {
                id: undefined,
                userId: 0,
                projectId: 0,
                beginDate: moment().format("MM/DD/YYYY"),
                endDate: moment().format("MM/DD/YYYY")
            },
            projectIdError: "",
            userIdError: "",
            projectButtonText: "Select",
            userButtonText: "Select",
            selectedProjectId: 0,
            selectedUserId: 0,
            calendarBeginDate: new Date(),
            calendarEndDate: new Date(),
            beginDate: moment().format("MM/DD/YYYY"),
            endDate: moment().format("MM/DD/YYYY"),
            beginDateError: "",
            endDateError: "",
            //statusOptions: [{id: 1, value: "Active"}, {id: 2, value: "Inactive"}],
            userProjectAddMessage: "",
            userProjectUpdatedMessage: "",
            alertClass: "",
            processing: false
        });
    }

    handleCloseNotification = () => {
        this.setState({
            ...this.state,
            showCreateUserProjectMessage: false,
            userProjectAddMessage: "",
            userProjectUpdatedMessage: "",
            alertClass: "",
            processing: false
        });
    }

    validateProjectForm = async (userId, projectId, beginDate, endDate, status) => {
        let formValid = false;
        const isNewUserProject = this.props.selectedUserProjectId === "";
        if(isNewUserProject)
            await this.setState({
                ...this.state,
                userIdError: userId !== 0  ? "" : "User is required",
                projectIdError: projectId !== 0  ? "" : "Project is required",
                beginDateError: moment(beginDate) < moment(endDate) ? "" : "Start date should be before the end date",
                endDateError: moment(beginDate) < moment(endDate) ? "" : "End date should be after the start date"
            });
        else
            await this.setState({
                ...this.state,
                userIdError: userId !== 0  ? "" : "User is required",
                projectIdError: projectId !== 0  ? "" : "Project is required",
                beginDateError: moment(beginDate) < moment(endDate) ? "" : "Start date should be before the end date",
                endDateError: moment(beginDate) < moment(endDate) ? "" : "End date should be after the start date",
                statusError: (typeof status) === 'undefined' ? "Status is required" : (status !== "Active" && status !== "Inactive" ? "Status should be Active or Inactive": "") 
            });
        formValid = this.state.userIdError === "" && this.state.projectIdError === "" && this.state.beginDateError === "" && this.state.endDateError === "";
        return formValid;
    }

    beginDateChanged = (date) => {
        this.setState({ 
            calendarBeginDate: date,
            userProject: {
                ...this.state.userProject,
                beginDate: moment(date).format("MM/DD/YYYY")
            }
        })
    }

    endDateChanged = (date) => {
        this.setState({ 
            calendarEndDate: date,
            userProject: {
                ...this.state.userProject,
                endDate: moment(date).format("MM/DD/YYYY")
            }
        })
    }

    handleClickOutside = () => {
        this.handleCloseAlert();
    }

    render() {  
        const { modalId, modalTitleId,  selectedUserProjectId, projects, clients, users } = this.props
        let filteredProjects = [], filteredUsers = [];
        const { statusOptions } = this.state;
        filteredProjects = projects.filter((project) => project.statusId === "-LVKu1L8RQg7woJlkpC5");
        filteredUsers = users.filter((user) => user.userStatusTypeId.toString() === "1");
        const capacityId = (typeof selectedUserProjectId) !== 'undefined' && selectedUserProjectId ? selectedUserProjectId : this.state.userProject.id;
        const isNewUserProject = (typeof capacityId) === 'undefined' || capacityId === "";
        return ( 
           
            <React.Fragment>
                <div className="modal fade" id={modalId} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"  aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={modalTitleId}>{modalTitleId}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" onClick={this.handleCloseAlert}>&times;</span>
                            </button>
                        </div>

                    <div className={ this.state.showCreateUserProjectMessage ? "" : "d-none" }>
                        <div className={ this.state.alertClass } role="alert">
                            { isNewUserProject ? this.state.userProjectAddMessage : this.state.userProjectUpdatedMessage }
                            <button type="button" className="close" onClick={ this.handleCloseNotification }>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    
                    <div className="modal-body">
                        <div>
                            <div className="row">
                                <div className="col">
                                    <label >User:<span className="ml-1" style={{color: 'red'}}>*</span></label>
                                    <div className="dropdown"> 
                                        <button style={{ width: '80%' }} className="btn btn-sm btn-info dropdown-toggle ml-1" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            { this.state.userButtonText }
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                            { filteredUsers && filteredUsers.map(user => {
                                            return( 
                                                <button
                                                    key={user.id} 
                                                    onClick={event => this.setState({selectedUserId: event.target.value, userButtonText: user.firstName + " " + user.lastName })} 
                                                    className="dropdown-item" type="button" value={user.id} >
                                                {user.firstName + " " + user.lastName}
                                                </button>
                                            )}) }
                                        </div>
                                    </div>
                                    <div className="text-danger">{this.state.userIdError}</div>
                                </div>

                                <div className="col">
                                    <label >Project:<span className="ml-1" style={{color: 'red'}}>*</span></label>
                                    <div className="dropdown"> 
                                        <button style={{ width: '80%' }} className="btn btn-sm btn-info dropdown-toggle ml-1" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            { this.state.projectButtonText }
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                            { filteredProjects && filteredProjects.map(project => {
                                                const projectClient = clients.find(client=> { 
                                                    if(client.id === project.clientId) { 
                                                        return client 
                                                    } 
                                                    else 
                                                    return undefined 
                                                })
                                            return( 
                                                <button
                                                    key={project.id} 
                                                    onClick={event => this.setState({selectedProjectId: event.target.value, projectButtonText: project.name })} 
                                                    className="dropdown-item" type="button" value={project.id} >
                                                ({ (typeof projectClient) !== 'undefined' && projectClient ? projectClient.name : ""}) {project.name}
                                                </button>
                                            )}) }
                                        </div>
                                    </div>
                                    <div className="text-danger">{this.state.projectIdError}</div>
                                </div>

                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <label >Start Date:<span className="ml-1" style={{color: 'red'}}>*</span></label><br/>
                                        <DatePicker
                                            onChange={this.beginDateChanged} 
                                            selected={this.state.calendarBeginDate}
                                        />
                                    <div className="text-danger">{this.state.beginDateError}</div>
                                </div>
                                <div className="col">
                                    <label >End Date:<span className="ml-1" style={{color: 'red'}}>*</span></label><br/>
                                        <DatePicker
                                            onChange={this.endDateChanged} 
                                            selected={this.state.calendarEndDate}
                                        />
                                    <div className="text-danger">{this.state.endDateError}</div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <label >Status:<span className="ml-1" style={{color: 'red'}}>*</span></label>
                                    <div className="dropdown">
                                        <button style={{ width: '38%' }} className="btn btn-sm btn-info dropdown-toggle ml-1" type="button" id="dropdownMenu3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            { this.state.statusButtonText }
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenu3">
                                            { (typeof statusOptions) !== 'undefined' && statusOptions && statusOptions.map(status => {
                                            return( 
                                                <button
                                                    key={status.id} 
                                                    onClick={event => this.setState({ statusButtonText: event.target.value })} 
                                                    className="dropdown-item" type="button" value={status.value}>
                                                    {status.value}
                                                </button>
                                            )}) }
                                        </div>
                                    </div>
                                    <div className="text-danger">{this.state.statusError}</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        { this.state.processing ? <Spinner className="mr-4" style={{color: '#578db9'}} animation="border" /> :
                        <div>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={ this.handleCloseAlert }>Close</button>
                            <button type="submit" onClick={ () => isNewUserProject ? this.handleCreateProject() : this.handleUpdateProject() } className="btn btn-primary ml-2" >{ isNewUserProject ? "Save" : "Update"}</button>
                        </div>}
                    </div>
                </div>
            </div>
            </div>
            </React.Fragment>
         )
    }
}


const mapStateToProps = state => {
    return {
        // clients: state.clientsStore.clients,
        // projects: state.projectsStore.projects,
        // userProjects: state.generalStore.userProjects,
        // userProject: state.generalStore.userProject,
        selectedUserProjectId: state.generalStore.selectedUserProjectId,
        selectedUserId: state.generalStore.userId,
        users: state.usersStore.users
     };
   };
  
  const mapDispatchToProps = dispatch => ({
    setUsers: (users) => dispatch(setUsers(users)),
    setClients: (clients) => dispatch(setClients(clients)),
    setProjects: (projects) => dispatch(setProjects(projects)),
    setSelectedUserProject: (userProjectId, userId) => dispatch(setSelectedUserProject(userProjectId, userId)),
    createNewUserProject: (selectedUserId, selectedProjectId, beginDate, endDate, userProjects, status) => dispatch(createNewUserProject(selectedUserId, selectedProjectId, beginDate, endDate, userProjects, status)),
    updateUserCapacity: (selectedUserProjectId, selectedUserId, projectId, beginDate, endDate, userProjects, oldUserId, status) => dispatch(updateUserCapacity(selectedUserProjectId, selectedUserId, projectId, beginDate, endDate, userProjects, oldUserId, status)),
    saveCapacityInStore: (userProjects, userProject) => dispatch(saveCapacityInStore(userProjects, userProject))
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(onClickOutside(CapacityPopUp));