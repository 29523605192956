import {Services} from '../api/services';

export const setLocations = (locations) => {
    return (dispatch, getState) => {
        Services.Locations.getLocations().then((data)=>{
            if(data.val() !== null) {
                locations = [];
                const response = data.val();
                for (var key in response) {
                    locations.push(response[key]);
                }
                dispatch({ type: 'SET_LOCATIONS', locations: locations.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0)) });
            } else {
                locations = [];
                dispatch({ type: '', locations });
            }}
        )
        .catch((error)=> {
            alert("Error while getting the locations!");
            locations = [];
            dispatch({ type: 'SET_LOCATIONS', locations });
        });
    }
};

export const setSelectedLocation = (selectedLocationId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_LOCATION_ID', selectedLocationId });      
   };
};

export const createNewLocation = (name, description, locations) => {
    return (dispatch, getState) => {
        Services.Locations.createNewLocation(name, description)
        .then((data)=>{
            let location = {};
            if(data) {
                location = {
                    id: data.id,
                    name: data.name,
                    description: data.description
                };
                if(data.locationAdded)
                {
                    locations.push(location);
                    location["locationAdded"] = data.locationAdded;
                    location["locationAddedMessage"] = data.locationAddedMessage;
                    dispatch({ type: 'SET_NEW_LOCATION', payload: { locations, location }});
                }
            }
            else{
                location["locationAdded"] = false;
                location["locationAddedMessage"] = "Error when creating the new location";
                dispatch({ type: 'SET_NEW_LOCATION_ERROR', location });
            } 
           }).catch((error)=>{
                var location = { };
                location["locationAdded"] = false;
                location["locationAddedMessage"] = "Error when creating the new location";
                dispatch({ type: 'SET_NEW_LOCATION_ERROR', location });
           });
   };
};
   export const updateLocation = (id, name, description, locations) => {
    return (dispatch, getState) => {
        Services.Locations.updateLocation(id, name, description)
        .then((data)=>{
            let location = {};
            if(data) {
                location = {
                    id: data.id,
                    name: data.name,
                    description: data.description
                };
                if(data.locationUpdated)
                {
                    locations.forEach((loc) => {
                        if(loc.id === data.id) {
                            loc.name = data.name;
                            loc.description = data.description;
                        }
                    });
                    location["locationUpdated"] = data.locationUpdated;
                    location["locationUpdatedMessage"] = data.locationUpdatedMessage;
                    dispatch({ type: 'SET_UPDATED_LOCATION', payload: { locations, location }});
                }
            }
            else{
                location["locationUpdated"] = false;
                location["locationUpdatedMessage"] = "Error when updating the location";
                dispatch({ type: 'SET_UPDATED_LOCATION_ERROR', location });
            } 
           }).catch((error)=>{
                var location = { };
                location["locationUpdated"] = false;
                location["locationUpdatedMessage"] = "Error when updating the location";
                dispatch({ type: 'SET_UPDATED_LOCATION_ERROR', location });
           });
   };
};

export const deleteLocation = (id) => {
    return (dispatch, getState) => {
        Services.Locations.deleteLocation(id).then((data)=> {
            if(data !== null) {
                //dispatch({ type: 'REMOVE_LOCATION', id });
                if((typeof data.id) !== 'undefined' && data.id !== null) {
                    let location = { "id": data.id, "locationDeleted": true, 
                                    "locationDeletedMessage": "Successfully deleted location" };
                    dispatch({ type: 'REMOVE_LOCATION', location });
                }
                else {
                    if((typeof data.message) !== 'undefined' && data.message) {
                        let location = { "locationDeleted": false, "locationDeletedMessage": data.message };
                        dispatch({ type: 'REMOVE_LOCATION_ERROR_MESSAGE', location });
                    }
                }
            } 
        });
   };
}

export const updateLocationsInStore = (locations) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_LOCATIONS', locations });
    };
}