import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Textbox from '../shared/textbox';
import { isTextValid } from '../../utilities/string.utilities';
import { connect } from "react-redux";
import { createNewUserType, setUserTypes, updateUserType, setSelectedUserType } from '../../actions/user-types-actions';
import Spinner from 'react-bootstrap/Spinner'
import { LocalModal } from '../common';

class UserTypesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userTypes: [],
            userTypesById: [],
            userType: {
                name: "",
                description: ""
            },
            selectedUserTypeId: "",
            nameError: "",
            descriptionError: "",
            baseAlertClass: "text-center alert alert-dismissible fade show alert-",
            alertClass: "",
            showCreateUserTypeMessage: false,
            userTypeAddedMessage: "",
            userTypeUpdatedMessage: "",
            processing: false,
            
        }
      }

      componentDidMount() {
        const { userTypes } = this.state;
        this.props.setUserTypes(userTypes);
      }

    handleTextboxChange = async (e) => {
        await this.setState({
            ...this.state,
            userType: {
                ...this.state.userType,
                [e.target.name]: e.currentTarget.value
            },
            alertClass: "",
            showCreateUserTypeMessage: false
        }); 
      }

    handleUserTypeSelected = (event) => {
        let userTypeId = parseInt(event.target.value);
        if(userTypeId > 0)
        {
            const selectedUserType = this.props.userTypes.find(function (userType) {
                return userType.id === userTypeId
              });
            this.setState({
                ...this.state,
                userType: selectedUserType
            });
        }
        else{
            this.setState({
                ...this.state,
                userType:{
                    name: "",
                    description: ""
                }
            });
        }        
    }

    componentDidUpdate = async (prevProps) => {
        const { selectedUserTypeId } = this.props
        if(prevProps.selectedUserTypeId !== this.props.selectedUserTypeId && (typeof this.props.selectedUserTypeId) !== 'undefined' && this.props.selectedUserTypeId) {
            const selectedUserType = this.props.userTypes.find(function (userType) {
                return userType.id.toString() === selectedUserTypeId.toString();
              });
            this.setState({
                ...this.state,
                userType: {
                    name: selectedUserType ? selectedUserType.name : "",
                    description: selectedUserType ? selectedUserType.description : ""
                },
                selectedUserTypeId: selectedUserTypeId ? selectedUserTypeId : ""
            });
        }
        if(prevProps.userType !== this.props.userType && (typeof this.props.userType.userTypeDeleted) === 'undefined') {
            this.props.setUserTypes(this.state.userTypes);
            this.setState({
                ...this.state,
                userType:{
                    name: this.props.userType.userTypeAdded ? "" : this.state.userType.name,
                    description: this.props.userType.userTypeAdded ? "" : this.state.userType.description,
                },
                userTypes: this.props.userTypes,
                userTypeAddedMessage: this.props.userType.userTypeAddedMessage ? this.props.userType.userTypeAddedMessage : "", //? this.state.userType.name + " has been added." : "",//this.props.userType.userTypeAddedMessage,
                userTypeUpdatedMessage: this.props.userType.userTypeUpdatedMessage ? this.props.userType.userTypeUpdatedMessage : "", //? "The userType has been updated." : "",//this.props.userType.userTypeUpdatedMessage,
                alertClass: (this.props.userType.userTypeAdded || this.props.userType.userTypeUpdated) ? this.state.baseAlertClass + "success" : this.state.baseAlertClass + "danger" ,
                showCreateUserTypeMessage: true,
                processing: false
            });
        }
    }

    handleCreateUserType = async () => {
        await this.setState({
            ...this.state,
            ...this.state.userType,
            alertClass: "",
            showCreateUserTypeMessage: false
        });
        const { name, description } = this.state.userType
        const formValid = await this.validateUserTypeForm(name, description);
        
        if(formValid) {
            this.setState({ processing: true })
            this.props.createNewUserType(name, description, 
                this.state.userTypes);   
        }
    }

    handleUpdateUserType = async () => {
        await this.setState({
            ...this.state,
            ...this.state.userType,
            alertClass: "",
            showCreateUserTypeMessage: false
        });
        const { name, description } = this.state.userType
        const formValid = await this.validateUserTypeForm(name, description);
        const { selectedUserTypeId } = this.state;
        if(formValid) {
            this.setState({ processing: true })
            this.props.updateUserType(selectedUserTypeId, name, 
                description, this.props.userTypes);   
        }
    }

    handleCloseAlert = () => {
        this.setState({
            ...this.state,
            userTypes: [],
            userTypesById: [],
            userType: {
                name: "",
                description: ""
            },
            selectedUserTypeId: "",
            nameError: "",
            descriptionError: "",
            baseAlertClass: "text-center alert alert-dismissible fade show alert-",
            alertClass: "",
            showCreateUserTypeMessage: false,
            userTypeAddedMessage: "",
            userTypeUpdatedMessage: "",
            processing: false,
        });
        this.props.setSelectedUserType(undefined);
    }

    handleCloseNotification = () => {
        this.setState({
            ...this.state,
            alertClass: "",
            showCreateUserTypeMessage: false,
            userTypeAddedMessage: "",
            userTypeUpdatedMessage: ""
        });
    }

    validateUserTypeForm = async (name, description) => {
        let formValid = false;
        const { userTypes } = this.props;
        const { selectedUserTypeId } = this.state;
        let isUserTypeValid = true;
        userTypes.forEach(userType => {
            if(userType.name.toLowerCase().trim() === name.toLowerCase().trim()) {
                if((typeof selectedUserTypeId) === 'undefined' || !selectedUserTypeId) {
                    isUserTypeValid = false;
                } else {
                    if(userType.id.toString() !== selectedUserTypeId.toString()) {
                        isUserTypeValid = false;
                    }
                }
            }
        });
        await this.setState({
            ...this.state,
            nameError: name && isTextValid(name) ? (!isUserTypeValid ? "Already exists an user type with this name": "") : "Name is required",
            descriptionError: description && isTextValid(description) ? "" : "Description is required",
            userTypeAddedMessage: "",
            userTypeUpdatedMessage: "",
            showCreateUserTypeMessage: false,
        });
        formValid = this.state.nameError === "" && this.state.descriptionError === "";
        return formValid;
    }

    handleClickOutside = () => {
        this.handleCloseAlert();
    }

    render() { 
        const { modalId, modalTitleId } = this.props;
        const { selectedUserTypeId } = this.state;
        const isNewUserType = selectedUserTypeId === "";
        return ( 
            <React.Fragment>
                <LocalModal
                    modalName={modalId}
                    title={modalTitleId}
                    processing={this.state.processing}
                    icon="fas fa-user-tag"
                    onConfirm={() =>
                        isNewUserType ? this.handleCreateUserType() : this.handleUpdateUserType()
                    }
                    onClose={this.handleCloseAlert}
                >
                {/* <div className="modal fade" id={modalId} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" 
                    aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content"> */}
                    {/* <div className="modal-header">
                        <h5 className="modal-title" id={modalTitleId}>{modalTitleId}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" onClick={this.handleCloseAlert}>&times;</span>
                        </button>
                    </div> */}
                    <div className={ this.state.showCreateUserTypeMessage ? "" : "d-none" }>
                        <div className={ this.state.alertClass } role="alert">
                            { isNewUserType ? this.state.userTypeAddedMessage : this.state.userTypeUpdatedMessage }
                            <button type="button" className="close" onClick={ this.handleCloseNotification }>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    {/* <div className="modal-body"> */}
                        <div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <Textbox 
                                        name = "name"
                                        value={this.state.userType.name || ''}
                                        errorText={this.state.nameError}
                                        inputLabel="Name" 
                                        inputType="text" 
                                        inputPlaceholder="Name" 
                                        inputClass="form-control"
                                        handleTextboxChange={e=> this.handleTextboxChange(e)}
                                        errorMessage={this.state.nameError}
                                        errorLabelClass="text-danger"
                                        required/>
                                </div>
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <Textbox 
                                        name = "description"
                                        value={this.state.userType.description || ''}
                                        errorText={this.state.nameError}
                                        inputLabel="Description" 
                                        inputType="text" 
                                        inputPlaceholder="Description" 
                                        inputClass="form-control"
                                        handleTextboxChange={e=> this.handleTextboxChange(e)}
                                        errorMessage={this.state.descriptionError}
                                        errorLabelClass="text-danger"
                                        required />
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                    {/* <div className="modal-footer">
                        { this.state.processing ? <Spinner className="mr-4" style={{color: '#578db9'}} animation="border" /> :
                        <div>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={ this.handleCloseAlert }>Close</button>
                            <button type="submit" onClick={ () => isNewUserType ? this.handleCreateUserType() : this.handleUpdateUserType() } className="btn btn-primary ml-2" >{ isNewUserType ? "Save" : "Update"}</button>
                        </div>}
                    </div> */}
                {/* </div>
            </div>
            </div> */}
            </LocalModal>
            </React.Fragment>
         );
    }
}


 const mapStateToProps = state => {
    return {
        userType: state.userTypesStore.userType,
        selectedUserTypeId: state.userTypesStore.selectedUserTypeId,
        userTypes: state.userTypesStore.userTypes
     };
   };
  
  const mapDispatchToProps = dispatch => ({
    setUserTypes: (userTypes) => dispatch(setUserTypes(userTypes)),
    createNewUserType: (name, description, userTypes) => dispatch(createNewUserType(name, description, userTypes)),
    updateUserType: (id, name, description, userTypes) => dispatch(updateUserType(id, name, description, userTypes)),
    setSelectedUserType: (userTypeId) => dispatch(setSelectedUserType(userTypeId))
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(onClickOutside(UserTypesModal));