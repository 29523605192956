import React, { Component } from 'react';

class Textbox extends Component {

    render() {
        const textboxStyle = this.props.style ? this.props.style : { maxWidth: '15rem'};
        console.log(this.props.errorMessage);
        return ( 
            <div className="form-group" style={this.props.divEmail ? this.props.divEmail : textboxStyle}>
                {this.props.required ? 
                    <span className={this.props.labelClass} htmlFor="exampleInputEmail1">{this.props.inputLabel}<span className="ml-1" style={{color: 'red'}}>*</span></span>
                    :
                    <span className={this.props.labelClass} htmlFor="exampleInputEmail1">{this.props.inputLabel}</span>
                }
                <input
                style={textboxStyle}
                name={this.props.name}
                type={this.props.inputType} 
                className={this.props.inputClass}
                id={this.props.inputId}  
                aria-describedby="emailHelp"
                placeholder={this.props.inputPlaceholder}
                onChange={this.props.handleTextboxChange}
                defaultValue={this.props.defaultValue}
                value={this.props.value}
                readOnly={ this.props.readOnly } />
                {this.props.errorMessageStyle ? 
                <div className={ this.props.errorLabelClass } style={this.props.errorMessageStyle}>{this.props.errorMessage}</div>
                :
                <div className={ this.props.errorLabelClass }>{this.props.errorMessage}</div>
                }
                {/* <div className={ this.props.errorLabelClass }>{this.props.errorMessage}</div> */}
                {this.props.suggestions && this.props.suggestions.length >0 && this.props.renderSuggestion ? 
                    (<ul type="disc">
                        {
                            this.props.suggestions.map(suggestion => {
                                return(this.props.renderSuggestion(suggestion));
                            })
                        }
                    </ul>)
                    :
                    ""
                }
                {this.props.inputType && this.props.inputType === "password" && this.props.showFogotLink ? 
                    <a href="forgotPassword">Forgot Password?</a> 
                : null } 
            </div>
         );
    }
}
 
export default Textbox;