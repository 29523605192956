import React, { Component } from 'react';
import Navbar from '../main/navbar';

class IndexTracking extends Component {
    constructor(props) {
        super(props);
        this.goToHome = this.goToHome.bind(this);
        this.state = {
          users: []
      };
    }
     
    handleClick = () => {
        this.props.setUsers();
    }

    goToHome = () => {
        this.props.history.push('/app')
    }

    render() { 
        //const { buttons } = this.state;
        return (
            <React.Fragment>
              <Navbar linkToHome={this.goToHome} />
                <table className="table table-sm table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">In</th>
                      <th scope="col">Out</th>
                      <th scope="col">Total</th>
                      <th scope="col">Proyect</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Activity</th>
                      <th scope="col">Detail</th>
                      {/* <th scope="col">Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Wed 22</td>
                      <td>08:00 AM</td>
                      <td>04:00 PM</td>
                      <td>08:00</td>
                      <td>Proyect 001</td>
                      <td>Customer AAA</td>
                      <td>Develop</td>
                      <td>Details bla bla 001</td>
                      {/* <td><button className="btn btn-sm btn-light">Edit</button></td> */}
                    </tr>
                    <tr>
                      <td>Wed 23</td>
                      <td>08:30 AM</td>
                      <td>04:30 PM</td>
                      <td>08:00</td>
                      <td>Proyect 001</td>
                      <td>Customer AAA</td>
                      <td>Develop</td>
                      <td>Details bla bla 002</td>
                      {/* <td><button className="btn btn-sm btn-light">Edit</button></td> */}
                    </tr>
                  </tbody>
                </table>
                
            </React.Fragment>
         );
    }
}

export default IndexTracking;