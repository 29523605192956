import React, { Component } from 'react';
import ProjectsPopUp from './project-popup';
import {
  setProjects,
  setSelectedProject,
} from '../../actions/projects-actions';
import { setClients } from '../../actions/clients-actions';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { setProjectsStatusType } from '../../actions/general-actions';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa/index';
import Navbar from '../main/navbar';
import {
  getClients,
  getClientStatusTypes,
  getProjectStatusTypes,
  getProjects,
} from '../../utilities/api.calls';
import { ActionButton, AddNew, LocalTable, Sort } from '../common';
import { TitlePanel } from '../common/LocalPanels';
import edit from '../../assets/images/icons/edit.svg';

class IndexProjects extends Component {
  constructor(props) {
    super(props);
    this.handleSelectedEdit = this.handleSelectedEdit.bind(this);
    this.showAllProjects = this.showAllProjects.bind(this);
    this.showActiveProjects = this.showActiveProjects.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.state = {
      projects: [],
      projectsWereLoaded: false,
      errorMessage: '',
      clients: [],
      projectsStatusTypesById: [],
      projectsStatusTypes: [],
      selectedProjectId: '',
      activeProjectsOnly: true,
      orderByNameAsc: false,
      orderByClientAsc: false,
      orderByStatusAsc: false,
      buttons: [
        {
          id: 1,
          className: 'btn btn-sm btn-success mr-2',
          dataToggle: 'modal',
          dataTarget: '#createProject',
          title: 'Add Project',
          onClick: this.handleSelectedEdit.bind(this),
        },
        {
          id: 2,
          className: 'btn btn-sm btn-primary',
          onClick: this.showAllProjects.bind(this),
          title: 'Show All',
        },
      ],
      sortField: '',
      sortDirection: 'asc',
      tableColumns: [
        {
          position: 1,
          field: 'name',
          label: 'Name',
          sortable: true,
        },

        {
          position: 2,
          field: 'clientName',
          label: 'Client',
          sortable: true,
        },
        {
          stylesHeader: { maxWidth: '40px' },
          position: 3,
          field: 'statusId',
          label: 'Status',
          sortable: true,
          customComponent: (row) => {
            return (
              <div className="d-flex justify-content-start">
                <div
                  className="status-dot"
                  style={{
                    position: 'relative',
                    left: '20px',
                    backgroundColor:
                      this.state.projectsStatusTypesById[row.statusId] ===
                      'Active'
                        ? '#73FF98'
                        : '#FF6666',
                  }}
                ></div>
              </div>
            );
          },
        },

        {
          stylesHeader: { maxWidth: '40px', textAlign: 'right' },
          stylesCell: { textAlign: 'right' },
          position: 4,
          field: '',
          label: 'Actions',
          sortable: false,
          type: 'button',
          customComponent: (row) => {
            return (
              <div className="button-container">
                <ActionButton
                  row={row}
                  dataToggle="modal"
                  dataTarget="#createProject"
                  handleAction={() => this.handleSelectedEdit(row.id)}
                  icon="fa fa-edit icon edit"
                  iconSrc={edit}
                  color=""
                />
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount = () => {
    getClientStatusTypes().then((responseStatus) => {
      if (responseStatus && responseStatus.success === true) {
        var clientsStatusTypesById = [];
        responseStatus.clientsStatusTypes.forEach((clientStatusType) => {
          clientsStatusTypesById[clientStatusType.id] = clientStatusType.name;
        });
        getClients().then((responseClients) => {
          if (responseClients && responseClients.success === true) {
            let clients = [];
            responseClients.clients.forEach((client) => {
              client['statusType'] =
                typeof clientsStatusTypesById[client.statusId] !== 'undefined'
                  ? clientsStatusTypesById[client.statusId]
                  : null;
              clients[client.id] = client;
            });
            getProjectStatusTypes().then((responseProjectStatus) => {
              if (
                responseProjectStatus &&
                responseProjectStatus.success === true
              ) {
                var projectsStatusTypesById = [];
                responseProjectStatus.projectsStatusTypes.forEach(
                  (projectsStatusType) => {
                    projectsStatusTypesById[projectsStatusType.id] =
                      projectsStatusType.name;
                  }
                );
                getProjects().then((response) => {
                  if (response && response.success === true) {
                    let projects = response.projects;
                    projects.forEach((project) => {
                      project['statusType'] =
                        typeof projectsStatusTypesById[project.statusId] !==
                        'undefined'
                          ? projectsStatusTypesById[project.statusId]
                          : null;
                      if (clients) {
                        project['clientName'] = clients[project.clientId].name;
                        project['client'] = clients[project.clientId];
                      }
                    });
                    this.props.setProjects(projects);
                    this.setState({
                      ...this.state,
                      projects,
                      projectsStatusTypesById,
                      projectsWereLoaded: true,
                      clients
                    });
                  }
                  if (response && response.success === false) {
                    this.setState({
                      ...this.state,
                      projectsWereLoaded: true,
                      errorMessage: response.error,
                    });
                  }
                });
              }
              if (
                responseProjectStatus &&
                responseProjectStatus.success === false
              ) {
                this.setState({
                  ...this.state,
                  projectsWereLoaded: true,
                  errorMessage: responseProjectStatus.error,
                });
              }
            });
          }
          if (responseClients && responseClients.success === false) {
            this.setState({
              ...this.state,
              projectsWereLoaded: true,
              errorMessage: responseClients.error,
            });
          }
        });
      }
      if (responseStatus && responseStatus.success === false) {
        this.setState({
          ...this.state,
          projectsWereLoaded: true,
          errorMessage: responseStatus.error,
        });
      }
    });
  };

  componentDidUpdate = async(prevProps) => {
    if(prevProps.projects !== this.props.projects && (typeof this.props.projects) !== 'undefined' && this.props.projects !== null && this.props.projects.length > 0){
      let projects = this.props.projects;
      const { projectsStatusTypesById, clients } = this.state;
      projects.forEach((project) => {
        project['statusType'] =
          typeof projectsStatusTypesById[project.statusId] !==
          'undefined'
            ? projectsStatusTypesById[project.statusId]
            : null;
        if (clients) {
          project['clientName'] = !clients[project.clientId] ? '': clients[project.clientId].name;
          project['client'] = !clients[project.clientId] ? {} : clients[project.clientId];
        }
      });
      await this.setState({
          ...this.state,
          projects: this.props.projects
      });
  }
  } 

  handleSelectedEdit = (id) => {
    this.setState({
      selectedProjectId: id,
    });
    this.props.setSelectedProject(id);
  };

  sortProjects = (field, sortDirection) => {
    const asc = sortDirection === 'asc';
    let { projects } = this.state;
    projects = projects.sort((a, b) =>
      a[field].toLowerCase() > b[field].toLowerCase()
        ? asc
          ? 1
          : -1
        : b[field].toLowerCase() > a[field].toLowerCase()
        ? asc
          ? -1
          : 1
        : 0
    );

    this.setState({
      projects: projects,
      sortField: field,
      sortDirection: sortDirection,
    });
  };

  showActiveProjects = async () => {
    let buttons = this.state.buttons;
    buttons[1]['onClick'] = this.showAllProjects.bind(this);
    buttons[1]['title'] = 'Show All';
    await this.setState({
      activeProjectsOnly: true,
      buttons,
    });
  };

  showAllProjects = async () => {
    let buttons = this.state.buttons;
    buttons[1]['onClick'] = this.showActiveProjects.bind(this);
    buttons[1]['title'] = 'Show Active projects only';
    await this.setState({
      activeProjectsOnly: false,
      buttons,
    });
  };

  goToHome = () => {
    this.props.history.push('/app');
  };

  render() {
    const {
      projects,
      projectsWereLoaded,
      sortField,
      sortDirection,
      tableColumns,
    } = this.state;

    if (!projectsWereLoaded) {
      return (
        <React.Fragment>
          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <Spinner style={{ color: 'white' }} animation="border" />
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <TitlePanel
          title="Projects"
          total={projects.length}
          history={this.props.history}
          icon="fas fa-project-diagram"
        />
        <Filter
          {...this.props}
          handleNewItem={() => this.handleSelectedEdit('')}
          sortField={sortField}
          handleSort={this.sortProjects}
          tableColumns={tableColumns}
        ></Filter>

        <Items
          items={projects}
          sortDirection={sortDirection}
          sortField={sortField}
          handleSort={this.sortProjects}
          tableColumns={tableColumns}
          handleSelectedEdit={this.handleSelectedEdit}
        />

        <ProjectsPopUp
          modalId="createProject"
          modalTitleId={
            this.state.selectedProjectId === ''
              ? 'Create Project'
              : 'Update Project'
          }
        />
      </React.Fragment>
    );
  }
}

const Filter = ({
  children,
  handleSort,
  sortField,
  handleNewItem,
  tableColumns,
}) => {
  return (
    <div className="container ">
      <div className="row filter-container">
        <div className="col-10 col-sm-10 col-md-3 col-lg-3 d-none">
          <div className="inner-addon right-addon">
            <i className="fa fa-search icon"></i>
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="col-md-10 col-lg-10 d-none d-md-block">{children}</div>
        <div className="col-12 col-md-2 filter-buttons-container">
          <AddNew handleNew={handleNewItem} dataTarget="#createProject" />
          <Sort
            handleSort={handleSort}
            sortField={sortField}
            tableColumns={tableColumns}
          />
        </div>
      </div>
    </div>
  );
};

const Items = (props) => {
  return (
    <>
      <Cards {...props} />
      <LocalTable
        {...{
          ...props,
          rows: props.items,
          columns: props.tableColumns,
        }}
      />
    </>
  );
};

const Card = ({ id, name, clientName, statusType, handleSelectedEdit }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="col-12 col-lg-3 col-md-4 col-sm-6 col-exsm-12">
      <div className="inventory-card">
        <div>{name}</div>
        <div className="card-grey">{clientName}</div>
        <div className="card-blue">
          {statusType}
        </div>
        {open && (
            <>
              <div className="action-buttons-card-container w-100 mt-1 d-flex justify-content-center">
              <ActionButton
                  dataToggle="modal"
                  dataTarget="#createProject"
                  handleAction={() => handleSelectedEdit(id)}
                  icon="fa fa-edit icon edit"
                  iconSrc={edit}
                  color=""
                />
              </div>
            </>
          )}
          <div className="see-more-container">
            <div className="see-more" onClick={() => setOpen(!open)}>
              <i className={'fas fa-chevron-' + (open ? 'up' : 'down')} />
            </div>
          </div>
      </div>
    </div>
  );
}

const Cards = ({ items, handleSelectedEdit }) => {
  return (
    <div className="container d-md-none cards-container">
      <div className="row">
        {items.map((item, index) => (
          <Card key={'project_' + index} {...item}
          handleSelectedEdit={handleSelectedEdit} />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    clients: state.clientsStore.clients,
    projects: state.projectsStore.projects,
    projectsStatusTypes: state.generalStore.projectsStatusTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setClients: (clients) => dispatch(setClients(clients)),
  setProjects: (projects) => dispatch(setProjects(projects)),
  setSelectedProject: (projectId) => dispatch(setSelectedProject(projectId)),
  setProjectsStatusType: (projectsStatusTypes) =>
    dispatch(setProjectsStatusType(projectsStatusTypes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexProjects);
