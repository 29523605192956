import React, { Component } from "react";
import onClickOutside from 'react-onclickoutside';
import Textbox from "../shared/textbox";
import { connect } from "react-redux";
import { updateInventoryItemUser, setSelectedItem, setInventoryItemUser } from '../../actions/inventory-actions';
import Spinner from 'react-bootstrap/Spinner';

class EditInventoryItemUserPopUp extends Component {
  state = {
    userId: 0,
    selectedUserId: 0,
    users: [],
    userError: "",
    baseAlertClass: "text-center alert alert-dismissible fade show alert-",
    alertClass: "",
    itemUpdatedMessage: "",
    processing: false,
    showCreateItemMessage: false,
    userButtonText: "Select"
  };

  handleTextboxChange = e => { 
    this.setState({
        ...this.state,
      item: {
        ...this.state.item,
        [e.target.name]: e.currentTarget.value
      }
    });
  };

  componentWillReceiveProps = nextProps => {
    if(nextProps.selectedItem) {
      this.setState({
        ...this.state,
        item: {
          brand: nextProps.selectedItem.brand || "",
          codeId: nextProps.selectedItem.codeId || "",
          detail: nextProps.selectedItem.detail || "",
          id: nextProps.selectedItem.id || "",
          inventoryItemStatusTypeId:
          nextProps.selectedItem.inventoryItemStatusTypeId || "",
          model: nextProps.selectedItem.model || "",
          type: nextProps.selectedItem.type || "",
          userId: nextProps.selectedItem.userId || "",
          locationId: nextProps.selectedItem.locationId || ""
        }
      });  
    }
  };

  handleSaveEdit = async (event) => {
    let userValid = false;
    this.props.users.forEach(user => {
        if(user.id.toString() === this.state.selectedUserId.toString()) {
            userValid = true;
        }
    });
    if(!userValid && this.state.selectedUserId.toString() === "-1") userValid = true;
    if(userValid === false) {
        await this.setState({
            userError: "Selected user is not valid"
        });
    } else {
        await this.setState({
            processing: true
        });
        const { item, selectedUserId, userButtonText } = this.state;
        this.props.updateInventoryItemUser(item, selectedUserId, userButtonText, this.props.inventoryItems);
    }
  }

  componentDidUpdate = async (prevProps) => {
    let itemUserUpdated = this.props.itemUserUpdated;
    if(prevProps.itemUserUpdated !== this.props.itemUserUpdated && this.props.itemUserUpdated) {
        if((typeof this.props.itemUserUpdated.itemUpdated) !== 'undefined') {
            this.setState({
                ...this.state,
                ...this.state.item,
                showCreateItemMessage: this.props.itemUserUpdated.itemAdded || this.props.itemUserUpdated.itemUpdated ? true : false,
                itemUpdatedMessage: this.props.itemUserUpdated.itemUpdated ? "Item has been updated." : this.props.itemUserUpdated.itemUpdatedMessage,
                alertClass: this.props.itemUserUpdated.itemAdded || this.props.itemUserUpdated.itemUpdated ? this.state.baseAlertClass + "success" : this.state.baseAlertClass + "danger" ,
                processing: false
              });
              itemUserUpdated['itemUpdated'] = false;
              itemUserUpdated['itemUpdatedMessage'] = "";
              await this.props.setInventoryItemUser(itemUserUpdated, this.props.inventoryItems);
        }
    }
    if(prevProps.selectedItem !== this.props.selectedItem && this.props.selectedItem 
      && (typeof this.props.selectedItem.itemDeleted) === 'undefined') {
      if(this.props.users.length > 0) {
        let userFullName = '';
        let activeUsers = [];
        this.props.users.forEach(user => {
          if(this.props.selectedItem.userId){
            if((this.props.selectedItem.itemUpdated && this.props.selectedItem.userId === user.id.toString()) 
            || this.props.selectedItem.userId.toString() === user.id.toString()) {
                userFullName = user.firstName + ' ' + user.lastName;
              }
          }          
          if(user.userStatusTypeId.toString() === '1') {
            activeUsers.push(user);
          }
        });
        let users = [];
        const userAux = { firstName: "NOT", lastName: "ASSIGNED", userStatusTypeId: "1", id: "-1" };
        users.push(userAux);
        activeUsers.map(user => users.push(user));
        users.sort((a,b) => a.id === "-1" ? -1 : b.id === "-1" ? 1 : (String(a.firstName).toLowerCase() > String(b.firstName).toLowerCase()) ? 1 : ((String(b.firstName).toLowerCase() > String(a.firstName).toLowerCase()) ? -1 : 0))
        if(this.props.selectedItem.userId){
          if(userFullName === '' && this.props.selectedItem.userId.toString() === "-1") {
            userFullName = userAux.firstName + ' ' + userAux.lastName;
          }
        }else if(userFullName === '') {
          userFullName = userAux.firstName + ' ' + userAux.lastName;
        }
        this.setState({
            ...this.state,
            oldUserId: this.props.selectedItem.userId,
            processing: false,
            selectedLocationId: this.props.selectedItem.locationId,
            userButtonText: userFullName,
            selectedUserId: this.props.selectedItem.userId,
            users: users
        });
      }
    }
 }

 handleCloseAlert = () => {
  this.props.setSelectedItem("");
  this.props.setInventoryItemUser(undefined, this.props.inventoryItems);
  this.setState({
    item: {
      brand: "",
      codeId: "",
      detail: "",
      id: "",
      inventoryItemStatusTypeId: 2,

      model: "",
      type: "",
      userId: ""
    },
    userId: 0,
    selectedUserId: undefined,
    users: [],
    codeIdError: "",
    typeError: "",
    brandError: "",
    modelError: "",
    locationError: "",
    detailError: "",
    baseAlertClass: "text-center alert alert-dismissible fade show alert-",
    alertClass: "",
    showCreateItemMessage: false,
    itemAddMessage: "",
    itemUpdatedMessage: "",
    processing: false,
    locationButtonText: "Select",
    selectedLocationId: "",
    inventoryItemTypeSuggestions: [],
    suggestions: []
  });
}

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.state.inventoryItemTypeSuggestions.filter(suggestion =>
      suggestion.label.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  getSuggestionValue = async suggestion => {
    console.log("");
    if(suggestion && suggestion.label && (typeof suggestion.label) === 'string') {
      await this.setState({
        ...this.state,
        item: {
          ...this.state.item,
          type: suggestion.label
        },
        suggestions: []
      });
    }
  }

  renderSuggestion = suggestion => (
    <li style={{color: 'black'}} key={suggestion.id} onClick={() => this.getSuggestionValue(suggestion)}>
      {suggestion.label}
    </li>
  );


  handleAutoSuggestChange = async (e) => {
    if(e && e.target && (typeof e.target.value) === 'string') {
      await this.setState({
        ...this.state,
        item: {
          ...this.state.item,
          type: e.target.value
        },
        suggestions: this.getSuggestions(e.target.value)
      });
    }
  };

  handleCloseNotification = () => {
    this.setState({
      baseAlertClass: "text-center alert alert-dismissible fade show alert-",
      alertClass: "",
      showCreateItemMessage: false,
      itemAddMessage: "",
      itemUpdatedMessage: ""
    })
  }

  handleClickOutside = () => {
    this.props.setSelectedItem("");
    this.props.setInventoryItemUser(undefined, this.props.inventoryItems);
    this.handleCloseNotification();
}

  render() {
    let { item } = this.state;
    const { users } = this.state;
    if((typeof item) === 'undefined') {
        item = {codeId: "", type: "", brand: "", model: "", detail: ""};
    }
    let locationName = '';
    if(this.props.locations.length > 0) {
        this.props.locations.forEach(location => {
          if((typeof item) !== 'undefined' && item 
          && (typeof item.locationId) !== 'undefined' 
          && item.locationId 
          && item.locationId.toString() === location.id.toString()) {
            locationName = location.name;
          } 
        });
    }
    if(locationName === '') locationName = "Not specified";
    return (
      <React.Fragment>
        <div
          className="modal fade" id={this.props.modalId} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id={this.props.modalTitleId}>
                  {this.props.modalTitleId}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" 
                onClick={ this.handleCloseAlert }>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className={ this.state.showCreateItemMessage ? "" : "d-none" }>
                <div className={ this.state.alertClass } role="alert">
                  { this.state.itemUpdatedMessage }
                  <button type="button" className="close" onClick={ this.handleCloseNotification }>
                      <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col">
                     <Textbox
                      name="codeId"
                      value={item.codeId}
                      inputLabel="CodeId:"
                      inputType="text"
                      inputPlaceholder="AAA000"
                      inputClass="form-control form-control-modal"
                      readOnly
                    />
                  </div>
                  <div className="col">
                    <Textbox
                      name="type"
                      value={item.type}
                      inputLabel="Type:"
                      inputType="text"
                      inputPlaceholder="Laptop or Headset.."
                      inputClass="form-control form-control-modal"
                      readOnly /> 
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Textbox
                      name="brand"
                      value={item.brand}
                      inputLabel="Brand:"
                      inputType="text"
                      inputPlaceholder="AAA000"
                      inputClass="form-control form-control-modal"
                      readOnly
                    />
                  </div>
                  <div className="col">
                    <Textbox
                      name="model"
                      value={item.model}
                      inputLabel="Model:"
                      inputType="text"
                      inputPlaceholder="Model"
                      inputClass="form-control form-control-modal"
                      readOnly />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                  <Textbox
                      name="location"
                      value={locationName}
                      inputLabel="Location:"
                      inputType="text"
                      inputPlaceholder="Location"
                      inputClass="form-control form-control-modal"
                      readOnly />
                  </div>
                  <div className="col">
                    <Textbox
                      name="detail"
                      value={item.detail}
                      inputLabel="Detail:"
                      inputType="text"
                      inputPlaceholder="Detail..."
                      inputClass="form-control form-control-modal"
                      readOnly />
                  </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label >User:<span className="ml-1" style={{color: 'red'}}>*</span></label>
                        <div className="dropdown">
                            <button style={{ width: '218px' }} className="btn btn-sm btn-info dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                { this.state.userButtonText }
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                { users && users.map(user => 
                                {
                                return(
                                <button
                                    key={user.id} 
                                    onClick={event => this.setState({selectedUserId: event.target.value, userButtonText: user.firstName + ' ' + user.lastName })} 
                                    className="dropdown-item" 
                                    type="button"
                                    value={user.id} >
                                    {user.firstName + ' ' + user.lastName}
                                </button>
                                )
                                }) }
                            </div>
                        </div>
                        <p style={{color: 'red'}}>{this.state.userError}</p>
                    </div>
                </div>
              </div>
              <div className="modal-footer">
              { this.state.processing ? <Spinner className="mr-4" style={{color: '#578db9'}} animation="border" /> :
                  <div>
                    <button id="hidePopUpBtn" type="button" className="btn btn-secondary" data-dismiss="modal" onClick={ this.handleCloseAlert }>Close</button>
                    <button type="submit" className="btn btn-primary ml-2" onClick={ this.handleSaveEdit }>Save</button>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    inventoryItems: state.inventoryStore.inventoryItems,
    selectedItem: state.inventoryStore.selectedItem,
    selectedUserId: state.usersStore.selectedUserId,
    users: state.usersStore.users,
    locations: state.locationsStore.locations,
    itemUserUpdated: state.inventoryStore.itemUserUpdated
  };
};

const mapDispatchToProps = (dispatch) => ({
    setSelectedItem: (id) => dispatch(setSelectedItem(id)),
    updateInventoryItemUser: (itemId, userId, userFullName, inventoryItems) => dispatch(updateInventoryItemUser(itemId, userId, userFullName, inventoryItems)),
    setInventoryItemUser: (item, inventoryItems) => dispatch(setInventoryItemUser(item, inventoryItems))
});

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(EditInventoryItemUserPopUp));
